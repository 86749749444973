// src/components/StudentTable.js

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material';

const getStatusStyle = (status) => ({
  padding: '4px 8px',
  color: status === 'Completed' ? '#1a7f37' : '#d14343',
  backgroundColor: status === 'Completed' ? '#e6f4ea' : '#fdecea',
  borderRadius: '12px',
  display: 'inline-block',
  fontWeight: 500,
});

const StudentTable = ({ students, selected, handleSelect, handleAmountChange, handleSelectAll, handlePayment }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < students.length}
                checked={students.length > 0 && selected.length === students.length}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Student ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Payment Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected.includes(student.id)}
                  onChange={() => handleSelect(student.id)}
                />
              </TableCell>
              <TableCell>{student.id}</TableCell>
              <TableCell>{student.lastname +" "+student.othernames}</TableCell>
              <TableCell>{student.class_}</TableCell>
              <TableCell>
                <TextField
                  value={student.amount}
                  onChange={(e) => handleAmountChange(student.id, e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </TableCell>
              <TableCell>{student.date}</TableCell>
              <TableCell>
                <span style={getStatusStyle(student.paymentStatus)}>
                  {student.paymentStatus}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentTable;
