import React, {useEffect, useRef} from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Backimage from './images/anouncement.svg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ReactToPrint from 'react-to-print';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Snack from './snack';
import './button.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import Imager from './images/Wallet.svg'
import SISlogo from './images/sislogoblue.png'
import nodata from './images/lesson-animate.svg'
import axios from 'axios'
import AlldoneIcon from '@mui/icons-material/TaskAlt';
import io from "socket.io-client";
import { PaystackButton } from 'react-paystack'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { announcement_ } from './redux/announcement';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import {  admin } from './redux/admin';
import {  schooldetails } from './redux/schooldetails';

import { capitalize } from '@mui/material';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const socket = io.connect("https://servermain.sisrevolution.com")




function Profile() {

  const pricer={"50-200":"https://paystack.com/pay/sisrevolution","201-400":"https://paystack.com/pay/sisrevolution201-400", "401-600":"https://paystack.com/pay/sisrevolution401-600","601-800":"https://paystack.com/pay/sisrevolution601-800", "801:1000":"https://paystack.com/pay/sisrevolution801-1000","1001-1200":"https://paystack.com/pay/sisrevolution100-1200","1201-1400":"https://paystack.com/pay/sisrevolution1201-1400", "1401-1600":"https://paystack.com/pay/1401t01600students", "1601-1800":"https://paystack.com/pay/1601to1800students", "1801-2000":"https://paystack.com/pay/1801to2000students","2001-2200":"https://paystack.com/pay/2001to2200students","2201:2400":"https://paystack.com/pay/2201to2400students","2401-2600":"https://paystack.com/pay/2401to2600students","2601-2800":"https://paystack.com/pay/2601to2800students","2801-3000":"https://paystack.com/pay/2801to3000students" }

  const pricing={"50-200":"450","201-400":"750", "401-600":"1110","601-800":"1400", "801-1000":"1700","1001-1200":"2050","1201-1400":"2350", "1401-1600":"2650", "1601-1800":"2950", "1801-2000":"3250", "2001-2200":"3550","2201:2400":"3850","2401-2600":"4150","2601-2800":"4450","2801-3000":"4750"}

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const dispatch = useDispatch()
  const [preloading, setpreloading] = React.useState(true);

  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const students = useSelector((state) => state.students)
  const expenses = useSelector((state) => state.expenses)
  const Class_ = useSelector((state) => state.Class_)
  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)
  const charge = 0
  const schooldata= async () => {
      await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))


              }

          })

  }



  const gettingdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
        {
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(announcement_({

                    announcement: res.data

                }))


            }

        })

}






useEffect(()=>{
    schooldata()
    gettingdata()
},[preloading])

const announcement = useSelector((state) => state.announcement_)

  const [opensnack, setOpensnack] = React.useState(false);
  const [value, setValue] = React.useState([null, null]);

  const [chat, setchat] = React.useState([]);
  const [prechat, setprechat] = React.useState([]);

  const [username, setusername]= React.useState("Joseph")
  const [room, setroom]= React.useState("room1")
  const [smsloading, setsmsloading]= React.useState(!true)
  const [typer, settyper]= React.useState("")


    const [message, setmessage] = React.useState("");
    const [viewer, setviewer] = React.useState("");
    const [startdate, setstartdate] = React.useState("");
    const [enddate, setenddate] = React.useState(getCurrentDate());
    const [stated, setstated] = React.useState(getCurrentDate());
    const [selectedDate, setSelectedDate] = React.useState(getCurrentDate());
  

    // var val = (parseFloat(students.length*charge)*100).toFixed(2)

    const [openannounce, setOpenannounce] = React.useState(!true);
    const [loading, setloading] = React.useState(!true);

    const [openbulk, setOpenbulk] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openfees, setOpenfees] = React.useState(!true);
    const [chosen, setchosen] = React.useState([]);
    const publicKey = "pk_live_be3b6346afcda4aa307dc2b45ec8eb29e8486944"
    // const amount= (parseFloat(students.length)*100 *charge).toFixed(2)

    var amount_ =(parseFloat(students.length)*100 *charge).toFixed(2)
    var amount = 0
    
    const [email, setEmail] = React.useState(schooldetails_.length!==0?schooldetails_[0].email:"")
    const [name, setName] = React.useState(schooldetails_.length!==0?schooldetails_[0].schoolname:"")
    const [phone, setPhone] = React.useState(schooldetails_.length!==0?schooldetails_[0].contact:"")
    const [currency, setcurrency] = React.useState("GHS")
    const componentRefreceipt = useRef()
    const [selectedterm, setselectedterm] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
  
  
  
    const confirmpayment=()=>{
      // e.preventDefault()
      var datepaid=date
      var year_=parseInt(year)
      var month_=parseInt(month)
      var amount_ =(parseFloat(students.length)*100 *charge).toFixed(2)
      var amount = schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"?(amount_*3.5).toFixed(2):amount_
      if(month_===12){
        year_=year_+1
        if(schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"){
          month_=4
        }else{
        month_=1
        }
      }else{
        if(schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"){
          month_=month_+4
        }else{
        month_=month_+1
        }
      }

      var next = year_ + "-" + month_ + "-" + day;
      var datehere=datepaid.split("-")
      var finalamount=(amount/100).toFixed(2)
      var data = {amount:finalamount, month:months[parseInt(datehere[1])-1], year:datehere[0], day:datehere[2],nextmonth:months[parseInt(datehere[1])-1]==="December"?"January":months[parseInt(datehere[1])],nextyear:months[parseInt(datehere[1])-1]==="December"?year+1:year, nextday:datehere[2], next:next, date:date, time:time, transactionID:"", term:schooldetails_[0].terms[schooldetails_[0].terms.length-1]}
      const buyingpolicy = async () => {
  
          await axios.post("https://servermain.sisrevolution.com/api/schools/paysystem/", {
                     
           data:data,
           amount:amount,
          _id:schooldetails_[0]._id
          
                      }).then(res => {
  
                  if (res.status === 200) {
                    schooldata()
                      setloading(!true)
                      // e.target.reset()
                      handleClicksnack()
                      setmessage("Payment made")
                      setstated("success")
                      setsmsloading(!true)
  
                  } else {
                      setloading(!true)
                      handleClicksnack()
                      setmessage("Error making payment")
                      setstated("error")
                  }
  
  
  
              })
  
      }
  
      buyingpolicy()
  
  }
  
  
  

    const componentProps = {
        email,
        amount,
        currency,
        metadata: {
            name,
            phone,
        },
        publicKey,
        text: "MAKE PAYMENT",
        onSuccess: () => confirmpayment(),
        onClose: () => alert("Please make payment before leaving"),
    }
  
  

  
    const handleClickOpenannounce = (data) => {
       
        setOpenannounce(true);
    };

      const handleClicksnack = () => {
        setOpensnack(true);
    };

      const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
                }
        setOpensnack(false);
    };

    const handleCloseannounce = () => {
        setOpenannounce(false);
    };

    const sendannouncement=()=>{

        const subject = document.getElementById("subject").value
        const message_ = document.getElementById("message_").value

        const sender = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/announcement/addannouncement/",
                {
                    message:message_,
                    subject:subject,
                    date:date,
                    time:time,
                    schoolID:admin.schoolID
    
                }).then(res => {
    
                    if (res.status === 200) {
    
                        dispatch(announcement_({

                            announcement: res.data
        
                        }))

                        handleCloseannounce()
                        handleClicksnack()
                        setmessage("Announcement Sent")
                        setstated("success")
                        setsmsloading(!true)
    
    
                    }else{
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("Error sending announcement")
                        setstated("error")
                                setsmsloading(!true)
                    }
    
                })
    
        }
        sender()



        

    }

    const handlebulksms=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                

    }

    const handleview=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                

    }

  const handleClickOpenbulk = (data) => {
       
        setOpenbulk(true);
    };

    const handleClosebulk = () => {
        setOpenbulk(false);
    };


 const handleClickOpenview = (data) => {
       setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };

  const handleClosefees = () => {
        setOpenfees(false);
    };


    const trying=()=>{
      const data = {  "customer": "customer@email.com", "plan": "PLN_ahtk74jl3tasals" }
      const uploading = async() => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/bill/", {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

         console.log(res)

        })

    }

    uploading()

    }





    const admissionreport=()=>{

// sort admitted students by date
 var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() && new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    

        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Report for Admitted Students</span>
                    <IconButton aria-label="" onClick={handleCloseview} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>


{/* enter here */}


<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Student Admission Report</h5>

</div>

</div>
{console.log("value")}
{console.log(startdate.d)}
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            <span><b>Number Of Admitted Students = </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s) = </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s) = </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname} = </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )}
            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            



<TableContainer>
  <Button variant="outlined" onClick={()=>setOpenview(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>Admission Report</b></p>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Student Name</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Class</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {studentslist.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> 
              <TableCell component="th" scope="row">{months[parseInt((row.createdAt.split('T')[0].split("-"))[1])-1] +" "+(row.createdAt.split('T')[0].split("-"))[2]+", "+(row.createdAt.split('T')[0].split("-"))[0]}</TableCell> 
              <TableCell component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
            <TableCell align="left" style={{ fontWeight:"bolder"}}>{row.class_}</TableCell>
          </TableRow>))} 


        </TableBody>
      </Table>
    </TableContainer>
</div>

        )

    }


    const newpage=()=>{

      return(

        <div>

<div>
  <img src={nodata}  style={{width:"40%", marginTop:"5%"}} />
</div>


        </div>

      )

    }

    const schoolfeesreport=()=>{


// sort admitted students by date
//  var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() || new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    
// var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() && new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    

 var totalfees = 0
 var feesbyclass = []
 var totallist = 0
 var studentfeeslist = []
 
 for(var i in students){

  for (var k in students[i].finance){
    for (var l in students[i].finance[k].payment){
      console.log(students[i].finance[k].payment[l])
      if(new Date(startdate).getTime()<=new Date(students[i].finance[k].payment[l].date).getTime() && new Date(students[i].finance[k].payment[l].date).getTime()<=new Date(enddate).getTime() ){

        studentfeeslist.push({ID:students[i].ID, name:students[i].lastname+" "+students[i].othernames, amount:students[i].finance[k].payment[l].amountpaid,paidto:students[i].finance[k].payment[l].receiver,date:students[i].finance[k].payment[l].date, class:students[i].class_})

      }
    }

  }

 }


 for(var i in Class_){
  var sub=0
  var num=0
  var listed= studentfeeslist.filter(bug=>bug.class===Class_[i].classname)
    for (var k in listed){
      if(listed.length!==0){
        totalfees+=parseFloat(listed[k].amount)
        sub+=parseFloat(listed[k].amount)
        
      }
    }
    if(listed.length!==0){
      totallist+=listed.length
    feesbyclass.push({class:Class_[i].classname,total:sub, num:listed.length})
    }
 }



        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>School Fees Report</span>
                    <IconButton aria-label="" onClick={handleClosefees} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>


{/* enter here */}


<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>School Fees Report</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total Amount Paid</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>No. Of Students</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {feesbyclass.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"> {indexer+1 +"."}</TableCell> 
              <TableCell component="th" scope="row">{row.class}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.total).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">{Intl.NumberFormat('en-US').format(row.num)}</TableCell>
          </TableRow>))} 

          <TableRow>
            <TableCell  />
            <TableCell style={{fontWeight:"bolder"}}>Total</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(totalfees).toFixed(2))}</TableCell>
            <TableCell  style={{fontWeight:"bolder"}} align="left">{Intl.NumberFormat('en-US').format(totallist)}</TableCell>
          </TableRow>


        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            



<TableContainer>
  <Button variant="outlined" onClick={()=>setOpenfees(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>School Fees Payment Report</b></p>

      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>ID</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Student Name</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Amount Paid</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Date Paid</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Paid To</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {studentfeeslist.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> 
              <TableCell component="th" scope="row">{row.ID}</TableCell>
              <TableCell component="th" scope="row">{row.name}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.class}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>GHc {Intl.NumberFormat('en-US').format(parseFloat(row.amount).toFixed(2))}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0]}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.paidto}</TableCell>
          </TableRow>))} 


        </TableBody>
      </Table>
    </TableContainer>
</div>

        )

    }


    const nonbillablereport=()=>{


var DailyCharge_ = 0
var WeeklyCharge_ = 0
var MonthlyCharge_ = 0
var TermlyCharge_ = 0
var BooksCharge_ = 0
var UniformCharge_ = 0
var AdmissionFee_ = 0
var PrintingFee_ = 0
var CanteenFee_ = 0
var Others_ = 0

 var totalfees = 0
 var feesbyclass = []
 var totallist = 0
 var studentfeeslist = []
 
 for(var i in students){

  for (var k in students[i].charges){
    for (var l in students[i].charges[k].payment){
      if(new Date(startdate).getTime()<=new Date(students[i].charges[k].payment[l].date).getTime() && new Date(students[i].charges[k].payment[l].date).getTime()<=new Date(enddate).getTime() ){

        studentfeeslist.push({ID:students[i].ID, name:students[i].lastname+" "+students[i].othernames, amount:students[i].charges[k].payment[l].amountpaid,paidto:students[i].charges[k].payment[l].receiver,date:students[i].charges[k].payment[l].date, class:students[i].class_, description:students[i].charges[k].payment[l].description})

      }
    }

  }

 }


 for(var i in Class_){
  var sub=0
var BooksCharge = 0
var UniformCharge = 0
var AdmissionFee = 0
var PrintingFee = 0
var CanteenFee = 0
var Others = 0
  var listed= studentfeeslist.filter(bug=>bug.class===Class_[i].classname)
    for (var k in listed){
      if(listed.length!==0){
        totalfees+=parseFloat(listed[k].amount)
        sub+=parseFloat(listed[k].amount)
        if(listed[k].description==="Admission Fee"){
          AdmissionFee+=parseFloat(listed[k].amount)
          AdmissionFee_+=parseFloat(listed[k].amount)
        }
        if(listed[k].description==="Printing Fee"){
          PrintingFee+=parseFloat(listed[k].amount)
          PrintingFee_+=parseFloat(listed[k].amount)
        }
        if(listed[k].description==="Canteen Fee"){
          CanteenFee+=parseFloat(listed[k].amount)
          CanteenFee_+=parseFloat(listed[k].amount)
        }
        if(listed[k].description==="Books Charge"){
          BooksCharge+=parseFloat(listed[k].amount)
          BooksCharge_+=parseFloat(listed[k].amount)
        } 
        if(listed[k].description==="Uniform Charge"){
          UniformCharge+=parseFloat(listed[k].amount)
          UniformCharge_+=parseFloat(listed[k].amount)
        }
        if(listed[k].description!=="Books Charge" && listed[k].description!=="Canteen Fee" && listed[k].description!=="Printing Fee" && listed[k].description!=="Admission Fee"){
          Others+=parseFloat(listed[k].amount)
          Others_+=parseFloat(listed[k].amount)
        }
        
      }
    }
    if(listed.length!==0){
      totallist+=listed.length
    feesbyclass.push({class:Class_[i].classname,total:sub, num:listed.length, admission:AdmissionFee, printing:PrintingFee, canteen:CanteenFee, books:BooksCharge,uniform:UniformCharge, others:Others})
    }
 }



        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Non-Billable Charges Report</span>
                    <IconButton aria-label="" onClick={handleClosefees} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>


{/* enter here */}


<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Non-Billable Charges Report</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Admission Fee </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Printing Fee </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Canteen Fee </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Books Fee </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Uniform </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Others </TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total Amount Paid</TableCell>
                      {/* <TableCell style={{ fontWeight:"bolder"}}>No. Of Students</TableCell> */}

          </TableRow>
        </TableHead>
        <TableBody>


          {feesbyclass.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"> {indexer+1 +"."}</TableCell> 
              <TableCell component="th" scope="row">{row.class}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.admission).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.printing).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.canteen).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.books).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.uniform).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.others).toFixed(2))}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(row.total).toFixed(2))}</TableCell>
              {/* <TableCell component="th" scope="row">{Intl.NumberFormat('en-US').format(row.num)}</TableCell> */}
          </TableRow>))} 

          <TableRow>
            <TableCell  />
            <TableCell style={{fontWeight:"bolder"}}>Total</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(AdmissionFee_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(PrintingFee_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(CanteenFee_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(BooksCharge_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(UniformCharge_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(Others_).toFixed(2))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(totalfees).toFixed(2))}</TableCell>
            {/* <TableCell  style={{fontWeight:"bolder"}} align="left">{Intl.NumberFormat('en-US').format(totallist)}</TableCell> */}
          </TableRow>


        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            



<TableContainer>
  <Button variant="outlined" onClick={()=>setOpenfees(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>Non-Billable Charges Report</b></p>

      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>ID</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Student Name</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Amount Paid</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Date Paid</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Description</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Paid To</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {studentfeeslist.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> 
              <TableCell component="th" scope="row">{row.ID}</TableCell>
              <TableCell component="th" scope="row">{row.name}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.class}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format(parseFloat(row.amount).toFixed(2))}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0]}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.description}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.paidto}</TableCell>
          </TableRow>))} 


        </TableBody>
      </Table>
    </TableContainer>
</div>

        )

    }



    const expensesreport=()=>{


// sort admitted students by date
//  var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() || new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    
// var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() && new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    

 var totalfees = 0
 var feesbyclass = []
 var totallist = 0
 var studentfeeslist = []
 
var filtered = expenses.filter(bug=>new Date(startdate).getTime()<=new Date(bug.date).getTime() && new Date(bug.date).getTime()<=new Date(enddate).getTime() )

 
for(var i in filtered){

  totalfees += parseFloat(filtered.amountspent ? filtered.amountspent : 0)

}
 


        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Expenditure Report</span>
                    <IconButton aria-label="" onClick={handleClosefees} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>

<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Expenditure Report</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>Start Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>End Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total Expenditure</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"> {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell component="th" scope="row">{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(totalfees).toFixed(2))}</TableCell>
          </TableRow>

    
        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


            <div>
            <Button variant="outlined" onClick={()=>setOpenfees(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>Expenses Report</b></p>


<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Expenditure Report</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>Start Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>End Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total Expenditure</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"> {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell component="th" scope="row">{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell component="th" scope="row">GHC {Intl.NumberFormat('en-US').format(parseFloat(totalfees).toFixed(2))}</TableCell>
          </TableRow>

    
        </TableBody>
      </Table>
    </TableContainer>


            


</div>          

</div>

        )

    }



    const incomestatement=()=>{

var totalexpenses = 0 
var totalnonbillable = 0 
var totalschoolfees = 0 

var filteredexpenses = expenses.filter(bug=>new Date(startdate).getTime()<=new Date(bug.date).getTime() && new Date(bug.date).getTime()<=new Date(enddate).getTime() )

 
for(var i in filteredexpenses){

  totalexpenses += parseFloat(filteredexpenses.amountspent?filteredexpenses.amountspent:0)

}
 

for(var i in students){

  for (var k in students[i].charges){
    for (var l in students[i].charges[k].payment){
      if(new Date(startdate).getTime()<=new Date(students[i].charges[k].payment[l].date).getTime() && new Date(students[i].charges[k].payment[l].date).getTime()<=new Date(enddate).getTime() ){

        totalnonbillable+=parseFloat(students[i].charges[k].payment[l].amountpaid ? students[i].charges[k].payment[l].amountpaid:0)

      }
    }

  }

 }


 for(var i in students){

  for (var k in students[i].finance){
    for (var l in students[i].finance[k].payment){
      console.log(students[i].finance[k].payment[l])
      if(new Date(startdate).getTime()<=new Date(students[i].finance[k].payment[l].date).getTime() && new Date(students[i].finance[k].payment[l].date).getTime()<=new Date(enddate).getTime() ){
        totalschoolfees+=parseFloat(students[i].finance[k].payment[l].amountpaid ? students[i].finance[k].payment[l].amountpaid:0)
      }
    }

  }

 }





        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Income Statement</span>
                    <IconButton aria-label="" onClick={handleClosefees} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>

<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Income Statement</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>Start Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>End Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Description</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell >School Fees</TableCell>
              <TableCell >GHC {Intl.NumberFormat('en-US').format(parseFloat(totalschoolfees).toFixed(2))}</TableCell>
          </TableRow>
   

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell > Other Charges</TableCell>
              <TableCell >GHC {Intl.NumberFormat('en-US').format(parseFloat(totalnonbillable).toFixed(2))}</TableCell>
          </TableRow>
 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell > Expenditure</TableCell>
              <TableCell >GHC - {Intl.NumberFormat('en-US').format(parseFloat(totalexpenses).toFixed(2))}</TableCell>
          </TableRow> 
          <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell  />
                <TableCell  />
                <TableCell style={{fontWeight:"bolder"}}>Net Income</TableCell>
              <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format( ((parseFloat(totalschoolfees) + parseFloat(totalnonbillable) )-parseFloat(totalexpenses) ).toFixed(2)   ) }</TableCell>
          </TableRow>

    
        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


            <div >
            <Button variant="outlined" onClick={()=>setOpenfees(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>Income Statement</b></p>

<div>
<br/>

<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Income Statement</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>Start Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>End Date</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Description</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Total</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell >School Fees</TableCell>
              <TableCell >GHC {Intl.NumberFormat('en-US').format(parseFloat(totalschoolfees).toFixed(2))}</TableCell>
          </TableRow>
   

 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell > Other Charges</TableCell>
              <TableCell >GHC {Intl.NumberFormat('en-US').format(parseFloat(totalnonbillable).toFixed(2))}</TableCell>
          </TableRow>
 <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell > {(months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] )}</TableCell> 
              <TableCell >{(months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] )}</TableCell>
              <TableCell > Expenditure</TableCell>
              <TableCell >GHC - {Intl.NumberFormat('en-US').format(parseFloat(totalexpenses).toFixed(2))}</TableCell>
          </TableRow> 
          <TableRow
                  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell  />
                <TableCell  />
                <TableCell style={{fontWeight:"bolder"}}>Net Income</TableCell>
              <TableCell style={{fontWeight:"bolder"}}>GHC {Intl.NumberFormat('en-US').format( ((parseFloat(totalschoolfees) + parseFloat(totalnonbillable) )-parseFloat(totalexpenses) ).toFixed(2)   ) }</TableCell>
          </TableRow>

    
        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


            


</div>          

</div>

        )

    }



    const attendancereport=()=>{

  // sort admitted students by date
  // var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() || new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    
  // var studentslist=  students.filter(bug=>new Date(startdate).getTime()<=new Date(bug.createdAt.split('T')[0]).getTime() && new Date(bug.createdAt.split('T')[0]).getTime()<=new Date(enddate).getTime())    

 var totalfees = 0
 var totalpresent_ = 0
 var totalabsent_ = 0
 var feesbyclass = []
 var totallist = 0
 var studentfeeslist = []

 
 for(var i in students){
    for (var k in students[i].attendance){
       
        for(var l in students[i].attendance[k]){
          console.log(students[i].attendance[k][l])
      if(new Date(startdate).getTime()<=new Date(students[i].attendance[k][l].date).getTime() && new Date(students[i].attendance[k][l].date).getTime()<=new Date(enddate).getTime() ){

        studentfeeslist.push({ID:students[i].ID, name:students[i].lastname+" "+students[i].othernames, attendance:students[i].attendance[k][l].attendance,date:students[i].attendance[k][l].date, class:students[i].class_})

      }
    }
   

  }

 }


 for(var i in Class_){
  var totalpresent=0
  var totalabsent=0

  var listed= studentfeeslist.filter(bug=>bug.class===Class_[i].classname)
    for (var k in listed){
      if(listed.length!==0){
        if(listed[k].attendance==="present"){
         totalpresent+=1 
         totalpresent_+=1 
        }else{
          totalabsent+=1
          totalabsent_+=1
        }
        
      }
    }
    if(listed.length!==0){
      totallist+=listed.length
    feesbyclass.push({class:Class_[i].classname,absent:totalabsent, present:totalpresent, num:listed.length})
    }
 }



        return(

<div>



<Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>School Fees Report</span>
                    <IconButton aria-label="" onClick={handleClosefees} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>


<Container>

<ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Report</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>


{/* enter here */}


<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>Attendance Report</h5>

</div>

</div>
<p><b>Period</b> {startdate!=="" ? (months[parseInt((startdate.split("-"))[1])-1] +" "+(startdate.split("-"))[2]+", "+(startdate.split("-"))[0] ):""}  to  {startdate!=="" ? (months[parseInt((enddate.split("-"))[1])-1] +" "+(enddate.split("-"))[2]+", "+(enddate.split("-"))[0] ):""} </p>
  <Divider></Divider>
  <br/>
            {/* <span><b>Number Of Admitted Students: </b>{studentslist.length} </span> <br/>
            <span><b>Male Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Male").length} </span> <br/>
            <span><b>Female Student(s): </b>{studentslist.length!==0 && studentslist.filter(bug=>bug.gender==="Female").length} </span> <br/>
            {
              Class_.map((list, i)=>
                <>
                {studentslist.length!==0 && studentslist.filter(bug=>bug.class_===list.classname).length !==0  && <><span><b>Admitted Students for {list.classname}: </b>{studentslist.filter(bug=>bug.class_===list.classname).length} </span> <br/></> }
                </>
              
    )} */}


    
<TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Number Present</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Number Absent</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>No. Of Students</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {feesbyclass.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{indexer+1 +"."}</TableCell> 
              <TableCell component="th" scope="row">{row.class}</TableCell>
              <TableCell component="th" scope="row">{Intl.NumberFormat('en-US').format(parseInt(row.present))}</TableCell>
              <TableCell component="th" scope="row">{Intl.NumberFormat('en-US').format(parseInt(row.absent))}</TableCell>
              <TableCell component="th" scope="row">{Intl.NumberFormat('en-US').format((row.num))}</TableCell>
          </TableRow>))} 

          <TableRow>
            <TableCell  />
            <TableCell style={{fontWeight:"bolder"}}>Total</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>{Intl.NumberFormat('en-US').format((totalpresent_))}</TableCell>
            <TableCell style={{fontWeight:"bolder"}}>{Intl.NumberFormat('en-US').format((totalabsent_))}</TableCell>
            <TableCell  style={{fontWeight:"bolder"}} align="left">{Intl.NumberFormat('en-US').format(totallist)}</TableCell>
          </TableRow>


        </TableBody>
      </Table>
    </TableContainer>


            


</div>          


</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            



<TableContainer>
  <Button variant="outlined" onClick={()=>setOpenfees(true)}> View Report  </Button>
  <p align="center" style={{color:"black"}}><b>Attendance Report</b></p>

      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>ID</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Student Name</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Class</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Date</TableCell>
                      <TableCell align="left" style={{ fontWeight:"bolder"}}>Attendance</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>


          {studentfeeslist.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> 
              <TableCell component="th" scope="row">{row.ID}</TableCell>
              <TableCell component="th" scope="row">{row.name}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{row.class}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder"}}>{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0]}</TableCell>
            <TableCell align="left" style={{fontWeight:"bolder", color:row.attendance==="present"?"green":"red"}}>{row.attendance}</TableCell>
          </TableRow>))} 


        </TableBody>
      </Table>
    </TableContainer>
</div>

        )

    }



   



    return (
        <div style={{height:"100%"}} >

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseannounce} aria-labelledby="customized-dialog-title" open={openannounce}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement</span>
                    <IconButton aria-label="" onClick={handleCloseannounce} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>
      
<div align="center">

<FormControl variant="standard" style={{ margin: 10 , width:"90%"}}>

                            <TextField
                                id="subject"
                                placeholder="Subject"
                                type="text"
                                style={{width:"100%"}}
                                variant="outlined"
                                size="small"



                            />
                        </FormControl>
<br/>
 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="message_"
                                placeholder="Message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendannouncement()} color="primary">
                                    Submit
                                </Button>
                            }



                </DialogActions>
            </Dialog>
            

            <Dialog fullWidth maxWidth="sm" onClose={handleClosebulk} aria-labelledby="customized-dialog-title" open={openbulk}>
            <DialogTitle id="customized-dialog-title">
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Bulk SMS to Guardians</span>
                    <IconButton aria-label="" onClick={handleClosebulk} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent> 
                 
<div align="center">


<FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="smsdata"
                                placeholder="Enter message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

                 
 <Button autoFocus onClick={handlebulksms} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Submit
                    </Button>
                   
                </DialogActions>
            </Dialog>
            



           <Container style={{backgroundColor:"white", }}>
            <br/>
           <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
               Reports
            </Typography>
            <br/>
<Grid container spacing={2} >
 
<Grid item xs={8}>
    <div style={{border:"none", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px"}}>

        <div style={{paddingLeft:"12px", paddingRight:"12px"}}>

{/* <div style={{backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, textAlign:"center", color:"white", borderRadius:"8px", padding:"8px"}}> */}
    
<div className='scroller3' style={{textAlign:"center", color:"white", borderRadius:"8px", padding:"8px", overflowY:"scroll", height:"450px"}}>



{viewer==="" && newpage()}
{viewer==="Admitted" && admissionreport()}
{viewer==="Fees" && schoolfeesreport()}
{viewer==="Attendance" && attendancereport()}
{viewer==="Others" && nonbillablereport()}
{viewer==="Expenses" && expensesreport()}
{viewer==="Satement" && incomestatement()}
{/* {expensesreport()} */}



</div>

</div>

      


</div>



</Grid>

<Grid item xs={4}>
    <div  style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", marginBottom:40}}>
    <p style={{}}><b>Search Reports</b></p>
<Divider/>

   {/* <Search style={{marginRight:"-40px", paddingLeft:10, marginTop:5, position:"absolute", fontSize:"32px", color:"gray" }}/><input type="search" placeholder='search' style={{padding:10,paddingLeft:40, fontSize:"18px", color:'gray', borderRadius:"10px",border:"none", borderWidth:"0.1px", borderColor:"lightgrey", width:"90%" }}/> */}
    
    <div style={{display:"flex", margin:"10px"}}>

    
    <TextField
                                    id="dob"
                                    label="Start Date"
                                    type="date"
                                    value={startdate}
                                    style={{marginRight:"5px"}}
                                   onChange={(e)=>setstartdate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="signup"
                                    size="small"
                                />
                                <span style={{marginTop:"7px"}}>to</span>
                                
                                <TextField
                                    id="dob"
                                    label="End Date"
                                    type="date"
                                    style={{marginLeft:"5px"}}
                                    value={enddate}
                                   onChange={(e)=>setenddate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="signup"
                                    size="small"
                                />
    </div>
    
    <Divider/>


    <div className='scroller3' style={{overflowY:"scroll", height:"450px"}}>





<div align="left" style={{margin:8}}>
    <div style={{border:"none", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", padding:5, marginTop:8}}>
       
    <Grid container>


                            <Grid item xs={12}> 
                            
                            <div onClick={()=>setviewer("Admitted")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "Admission Report"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>

                                <div onClick={()=>setviewer("Attendance")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "Attendance Report"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>

                                <div onClick={()=>setviewer("Fees")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "School Fees Report"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>

                                <div onClick={()=>setviewer("Others")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "Non-Billable Report"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>
                             
                                <div onClick={()=>setviewer("Expenses")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "Expenditure Report"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>

                                <div onClick={()=>setviewer("Satement")} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= "Income Statement"
                                // secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>
                             
                              
                             </Grid>

    </Grid>
    </div>
</div>





    </div>





    
    
    </div>
</Grid>
</Grid>
</Container>
        </div>
    );
}

export default Profile;
