import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { JsonToExcel } from 'react-json-to-excel';

import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';
import Tablereport from './tablereport';
import Tableexpenses from './expendituretable';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import Attendance from './date';



import TextField from '@mui/material/TextField';
import Menustyle from './menuexpenses';
import Menustyle1 from './menuexpenses1';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import Person from './images/Ellipse77.png';

import Stack from '@mui/material/Stack';
import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateexpensesdata, getdataexpenses } from './redux/expenses';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(id, term, expenses, impress, balance, date, time ) {
    return {
    id, term, expenses, impress, balance, date, time
        
    };
}
var counting=0

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

 
    {
        id: 'date',
        numeric: !true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'expenses',
        numeric: !true,
        disablePadding: false,
        label: 'Amount Spent',
    }
   , 
    {
        id: 'purpose',
        numeric: !true,
        disablePadding: false,
        label: 'Purpose',
    }, {
        id: 'description',
        numeric: !true,
        disablePadding: false,
        label: 'Description',
    }, 
    {
        id: 'purpose',
        numeric: !true,
        disablePadding: false,
        label: 'Recipient',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            EXPENDITURE
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

   
    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };



    const dispatch = useDispatch()
    const schools = useSelector((state) => state.expenses)

    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }




    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/expenditure/getexpensesdata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdataexpenses({

                        data: res.data

                    }))


                }

            })

    }

  

    React.useEffect(()=>{
        if(schooldetails_.length===0){
        schooldata()
        }

        if(schools.length===0){
        gettingdata()
        }
    },[])
   

    const data = [schools.map((list, index) => createData( list._id, list.term, list.expenses, list.impress, list.balance, list.date, list.time ))]

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    var rows = data[0]
  
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [opensupplyedit, setOpensupplyedit] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
   
    var exceldata=[]

    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openviewbill, setOpenviewbill] = React.useState(!true);
    const [openattendance, setOpenattendance] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openimpress, setOpenimpress] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openedit2, setOpenedit2] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openmessage, setOpenmessage] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [guardian, setGuardian] = React.useState('Mother');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState('');
    const [chosen1, setchosen1] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [loadingedit, setloadingedit] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("none")
    const [selectedgender, setselectedgender] = React.useState("none")
    const [selectedreligion, setselectedreligion] = React.useState("none")
    const [selectedterm, setselectedterm] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [selectedtermedit, setselectedtermedit] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [selectedtermedit2, setselectedtermedit2] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [selectedpurpose, setselectedpurpose] = React.useState("none")
    const [selectedpurposeedit, setselectedpurposeedit] = React.useState("none")
    const [person, setperson] = React.useState(Person)
    const [what, setwhat] = React.useState("back")
    
    var debitmain=0

    const credittotal=(data_)=>{
        var total_=0
        for (var i in data_){
            total_+=parseFloat(data_[i].impress)
        }
        return total_
    } 
    
    const debittotal=(data_)=>{
        var total_=0
        
        for (var i in data_){
                total_+=parseFloat(data_[i].amountspent !==undefined?data_[i].amountspent:0)
        }
        return total_
       
    }

    const searching = (e) => {
        setsearch(e.target.value)
    }

    if (search !== "") {
        var listed = rows.filter(bug => bug.date.toLowerCase().includes(search.toLowerCase()) || bug.term.toLowerCase().includes(search.toLowerCase())|| bug.balance.toLowerCase().includes(search.toLowerCase()) )
       rows=listed
    }

    if(sorter!=="none"){
        var listed = rows.filter(bug => bug.term===sorter )
       rows=listed
    }
  

    //actions
    const addimpress = (data) => {
        setloading(true)
     var balance = 0
      var impress = document.getElementById("impress").value
        var term = selectedterm
        if(rows.length !==0){
            balance= parseFloat(rows[rows.length-1].balance) + parseFloat(impress)
        }else{
            balance = impress

        }
           
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/addimpress/",
                {
                    term: term,
                    impress: impress,
                    balance:balance,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {
                        dispatch(getdataexpenses({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added an impress of GHC " + impress)
                        setstated("success")
                        handleCloseimpress()
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding impress")
                        setstated("error")
                        handleCloseimpress()

                    }

                })

        }
try{
            doer()
}catch(err){
    setloading(!true)
    handleClosesupply()
    handleClicksnack()
    setmessage("Network Error")
    setstated("error")
    handleCloseimpress()
}

    }



    const addexpenses = (data) => {
        setloading(true)
       
        var data = [rows[rows.length-1]]
        var amountpaid = document.getElementById("amountpaid").value
        var paidto = document.getElementById("paidto").value
        var description = document.getElementById("description").value
        var balance = 0
        if(data[0].expenses.length===0){
        balance=parseFloat(data[0].balance) - parseFloat(amountpaid)
        }else{ balance=parseFloat(data[0].balance) - parseFloat(amountpaid)
}
        var expenses = {date:date, time:time, term:selectedterm, id:parseInt(new Date().getTime()), amountspent:amountpaid, balance:0, paidto:paidto, purpose:selectedpurpose, description:description}
     
        var term = selectedterm
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/addexpenses/",
                {
                    _id: data[0].id,
                    term: term,
                    expenses: expenses,
                    balance: balance,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateexpensesdata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage(`You have successfully added an expenses of GHC ${amountpaid}`)
                        setstated("success")
                        handleClosesupply()
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding impress")
                        setstated("error")
                        handleClosesupply()

                    }

                })

        }
try{
            doer()
}catch(err){
    setloading(!true)
    handleClosesupply()
    handleClicksnack()
    setmessage("Network Error")
    setstated("error")
    handleClosesupply()
}

    }
 


    const editexpenses = (data) => {
        setloadingedit(true)
        var amountpaid = document.getElementById("amountpaidedit").value
        var paidto = document.getElementById("paidtoedit").value
        var description = document.getElementById("descriptionedit").value
        var compile = []

       compile = {date:data.date, time:data.time, term:selectedtermedit,id:data.id , amountspent:amountpaid, balance:0, paidto:paidto, purpose:selectedpurposeedit, description:description}


        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/editexpenses/",
                {
                    compile:compile,
                    id:data.id,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
                        dispatch(updateexpensesdata({

                            data: res.data

                        }))
                        setloadingedit(!true)
                       
                        handleClosesupply()
                        handleClicksnack()
                        setmessage(`You have successfully edited the expenses`)
                        setstated("success")
                        handleCloseedit()
                       

                        
                    } else {
                        setloadingedit(!true)
                        handleCloseedit()
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding impress")
                        setstated("error")

                    }

                })

        }

        try{
            doer()
        }catch(err){
             setloadingedit(!true)
                          openedit(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")
        }
        

    }


    const deleteexpenses = (data) => {
        setloadingedit(true)
        
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/deleteexpenses/",
                {
                    _id:data._id,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
                        dispatch(updateexpensesdata({

                            data: res.data

                        }))
                        setloadingedit(!true)
                       
                        handleClosesupply()
                        handleClicksnack()
                        setmessage(`You have successfully Deleted the expenses`)
                        setstated("success")
                        handleCloseedit()
                       

                        
                    } else {
                        setloadingedit(!true)
                        handleCloseedit()
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error deleting impress")
                        setstated("error")

                    }

                })

        }

        try{
            doer()
        }catch(err){
             setloadingedit(!true)
                          openedit(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")
        }
        

    }



    const editimpress = (data) => {
        setloadingedit(true)
        var last = [rows[rows.length-1]]
        var amountpaid = document.getElementById("impressedit").value
        var balance = 0
        var value = 0
        var compile = []
        var indexer=rows.findIndex(bug=>bug.id===chosen.id)

        for(var i=indexer; i<=rows.length-1; i++){
                  
            value =  parseFloat(rows[indexer].impress)-parseFloat(amountpaid)
            
            // var secondindex = rows[i].expenses.findIndex(bug=>bug.id===chosen.id)
           
            for(var k in rows[i].expenses){

                if(indexer===i ){
        
                    balance = parseFloat(rows[i].expenses[k].balance)-parseFloat(value)
                    compile.push({mainID:rows[i.id], data:{date:rows[i].expenses[k].date, time:rows[i].expenses[k].time, term:selectedtermedit,id:rows[i].expenses[k].id , amountspent:rows[i].expenses[k].amountspent, balance:balance, paidto:rows[i].expenses[k].paidto, purpose:rows[i].expenses[k].purpose, description:rows[i].expenses[k].description}})
                    
                  
                
                }else{
                if(indexer<i){
                    balance = parseFloat(rows[i].expenses[k].balance)-parseFloat(value)
                    compile.push({mainID:rows[i.id], data:{date:rows[i].expenses[k].date, time:rows[i].expenses[k].time, term:selectedtermedit,id:rows[i].expenses[k].id , amountspent:rows[i].expenses[k].amountspent, balance:balance, paidto:rows[i].expenses[k].paidto, purpose:rows[i].expenses[k].purpose, description:rows[i].expenses[k].description}})
                
                }
            }
            }

        }

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/editimpress/",
                {
                    compile:compile,
                    _id:chosen.id,
                    newvalue:amountpaid,
                    value:parseFloat(value),
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
// consoles.log(res)
                        dispatch(updateexpensesdata({

                            data: res.data

                        }))
                        setloadingedit(!true)
                       
                        handleClosesupply()
                        handleClicksnack()
                        setmessage(`You have successfully edited the expenses`)
                        setstated("success")
                        handleCloseedit2()
                       

                        
                    } else {
                        setloadingedit(!true)
                        handleCloseedit2()
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding impress")
                        setstated("error")

                    }

                })

        }

        try{
            doer()
        }catch(err){
             setloadingedit(!true)
                          openedit(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")
        }
        

    }


    const addschool = (data) => {
        setloading(true)
        var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {


                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")                    }

                })

        }

            doer()

    }

     const deleter = (data) => {
     setloading(true)
        var data = [rows[rows.length-1]]
        console.log(data)
       
        var expenses = rows[0].expenses.filter(bug=>bug !== chosen)
        var balance = parseFloat(data[0].balance)
        var term = selectedterm
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/expenditure/addexpenses/",
                {
                    _id: data[0].id,
                    term: term,
                    expenses: expenses,
                    balance: balance,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateexpensesdata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage(`You have successfully deleted an expenses`)
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding impress")
                        setstated("error")

                    }

                })

        }

            doer()

    }

    const handleClickOpenedit = (data1,data) => {
        setchosen1(data1)
        setchosen(data)
        setselectedpurposeedit(data.purpose)
        setselectedtermedit(data1.term)
        setOpenedit(true);
    };
    const handleCloseedit = () => {
        setOpenedit(false);
    };

  
    const handleClickOpenedit2 = (data) => {
        setchosen(data)
        setselectedtermedit2(data.term)
        setOpenedit2(true);
    };
    const handleCloseedit2 = () => {
        setOpenedit2(false);
    };

  
    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        setchosen(data)
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };

   
    const handleCloseadd = () => {
        setOpenadd(false);
    };

   

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        setOpenview(true);
    };
    const handleCloseview = () => {
        setOpenview(false);
    };

   

    const handleClickOpenviewbill = (data) => {
        console.log(data)
        setchosen(data)
        setOpenviewbill(true);
    };
    const handleCloseviewbill = () => {
        setOpenviewbill(false);
    };

      const handleClickOpenattendance = (data) => {
        console.log(data)
        setchosen(data)
        setOpenattendance(true);
    };
    const handleCloseattendance = () => {
        setOpenattendance(false);
    };

    const handleClickOpenmessage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenmessage(true);
    };
    const handleClosemessage = () => {
        setOpenmessage(false);
    };
    const handleClickOpensupply = (data) => {
        console.log(data)
        setchosen(data)
        setOpensupply(true);
    };
    const handleClosesupply = () => {
        setOpensupply(false);
    };
    
    const handleClickOpensupplyedit = (data) => {
        setchosen(data)
        setOpensupplyedit(true);
    };
    const handleClosesupplyedit = () => {
        setOpensupplyedit(false);
    };


    const handleClickOpenimpress = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimpress(true);
    };
    const handleCloseimpress = () => {
        setOpenimpress(false);
    };

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }
    const handleChangepurpose = (e) => {
        setselectedpurpose(e.target.value)
    }  
    const handleChangepurposeedit = (e) => {
        setselectedpurposeedit(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }
  const handleChangeterm = (e) => {
        setselectedterm(e.target.value)
    }  
    const handleChangetermedit = (e) => {
        setselectedtermedit(e.target.value)
    }
  const handleChangetermedit2 = (e) => {
        setselectedtermedit2(e.target.value)
    }

    
    const sorting = (e) => {
        
        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

const setback=(data)=>{
    setwhat(data)
}
        const sendmessage = () =>{

            return(



<div style={{textAlign:"center", margin:8}}>
<p style={{textAlign:"center", margin:12}}>
                        Send a message to Guardian via 
                    </p>
                    <Button  aria-label="fingerprint"  style={{margin:12}} color="primary">
        <WhatsAppIcon style={{fontSize:"30px"}} /> <br/> <small> Whatsapp</small>
      </Button>
           <Button onClick={()=>setback("email")} aria-label="fingerprint" style={{margin:12}} color="primary">
        <EmailIcon style={{fontSize:"30px"}} /> <br/> <small> Email</small>
      </Button>
   <Button onClick={()=>setback("sms")} aria-label="fingerprint" style={{margin:12}} color="primary">
        <MessageIcon style={{fontSize:"30px"}} /> <br/> <small> Text Message</small>
      </Button>

 

                    
                
</div>

            )
        }

        const viaSMS = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }
    
        const viaEmail = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }


   const viaEmailandtext = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email and SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }

        const guardianchange =(e)=>{

            setGuardian(e.target.value)

        }

const messenger =(type)=>{
    if(type==="email"){
        return(
            viaEmail()
        )
    }
    if(type==="sms"){
        return(
            viaSMS()
        )
    }  if(type==="back"){
        return(
            sendmessage()
        )
    }
}

        const otherguardian = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="surname"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"

    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="othernames"
    placeholder="Other name(s)"
    type="text"
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="occupation"
    placeholder="Occupation"
    type="text"
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="contact"
    placeholder="Contact"
    type="tel"
    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="email"
    placeholder="Email"
    type="email"
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="address"
    placeholder="Address"
    type="address"
    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }

    return (

        <Box sx={{ width: '100%' }}>

       

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>

                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={()=>deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    

                    <Button autoFocus onClick={handleClosedelete} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            
            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessage} aria-labelledby="customized-dialog-title" open={openmessage}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", color: "white" }}>
                    Pay Fees
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Semester</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={age}
                            className="signup"
                            onChange={handleChange}
                            autoWidth
                            label="Semester"
                        >
                            <MenuItem value="">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaid"
    placeholder="Amount Paid"
    type="number"
    className="signup"
    variant="outlined"
    label="Amount Paid"
    size="small"



/>
                    </FormControl>


                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidby"
    placeholder="Paid By"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid By"
    size="small"
/>
                    </FormControl>




                    </DialogContent>
                <DialogActions>
<Divider/>
                    {loading === false ?
                        <Button autoFocus onClick={()=>deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    

                    <Button autoFocus onClick={handleClosemessage} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog fullWidth maxWidth="sm" onClose={handleCloseattendance} aria-labelledby="customized-dialog-title" open={openattendance}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", color: "white" }}>
                    Attendance
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

                    <Attendance/>
                
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseattendance} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog  maxWidth="md" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
               <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Debit
                    <IconButton onClick={handleClosesupply} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    
                <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            onChange={handleChangeterm}
                            autoWidth
                            label="Term"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaid"
    placeholder="Amount Paid"
    type="number"
    className="signup"
    variant="outlined"
    label="Amount Paid"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

<br/>
                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidto"
    placeholder="Paid To"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid To"
/>
                    </FormControl>

                    <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Purpose</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedpurpose}
                            className="signup"
                            onChange={handleChangepurpose}
                            autoWidth
                            label="Purpose"
                        >
                            <MenuItem value="none">
                                <em>Choose Purpose</em>
                            </MenuItem>
                            <MenuItem value="Food">Food</MenuItem>
                            <MenuItem value="Building">Building</MenuItem>
                            <MenuItem value="Transport">Transport</MenuItem>
                            <MenuItem value="Health">Health</MenuItem>
                            <MenuItem value="Accident">Accident</MenuItem>
                            <MenuItem value="Books">Books</MenuItem>
                            <MenuItem value="Academics">Academics</MenuItem>
                            <MenuItem value="Computer">Computer</MenuItem>
                            <MenuItem value="Library">Library</MenuItem>
                            <MenuItem value="Canteen">Canteen</MenuItem>
                            <MenuItem value="Classroom">Classroom</MenuItem>
                            <MenuItem value="Office">Office</MenuItem>
                            <MenuItem value="Washroom">Washroom</MenuItem>

                        </Select>
                    </FormControl>
<br/>

<FormControl style={{ margin: 10}}>
                        <TextField
                            labelId="demo-simple-select-autowidth-label"
                            id="description"
                            value={age}
                            style={{width:"100%"}} 
                            onChange={handleChange}
                           placeholder='Description'
                           rows={5}
                        />
                           
                    </FormControl>

                    <br/>
<div align="center">
                    {loading === false ?
                        <Button autoFocus onClick={() => addexpenses(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    </div>
                </DialogContent>

               
            </Dialog>



            <Dialog  maxWidth="md" onClose={handleClosesupplyedit} aria-labelledby="customized-dialog-title" open={opensupplyedit}>
               <DialogTitle id="customized-dialog-title" onClose={handleClosesupplyedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Debit
                    <IconButton onClick={handleClosesupplyedit} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    
                <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            onChange={handleChangeterm}
                            autoWidth
                            label="Term"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaidedit"
    placeholder="Amount Paid"
    type="number"
    className="signup"
    variant="outlined"
    label="Amount Paid"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

<br/>
                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidtoedit"
    placeholder="Paid To"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid To"
/>
                    </FormControl>

                    <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Purpose</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedpurpose}
                            className="signup"
                            onChange={handleChangepurpose}
                            autoWidth
                            label="Purpose"
                        >
                            <MenuItem value="none">
                                <em>Choose Purpose</em>
                            </MenuItem>
                            <MenuItem value="Food">Food</MenuItem>
                            <MenuItem value="Building">Building</MenuItem>
                            <MenuItem value="Transport">Transport</MenuItem>
                            <MenuItem value="Health">Health</MenuItem>
                            <MenuItem value="Accident">Accident</MenuItem>
                            <MenuItem value="Books">Books</MenuItem>
                            <MenuItem value="Academics">Academics</MenuItem>
                            <MenuItem value="Computer">Computer</MenuItem>
                            <MenuItem value="Library">Library</MenuItem>
                            <MenuItem value="Canteen">Canteen</MenuItem>
                            <MenuItem value="Classroom">Classroom</MenuItem>
                            <MenuItem value="Office">Office</MenuItem>
                            <MenuItem value="Washroom">Washroom</MenuItem>

                        </Select>
                    </FormControl>
<br/>

<FormControl style={{ margin: 10}}>
                        <TextField
                            labelId="demo-simple-select-autowidth-label"
                            id="descriptionedit"
                            value={age}
                            style={{width:"100%"}} 
                            onChange={handleChange}
                           placeholder='Description'
                           rows={5}
                        />
                           
                    </FormControl>

                    <br/>
<div align="center">
                    {loading === false ?
                        <Button autoFocus onClick={() => addexpenses(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    </div>
                </DialogContent>

               
            </Dialog>



            <Dialog  maxWidth="md" onClose={handleCloseimpress} aria-labelledby="customized-dialog-title" open={openimpress}>
               <DialogTitle id="customized-dialog-title" onClose={handleCloseimpress} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Impress
                    <IconButton onClick={handleCloseimpress} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    
                <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            onChange={handleChangeterm}
                            autoWidth
                            label="Term"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="impress"
    placeholder="Amount"
    type="number"
    className="signup"
    variant="outlined"
    label="Amount"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>


                    <br/>
                    
<div align="center">
                    {loading === false ?
                        <Button autoFocus onClick={() => addimpress(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    </div>
                </DialogContent>

               
            </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>

                <DialogContent dividers style={{ textAlign: "center" }}>
                    

                   <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+chosen.image} width="100%"/>

                </DialogContent>

                <DialogActions>
                                                        
                    <Button autoFocus onClick={handleCloseimage} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>



                <Dialog  maxWidth="md" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", color: "white" }}>
                        Add Class Fees
                    </DialogTitle>

                    <DialogContent dividers>



                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="none"> <em>Choose Class</em> </MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>
                                

                            </Select>


                        </FormControl>

                   
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="Fees"
    placeholder="Fees"
    type="number"
    className="signup"
    variant="outlined"
    label="Hometown"
    size="small"
/>

</FormControl>



                        <div style={{ textAlign: "center" }}>

                            {loading === false ?
                                <Button autoFocus onClick={() => addschool()} color="primary">
                                    Submit
                                </Button>
                                :
                                <Button disabled={loading} >

                                    <CircularProgress size={20} /> Loading...

                                </Button>
                            }

                           
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleCloseadd} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", color: "white" }}>
                        Edit Expenses
                    </DialogTitle>
                    <DialogContent dividers style={{ textAlign: "left" }}>
                    
                    <FormControl style={{ margin: 10}}>
                            <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id=""
                                value={selectedtermedit}
                                className="signup"
                                onChange={handleChangetermedit}
                                autoWidth
                                label="Term"
                            >
                                <MenuItem value="none">
                                    <em>Choose Term</em>
                                </MenuItem>
                                {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                                schooldetails_[0].terms.map((list,index)=>
                                <MenuItem value={list}>{list}</MenuItem>
    
                                ) :""   
                            }
                            </Select>
                        </FormControl>
                        
    
                        <FormControl variant="standard" style={{ margin: 10 }}>
    
    <TextField
        id="amountpaidedit"
        // placeholder="Amount Paid"
        defaultValue={chosen.length!==0 && chosen.amountspent}
        type="number"
        className="signup"
        variant="outlined"
        label="Amount Paid"
        InputProps={{
            startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
          }}
    
    
    
    />
                        </FormControl>
    
    <br/>
                       <FormControl variant="standard" style={{ margin: 10 }}>
    
    <TextField
        id="paidtoedit"
        // placeholder="Paid To"
        type="text"
        defaultValue={chosen.length!==0 && chosen.paidto}
    
        className="signup"
        variant="outlined"
        label="Paid To"
    />
                        </FormControl>
    
                        <FormControl style={{ margin: 10}}>
                            <InputLabel id="demo-simple-select-autowidth-label">Purpose</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id=""
                                value={selectedpurposeedit}
                                className="signup"
                                onChange={handleChangepurposeedit}
                                autoWidth
                                label="Purpose"
                            >
                                <MenuItem value="none">
                                    <em>Choose Purpose</em>
                                </MenuItem>
                                <MenuItem value="Food">Food</MenuItem>
                                <MenuItem value="Building">Building</MenuItem>
                                <MenuItem value="Transport">Transport</MenuItem>
                                <MenuItem value="Health">Health</MenuItem>
                                <MenuItem value="Accident">Accident</MenuItem>
                                <MenuItem value="Books">Books</MenuItem>
                                <MenuItem value="Academics">Academics</MenuItem>
                                <MenuItem value="Computer">Computer</MenuItem>
                                <MenuItem value="Library">Library</MenuItem>
                                <MenuItem value="Canteen">Canteen</MenuItem>
                                <MenuItem value="Classroom">Classroom</MenuItem>
                                <MenuItem value="Office">Office</MenuItem>
                                <MenuItem value="Washroom">Washroom</MenuItem>
    
                            </Select>
                        </FormControl>
    <br/>
    
    <FormControl style={{ margin: 10}}>
                            <TextField
                                labelId="demo-simple-select-autowidth-label"
                                id="descriptionedit"
                                defaultValue={chosen.length!==0 && chosen.description}
                                style={{width:"100%"}} 
                                onChange={handleChange}
                               placeholder='Description'
                               rows={5}
                            />
                               
                        </FormControl>
    
                        <br/>
    <div align="center">
                        {loadingedit === false ?
                            <Button autoFocus onClick={() => editexpenses(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                Submit
                            </Button>
                            :
                            <Button disabled={loading} >
    
                                <CircularProgress size={20} /> Loading...
    
                            </Button>
                        }
                        </div>
                    </DialogContent>
    
                    <DialogActions>
                        <Button autoFocus onClick={handleCloseedit} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseedit2} aria-labelledby="customized-dialog-title" open={openedit2}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit2} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", color: "white" }}>
                        Edit Impress
                    </DialogTitle>
                    <DialogContent dividers style={{ textAlign: "left" }}>
                    
                    <FormControl style={{ margin: 10}}>
                            <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id=""
                                value={selectedtermedit2}
                                className="signup"
                                onChange={handleChangetermedit2}
                                autoWidth
                                label="Term"
                            >
                                <MenuItem value="none">
                                    <em>Choose Term</em>
                                </MenuItem>
                                {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                                schooldetails_[0].terms.map((list,index)=>
                                <MenuItem value={list}>{list}</MenuItem>
    
                                ) :""   
                            }
                            </Select>
                        </FormControl>
                        
    
                        <FormControl variant="standard" style={{ margin: 10 }}>
    {console.log(chosen.impress)}
    <TextField
        id="impressedit"
        placeholder="Amount"
        type="number"
        defaultValue={chosen!==undefined?chosen.impress:0}
        className="signup"
        variant="outlined"
        label="Amount"
        InputProps={{
            startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
          }}
    
    
    
    />
                        </FormControl>
    
    
                        <br/>
                        
    <div align="center">
                        {loadingedit === false ?
                            <Button autoFocus onClick={() => editimpress(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                Submit
                            </Button>
                            :
                            <Button disabled={loading} >
    
                                <CircularProgress size={20} /> Loading...
    
                            </Button>
                        }
                        </div>
                    </DialogContent>
    
                
                    <DialogActions>
                        <Button autoFocus onClick={handleCloseedit2} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />
                <Divider/>
<Grid container style={{margin:0, padding:0, textAlign:"left", marginLeft:20}}>

<Grid lg={3} xs={2} md={2} >
{/* <p><b>Total Impress</b>: GHC {Intl.NumberFormat('en-US').format(rows.length!==0?credittotal(rows):0)}</p> */}
<p style={{marginTop:"20px"}}><b>Total Expenses</b>: GHC {Intl.NumberFormat('en-US').format(rows.length!==0?debittotal(rows):0)}</p>
</Grid>
{/* <Grid lg={3} xs={2} md={2}>
<p mt={2}><b>Balance</b>: GHC {Intl.NumberFormat('en-US').format(rows.length!==0?rows[rows.length-1].balance:0)}</p>
</Grid> */}
<Grid lg={3} xs={2} md={2}>
<p><Button style={{backgroundColor:" #e0eff9" , color:"#1976d2"}} onClick={
    handleClickOpensupply
}>Add Expenses</Button></p>
</Grid>


</Grid>
<Divider/>
<div style={{textAlign:"center", marginTop:"6px", marginBottom:"5px"}}><JsonToExcel title="Download Expenses as Excel"  data={exceldata} fileName="School fees data" btnClassName="custom-classname" />
</div>
                <Grid container mt={2} >
      
                    <Grid lg={3} xs={2} md={6} style={{textAlign:"center"}}>
                        <FormControl style={{ marginRight: 2, minWidth: 80, padding: 0, marginTop: 10, outline:"none",border:"none", backgroundColor:" #e0eff9" , color:"#1976d2" }}>
                            <Select
                                id="demo-simple-select-autowidth"
                                value={sorter}
                                onChange={sorting}
                                autoWidth
                                style={{ height: "38px", width: "100%", padding: 0,backgroundColor:" #e0eff9" , color:"#1976d2", outlined:"none",border:"none", }}
                            >

                                <MenuItem value="none">Sort by Term</MenuItem>
                                {/* <MenuItem value="All">All</MenuItem> */}
                                {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid lg={4} xs={10} md={6}>
                        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color:"grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "100%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index_) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index_}`;
                          
                        return    (
                            
                            <>
                       
                         
                         
                                        <TableRow hover>

                                            <TableCell style={{ textAlign: "center" }}>
<div style={{display:"none"}}>                                            {exceldata.push({Date:(months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] ), Amount_Spent: "GHC "+row.amountspent, Purpose:row.purpose, Description:row.description, Paid_to:row.paidto })}
</div>
                                                <span>
                                                    {index_+1+"."}
                                                   

                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.date && (months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] )}</TableCell>
                                           
                                            <TableCell align="left">GHC {Intl.NumberFormat('en-US').format(row.amountspent)}</TableCell>
                                            <TableCell align="left">{row.purpose}</TableCell>
                                            <TableCell align="left">{row.description}</TableCell>
                                            <TableCell align="left">{row.paidto}</TableCell>
                                          
                                            <TableCell align="left">{<Menustyle data={row} view={() => handleClickOpenview(row)} viewbill={() => handleClickOpenviewbill(row)} attendance={(sender) => handleClickOpenattendance(sender)}  academics={(sender) => handleClickOpensupply(sender)} message={(sender) => handleClickOpenmessage(sender)} addsupply={(sender) => handleClickOpensupply(sender)} edit={() => handleClickOpenedit(row,row)} delete={() => handleClickOpendelete(row)}  />}</TableCell>

                                        </TableRow>
                                
                                
                           


                        
</> )

                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>


    );
}
