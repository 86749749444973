import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import EmailIcon from '@mui/icons-material/Email';

import OutlinedInput from '@mui/material/OutlinedInput';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';

import TextField from '@mui/material/TextField';
import Menustyle from './menuteachers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';

import EngineeringIcon from '@mui/icons-material/Engineering';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import {  getdata_ } from './redux/class';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, BrowserRouter } from "react-router-dom";


import Stack from '@mui/material/Stack';
import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ContactsOutlined } from '@mui/icons-material';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(_id, id, lastname, othernames, image, contact, position,positiondescription, gender, dob, hometown, residence, religion, email, finance, attendance, maritalstatus,tittle,status, class_, title, subjectstoteach, positiondescription_, bank, snnit, accountnumber) {
    return {
        _id,
        id,
        lastname,
        othernames,
        image,
        contact,
        position,
        positiondescription,
        gender,
        dob,
        hometown,
        residence,
        religion,
        email, 
        finance,
        attendance,
        maritalstatus,
        tittle,
        status,
        class_,
        title,
        subjectstoteach,
        positiondescription_, bank, snnit, accountnumber

    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [



    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },

    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'position',
        numeric: !true,
        disablePadding: false,
        label: 'Position',
    },
    {
        id: 'gender',
        numeric: !true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'contact',
        numeric: !true,
        disablePadding: false,
        label: 'Contact',
    }, {
        id: 'email',
        numeric: !true,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'address',
        numeric: !true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                STAFF
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const schooldetails_ = useSelector((state) => state.schooldetails)

    const [opensnack, setOpensnack] = React.useState(false);
    const [logpage, setlogpage] = React.useState(false);

    const [activeworker, setactiveworker] = React.useState(true);
    const [inactiveworker, setinactiveworker] = React.useState(false);
    const [bulkmessage, setbulkmessage] = React.useState(schooldetails_.length!==0 && schooldetails_[0].bulkmessage!==undefined && schooldetails_[0].bulkmessage !==false?true: false);
    const [addstudent, setaddstudent] = React.useState(schooldetails_.length!==0 && schooldetails_[0].addstudent!==undefined && schooldetails_[0].addstudent !==false?true: false);
    const [gettingdatatype, setgettingdatatype] = React.useState("Active");
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const dispatch = useDispatch()
    const schools = useSelector((state) => state.teachers)
    var Class_ = useSelector((state) => state.Class_)
    const admin = useSelector((state) => state.admin)
    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }



    const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }


    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdatateacher({

                        data: res.data

                    }))


                }

            })

    }
 

    useEffect(() => {
       
        schooldata()
        gettingdata()
        gettingdata_()

    }, [gettingdatatype]);


    const data = [schools.map((list, index) => createData(list._id, list.ID, list.lastname, list.othernames, list.image, list.contact, list.position,list.positiondescription, list.gender, list.dob, list.hometown, list.residence, list.religion, list.email, list.finance, list.attendance, list.maritalstatus, list.tittle, list.status, list.class_, list.title, list.subjectstoteach,list.positiondescription_, list.bank, list.snnit, list.accountnumber))]

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    var rows = data[0]

    const [trialmode, settrialmode] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [passwordloading, setpasswordloading] = React.useState(!true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [loaddelete, setloaddelete] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [status_selector, setstatus_selector] = React.useState("");
    const [openstatus, setOpenstatus] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("")
    const [position, setposition] = React.useState("")
    const [tittle, settittle] = React.useState("")
    const [positiondescription, setpositiondescription] = React.useState("")
    const [positiondescription_, setpositiondescription_] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("")
    const [selectedreligion, setselectedreligion] = React.useState("")
    
    const [selectedclassedit, setselectedclassedit] = React.useState("")
    const [positionedit, setpositionedit] = React.useState("")
    const [tittleedit, settittleedit] = React.useState("")
    const [positiondescriptionedit, setpositiondescriptionedit] = React.useState("")
    const [positiondescriptionedit_, setpositiondescriptionedit_] = React.useState("")
    const [positiondescription_edit, setpositiondescription_edit] = React.useState("")
    const [selectedgenderedit, setselectedgenderedit] = React.useState("")
    const [selectedreligionedit, setselectedreligionedit] = React.useState("")
    const [maritalstatusedit, setmaritalstatusedit] = React.useState("")
    const [maritalstatusmain, setmaritalstatusmain] = React.useState("")
    const [person, setperson] = React.useState(Person)

    const [what, setwhat] = React.useState("back")
    const [newstudentloading, setnewstudentloading] = React.useState("false")
    const [newworkerloading, setnewworkerloading] = React.useState("false")
    const [editworkerloading, seteditworkerloading] = React.useState("false")
    const [deleteworkerloading, setdeleteworkerloading] = React.useState("false")
    const [messageloading, setmessageloading] = React.useState("false")
    const [openpassword, setOpenpassword] = React.useState(false)

    const searching = (e) => {
        setsearch(e.target.value)
    }

   

    if(sorter !== "none" && sorter !== "All"){
        if(sorter==="Teaching Staff"){
            var listed = rows.filter(bug => bug.position === sorter )
          
            rows = listed
        }
        else if(sorter==="Non-Teaching Staff" ){
            var listed = rows.filter(bug => bug.position !== "Teaching Staff" )
            rows = listed
        }
            
        }else{
            rows = data[0]
        }


        if (search !== "") {
            var listed = rows.filter(bug => bug.status.toLowerCase().includes(search.toLowerCase()) || bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.contact.includes(search)  || bug.id.toLowerCase().includes(search.toLowerCase()) )
          
            rows = listed
     
        }

      const addstaff = () => {

        var subjectstoteach=[]
                    setnewworkerloading(true)

if(positiondescription==="Subject Teacher"|| positiondescription==="Class and Subject Teacher"){
    for(var i in personName){
        var newer={class:personName[i],subject:listing[i]}
        subjectstoteach.push(newer)
        console.log(subjectstoteach)
    }
}

                    const file = document.getElementById("fileimage").files[0]
                    const image = ""
                    const tittle_ = tittle
                    const lastname = document.getElementById("lastname").value
                    const othernames = document.getElementById("othernames").value
                    const dob = document.getElementById("dob").value
                    const residence = document.getElementById("residence").value
                    const hometown = document.getElementById("hometown").value
                    const contact = document.getElementById("contact").value
                    const email = document.getElementById("email").value
                    const snnit = document.getElementById("snnit").value
                    const accountnumber = document.getElementById("accountnumber").value
                    const bank = document.getElementById("bank").value
                    const maritalstatus = maritalstatusmain
                    const position_ = position
                    const religion = selectedreligion
                    const gender = selectedgender
                    const class_ = personName.length===0?[positiondescription_]:personName
                    const stringer = JSON.stringify(subjectstoteach)

                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", document.getElementById("fileimage").files[0].name)
                    }
                    fd.append("title", tittle_)
                    fd.append("schoolID",schooldetails_[0].schoolID)
                    fd.append("lastname", lastname)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("subjectstoteach", stringer)
                    fd.append("hometown", hometown)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("contact", contact)
                    fd.append("email", email)
                    fd.append("snnit", snnit)
                    fd.append("accountnumber", accountnumber)
                    fd.append("bank", bank)
                    fd.append("maritalstatus", maritalstatus)
                    fd.append("positiondescription", positiondescription)
                    fd.append("positiondescription_", positiondescription_)
                    fd.append("position", position_)
                    fd.append("sendername", schooldetails_[0].smsname)
                    fd.append("senderid", schooldetails_[0].smsID)
                    fd.append("schoolname", schooldetails_[0].schoolname)
                    fd.append("status", gettingdatatype)
                 
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/teachers/registerworker/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                           gettingdata()       
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("New Worker Added")
                        setstated("success")
                                setnewworkerloading(!true)

                            }
                            else{
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Adding Worker")
                        setstated("success")
                                setnewworkerloading(!true)
                            }

                        })

                    }

                    try{
                    uploading()
                }
                catch(err){
                    handleClicksnack()
                    setmessage("Network Error")
                    setstated("error")
                    setnewworkerloading(!true)
                }



                }

      const editstaff = () => {
                    
                    var subjectstoteach=[]
                    seteditworkerloading(true)
                    if(positiondescriptionedit==="Subject Teacher"|| positiondescriptionedit==="Class and Subject Teacher"){
                        for(var i in personName){
                            var newer={class:personName[i],subject:listing[i]}
                            subjectstoteach.push(newer)
                            console.log(subjectstoteach)
                        }
                    }


                    const file = document.getElementById("fileimageedit").files[0]
                    const image = ""
                    const tittle_ = tittleedit
                    const lastname = document.getElementById("lastnameedit").value
                    const othernames = document.getElementById("othernamesedit").value
                    const dob = document.getElementById("dobedit").value
                    const residence = document.getElementById("residenceedit").value
                    const hometown = document.getElementById("hometownedit").value
                    const contact = document.getElementById("contactedit").value
                    const email = document.getElementById("emailedit").value
                    const snnit = document.getElementById("snnitedit").value
                    const accountnumber = document.getElementById("accountnumberedit").value
                    const bank = document.getElementById("bankedit").value
                    const maritalstatus = maritalstatusedit
                    const position_ = positionedit
                    const religion = selectedreligionedit
                    const gender = selectedgenderedit
                    const class_ = personName.length===0?[positiondescriptionedit_]:personName
                    const stringer = JSON.stringify(subjectstoteach)
                   
              
                    if(positiondescription==="Subject Teacher" || positiondescription==="Class and Subject Teacher"){
                        setpositiondescription_(personName)
                    }
                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", dob+lastname+".jpg")
                    }
                    fd.append("_id", chosen._id)
                    fd.append("tittle", tittle_)
                    fd.append("lastname", lastname)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("subjectstoteach", stringer)
                    fd.append("hometown", hometown)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("contact", contact)
                    fd.append("email", email)
                    fd.append("snnit", snnit)
                    fd.append("accountnumber", accountnumber)
                    fd.append("bank", bank)
                    fd.append("maritalstatus", maritalstatus)
                    fd.append("positiondescription", positiondescriptionedit)
                    fd.append("positiondescription_", positiondescriptionedit_)
                    fd.append("position", position_)
                    fd.append("status", gettingdatatype)
                 
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/teachers/editworker/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                              gettingdata()
                               
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Worker's data edited")
                        setstated("success")
                                seteditworkerloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error editing Worker's data")
                        setstated("success")
                                seteditworkerloading(!true)
                            }

                        })

                    }
try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    handleClicksnack()
                    seteditworkerloading(!true)
                }


                }


                const sendsms = () => {
                    setmessageloading(true)

                       
                    const message = document.getElementById("messagesms").value
                   
                
                    const fd = new FormData()
                  
                    fd.append("message", message)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("smsname",schooldetails_[0].smsname)
                    fd.append("smsID",schooldetails_[0].smsID)
                    fd.append("contact", chosen.contact)
                    fd.append("guadianname", chosen.guardianlastname)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/teachers/singlesms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                            if (res.status === 200) {

                        setmessageloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                               
                            }
                           else if (res.status === 204) {
                        
                        setmessageloading(false)
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                
                            }else{
                        
                        setmessageloading(false)
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                        
                            }

                        })

                    }
                    try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setmessageloading(false)
                    handleClicksnack()
                }
                }

    const sendemail = () => {
                    setmessageloading(true)

                    const messagesms = document.getElementById("messageemail").value
                    const subject = document.getElementById("subject").value
                                
                    const fd = new FormData()
                  
                    fd.append("message", messagesms)
                    fd.append("subject", subject)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("teacher", chosen.lastname)
                    fd.append("contact", chosen.contact)
                    fd.append("teacheremail", chosen.email)
                    fd.append("logo", schooldetails_[0].logo)
                    fd.append("schoolname", schooldetails_[0].schoolname)
                    fd.append("address", schooldetails_[0].address)
                    fd.append("contact", schooldetails_[0].contact)
                       
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/teachers/singleemail/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                            if (res.status === 200) {

                        setmessageloading(false)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Email sent")
                        setstated("success")
                               
                            }
                            else if (res.status === 204) {

                        setmessageloading(false)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Email")
                        setstated("error")
                               
                            }else{

                        setmessageloading(false)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Email")
                        setstated("error")
                               
                            }

                        })

                    }

try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setmessageloading(false)
                    handleClicksnack()
                }



                }


    const changeedit = (changer, e) => {
        changer(e.target.value)
        console.log(e.target.value)
    }

    const editschool = (data) => {
        setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/editschool/",
                {
                    id: chosen.id,
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateteacherdata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("data has been edited")
                        setstated("sucess")

                    } else {
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error editting data")
                        setstated("error")
                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
        handleClicksnack()
    }

    }

    const addschool = (data) => {
        setloading(true)
        var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateteacherdata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")
                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
        handleClicksnack()
    }

    }



    const changer_=(e,classer,data)=>{
        console.log("sorted")
        console.log(data)
        var chooser = {...data}
        var subjectstoteach = [...data.subjectstoteach]

        var sorted = subjectstoteach==="none" || (subjectstoteach.length===1 && subjectstoteach[0]==='[]') ? []: subjectstoteach.filter(bug=>bug.class!==classer)
        sorted.push({class:classer, subject:e.target.value})
        console.log(sorted)
        chooser.subjectstoteach=sorted
        console.log(chooser)
        console.log(chosen)
        

        // setchosen(chooser)




    }




    const deleter = (data) => {

        setdeleteworkerloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/teachers/deleteteacher/",
                {
                    _id: chosen._id,
                    schoolID:admin.schoolID,
                    status:gettingdatatype

                }).then(res => {

                    if (res.status === 200) {
                        
                        gettingdata()

                        setdeleteworkerloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setdeleteworkerloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }
try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setdeleteworkerloading(!true)
        handleClicksnack()
    }

    }

    const handleClickOpenedit = (data) => {
        setchosen(data)
        var all = data.class_[0].split(",")
        setselectedclassedit(data.positiondescription)
        setpositiondescriptionedit(data.positiondescription)
        setpositiondescriptionedit_(data.positiondescription_)
        setPersonName(all)
        setselectedgenderedit(data.gender)
        setselectedreligionedit(data.religion)
        setmaritalstatusedit(data.maritalstatus)
        setpositionedit(data.position)
       
        if(data.subjectstoteach!==undefined && data.subjectstoteach!=="" && data.subjectstoteach.length!==0){

            for (var i in data.subjectstoteach){
                if(typeof(data.subjectstoteach[i].subject)!=="string" && data.subjectstoteach[i].subject.length!==0){

                    listingset[i]([...data.subjectstoteach[i].subject])

                }else{

                    listingset[i]([data.subjectstoteach[i].subject])

                }
            }
        }

        setperson("https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+data.image)
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimage(true);
    };

    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClickOpenstatus = (data) => {
        console.log(data)
        setchosen(data)
        setstatus_selector(data.status)
        setOpenstatus(true);
    };

    const handleClosestatus = () => {
        setOpenstatus(false);
    };

    const handleClickOpenpassword = (data) => {
        setchosen(data)
        setOpenpassword(true);
    };

    const handleClosepassword = () => {
        setOpenpassword(false);
    };

    const handleClickOpenadd = (data) => {
        setPersonName([])
for(var i in listingset){
    listingset[i]([])
}
 setpositiondescription("")


        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setOpenadd(true);
        }
    };

    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };

    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };

    const handleClickOpensupply = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpensupply(true);}
    };

    const handleClosesupply = () => {
        setOpensupply(false);
    };

    const totalsupplied = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].quantity
        }


        return total
    }

    const srvrec = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].SRVrec
        }


        return total
    }

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }    
    const handleChange_genderedit = (e) => {
        setselectedgenderedit(e.target.value)
    }    
    const handleChange_tittle = (e) => {
        settittle(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }   
    const handleChange_religionedit = (e) => {
        setselectedreligionedit(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }
  const handleChange_position = (e) => {
        setposition(e.target.value)
    } 
    const handleChange_positionedit = (e) => {
        setpositionedit(e.target.value)
    } 
    
    const handleChange_tittleedit = (e) => {
        settittleedit(e.target.value)
    }    
    const changemaritalstatusedit = (e) => {
        setmaritalstatusedit(e.target.value)
    }
  const changemaritalstatus = (e) => {
        setmaritalstatusmain(e.target.value)
    }

  const handleChange_positiondescription = (e) => {
        setpositiondescription(e.target.value)
    }

  const handleChange_positiondescription_ = (e) => {
        setpositiondescription_(e.target.value)
    }
    
    const handleChange_positiondescriptionedit = (e) => {
        setpositiondescriptionedit(e.target.value)
        var val=e.target.value
        if(val==="Subject Teacher"  || val==="Class and Subject Teacher"){
              var all = data.class_[0].split(",")
                setPersonName(all)
        }else{
            setPersonName([])
        }
    }
  
    const handleChange_positiondescriptionedit_ = (e) => {
        setpositiondescriptionedit_(e.target.value)
    }


    const handleChange_positiondescription_edit = (e) => {
        setpositiondescription_edit(e.target.value)
    }



    const sorting = (e) => {

        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };



    const setback=(data)=>{
    setwhat(data)
}
        const sendmessage = () =>{

            return(



<div style={{textAlign:"center", margin:8}}>
<p style={{textAlign:"center", margin:12}}>
                        Send  {chosen.title+" "+chosen.lastname} a message via 
                    </p>
                    <Button  aria-label="fingerprint"   style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <WhatsAppIcon style={{fontSize:"20px"}} />}>
    <small> Whatsapp</small>
      </Button>
           <Button onClick={()=>setback("email")} aria-label="fingerprint"  style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <EmailIcon style={{fontSize:"20px"}} />}>
        <small> Email</small>
      </Button>
   <Button onClick={()=>setback("sms")} aria-label="fingerprint"  style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <MessageIcon style={{fontSize:"20px"}} />}>
        <small> Text Message</small>
      </Button>

           
</div>

            )
        }

        const viaSMS = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" id="messagesms" defaultValue={"Hello "+ chosen.lastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>
{messageloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendsms()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }
                </div>
                </div>


            )

        }
    
        const viaEmail = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
<Divider/>
<br/>
<br/>
                <textarea rows="1" id='subject' placeholder='Subject' style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
                <textarea rows="5" id='messageemail' defaultValue={"Hello "+ chosen.lastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>
{messageloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendemail()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }
                </div>
                </div>


            )

        }

     
    
    const messenger =(type)=>{
    if(type==="email"){
        return(
            viaEmail()
        )
    }
    if(type==="sms"){
        return(
            viaSMS()
        )
    }  if(type==="back"){
        return(
            sendmessage()
        )
    }
}

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

        var names=[]

        for(var i in Class_){
            names.push(Class_[i].classname)
        }

        const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

        const [personName, setPersonName] = React.useState([]);

        const handleChange_ = (event) => {
          const {
            target: { value },
          } = event;
          setPersonName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
        };
      


        const [chosensubjectstoteach0, setchosensubjectstoteach0] = React.useState([]);
        const [chosensubjectstoteach1, setchosensubjectstoteach1] = React.useState([]);
        const [chosensubjectstoteach2, setchosensubjectstoteach2] = React.useState([]);
        const [chosensubjectstoteach3, setchosensubjectstoteach3] = React.useState([]);
        const [chosensubjectstoteach4, setchosensubjectstoteach4] = React.useState([]);
        const [chosensubjectstoteach5, setchosensubjectstoteach5] = React.useState([]);
        const [chosensubjectstoteach6, setchosensubjectstoteach6] = React.useState([]);
        const [chosensubjectstoteach7, setchosensubjectstoteach7] = React.useState([]);
        const [chosensubjectstoteach8, setchosensubjectstoteach8] = React.useState([]);
        const [chosensubjectstoteach9, setchosensubjectstoteach9] = React.useState([]);
        const [chosensubjectstoteach10, setchosensubjectstoteach10] = React.useState([]);
const listing=[chosensubjectstoteach0,chosensubjectstoteach1,chosensubjectstoteach2,chosensubjectstoteach3,chosensubjectstoteach4,chosensubjectstoteach5,chosensubjectstoteach6,chosensubjectstoteach7,chosensubjectstoteach8,chosensubjectstoteach9,chosensubjectstoteach10]
const listingset=[setchosensubjectstoteach0,setchosensubjectstoteach1,setchosensubjectstoteach2,setchosensubjectstoteach3,setchosensubjectstoteach4,setchosensubjectstoteach5,setchosensubjectstoteach6,setchosensubjectstoteach7,setchosensubjectstoteach8,setchosensubjectstoteach9,setchosensubjectstoteach10]
        

const handlesubjectstoteach = (event, setting) => {
          const {
            target: { value },
          } = event;
          setting(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
        };
      



        
        if(activeworker===false && inactiveworker===false){

            setactiveworker(true)
            setgettingdatatype("Active")

        }

        const changestudenttype=(e, name)=>{
            console.log(e.target.checked)
            if(name==="Active"){
                setactiveworker(e.target.checked)
                setinactiveworker(false)
                setgettingdatatype("Active")
            }
             else if(name==="Inactive"){
                setactiveworker(false)
                setinactiveworker(e.target.checked)
                setgettingdatatype("Inactive")

            }   
           

            

        }
                
        
        const changebulksms=(e, name)=>{
          
                setbulkmessage(e.target.checked)

                console.log(e.target.checked)
                const doer = async () => {
                    await axios.post("https://servermain.sisrevolution.com/api/schools/restrictmessage/",
                        {
                           _id:schooldetails_[0].schoolID,
                           bulkmessage:e.target.checked,
        
                        }).then(res => {
        console.log(res)
                            if (res.status === 200) {
        
                              
                            dispatch(schooldetails({
        
                                data: res.data
        
                            }))
        
                              
        
                            } else {
                                setmessage("Restriction Error")
                                setstated("error")
                                handleClicksnack()
                                setbulkmessage(!(e.target.checked))
                            }
        
                        })
        
                }


                try{
   
                    doer()
                 }
                 catch(err){
                     setmessage("Network Error")
                     setstated("error")
                     setbulkmessage(!(e.target.checked))
                     handleClicksnack()
                 }


              
        }
           
      const changeaddstudent=(e, name)=>{
         
                setaddstudent(e.target.checked)
        console.log(e.target.checked)
                const doer = async () => {
                    await axios.post("https://servermain.sisrevolution.com/api/schools/addstudentrestriction/",
                        {
                            _id:schooldetails_[0].schoolID,
                            addstudent:e.target.checked
        
                        }).then(res => {


                            console.log(res)



                            if (res.status === 200) {
        
                              
                            dispatch(schooldetails({
        
                                data: res.data
        
                            }))
        
                              
        
                            } else {
                                setmessage("Restriction Error")
                                setstated("error")
                                handleClicksnack()
                                setaddstudent(!(e.target.checked))
                            }
        
                        })
        
                }


                try{
   
                    doer()
                 }
                 catch(err){
                    console.log(err)
                     setmessage("Network Error")
                     setstated("error")
                     setaddstudent(!(e.target.checked))
                     handleClicksnack()
                 }
        }
           

        const changestatus = (data) => {

            setdeleteworkerloading(true)
   
           const doer = async () => {
               await axios.post("https://servermain.sisrevolution.com/api/teachers/changestatus/",
                   {
                      _id:chosen._id,
                      status:status_selector,
                      statuschosen:gettingdatatype,
                      schoolID:admin.schoolID
   
                   }).then(res => {
   
                       if (res.status === 200) {
   
                           dispatch(getdatateacher({
   
                               data: res.data
       
                           }))
   
                           setdeleteworkerloading(!true)
                           handleClosestatus()
                           handleClicksnack()
                           setmessage("Staff status changed to "+status_selector)
                           setstated("success")
   
                       } else {
                        setdeleteworkerloading(!true)
                        handleClosestatus()
                           handleClicksnack()
                           setmessage("Error changing status")
                           setstated("error")
                       }
   
                   })
   
           }
           try{
   
               doer()
            }
            catch(err){
                setmessage("Network Error")
                setstated("error")
                setdeleteworkerloading(!true)
                handleClicksnack()
            }
   
       }
   
   
        const resetpassword = (data) => {

            setpasswordloading(true)
   
           const doer = async () => {
               await axios.post("https://servermain.sisrevolution.com/api/teachers/resetpassword/",
                   {
                      _id:chosen._id,
                      contact:chosen.contact,
                      email:chosen.email,
                      schoolID:admin.schoolID
   
                   }).then(res => {
   
                       if (res.status === 200) {
   
                           setpasswordloading(!true)
                           handleClosepassword()
                           handleClicksnack()
                           setmessage("Password reset successful")
                           setstated("success")
   
                       } else {
                        setpasswordloading(!true)
                        handleClosepassword()
                           handleClicksnack()
                           setmessage("Error reseting password")
                           setstated("error")
                       }
   
                   })
   
           }
           try{
   
               doer()
            }
            catch(err){
                setmessage("Network Error")
                setstated("error")
                setpasswordloading(!true)
                handleClicksnack()
            }
   
       }
   
   

       const status_changer=(e)=>{
        setstatus_selector(e.target.value)
    }

    const settrialmode_=()=>{
        settrialmode(false)
    }


    return (

        <Box sx={{ width: '100%' }}>



            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>



           
            <Dialog fullWidth maxWidth="sm" onClose={handleClosepassword} aria-labelledby="customized-dialog-title" open={openpassword}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosepassword} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Reset Password for {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleClosepassword} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
            <br/>
            <br/>
            <Grid container textAlign="center">
                <Grid xs={12}>
                {passwordloading ===true?
   
   <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginLeft:10}}
>
Loading...
</LoadingButton>
:

<Button  onClick={()=>resetpassword(chosen)} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Reset Password</Button>

}
</Grid>



</Grid>
<br/>
<br/>


            </Dialog>





            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Delete Staff
                    <IconButton onClick={handleClosedelete} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                   {deleteworkerloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => deleter(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }


                  
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleClosestatus} aria-labelledby="customized-dialog-title" open={openstatus}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosestatus} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Change Status
                    <IconButton onClick={handleClosestatus} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                  
                    
                <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={status_selector}
        onChange={status_changer}
        name="radio-buttons-group"
      >
        <FormControlLabel value="Active" control={<Radio />} label="Active" />
        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
      </RadioGroup>
    </FormControl>




                </DialogContent>
                <DialogActions>

                   {deleteworkerloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => changestatus(chosen)} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }


                  
                </DialogActions>
            </Dialog>


            <Dialog fullWidth width="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
               <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Staff Details
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{textAlign:"center", marginLeft:"40%"}}>
                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />
                    </div>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary={chosen.lastname+" "+chosen.othernames} secondary="Name" />
                        </ListItem> 
                        <Divider/>
                        <ListItem>
                            <ListItemText primary={chosen.positiondescription} secondary="Position description" />
                        </ListItem> 
                        <Divider />
                        {chosen.positiondescription==="Teacher" || chosen.positiondescription==="Subject Teacher"?
                        <ListItem>
                            <ListItemText primary={chosen.length!==0?chosen.class_[0].split(",").map((list, index)=><span>{list+", " } </span>):"None"} secondary="Class" />
                        </ListItem>:""
}
                        <ListItem>
                            <ListItemText primary={chosen.gender} secondary="Gender" />
                        </ListItem>
                           
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.length!==0 && months[parseInt((chosen.dob.split("-"))[1])-1] +" "+(chosen.dob.split("-"))[2]+", "+(chosen.dob.split("-"))[0] } secondary="Date of Birth" />
                        </ListItem>
                        <Divider />
                        
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Contact" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.email} secondary="Email" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.residence} secondary="Residence Address" />
                        </ListItem>
                     
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.religion} secondary="Religion" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.status} secondary="Status" />
                        </ListItem>
                        <Divider />



                    </List>

                    <br />

              
                </DialogContent>
                
            </Dialog>


            <Dialog fullWidth maxWidth="sm" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Messages
                    <IconButton onClick={handleClosesupply} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
               
                {messenger(what)}

              

                <DialogActions>
                 
                  
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>




                 

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>


            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/uploads/'+chosen.image} width="100%"/>

                </DialogContent>

            </Dialog>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                   <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    New Staff
                    <IconButton onClick={handleCloseadd} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimage"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimage">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>

                        <br />


      <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Tittle</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={tittle}
                                label="Tittle"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_tittle}
                            >
                                <MenuItem value="none">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Mr.">Mr.</MenuItem>
                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Rev.">Rev.</MenuItem>
                                


                            </Select>


                        </FormControl>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <br/>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                       

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dob"
                                label="Date of Birth"
                                type="date"
                                defaultValue="2017-05-24"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>
 <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                       
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>
 <br />
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >
                                <MenuItem value="none">
                                    <em>Select Religion</em>
                                </MenuItem>
                                <MenuItem value="Christian">Christian</MenuItem>
                                <MenuItem value="Muslim">Muslim</MenuItem>
                                <MenuItem value="Complicated">Complicated</MenuItem>

                            </Select>


                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="none">
                                    <em>Select Gender</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>
<br/>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Department</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={position}
                                label="Department"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_position}
                            >
                                <MenuItem value="none">
                                    <em>Select Departmant</em>
                                </MenuItem>
                            <MenuItem value="Teaching Staff">Teaching Staff</MenuItem>
                            <MenuItem value="Non-Teaching Staff">Non-Teaching Staff</MenuItem>



                            </Select>


                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={positiondescription}
                                label="Position Description"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_positiondescription}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Subject Teacher">Subject Teacher</MenuItem>
                                <MenuItem value="Class Teacher">Class Teacher</MenuItem>
                                <MenuItem value="Class and Subject Teacher">Class and Subject Teacher</MenuItem>
                                <MenuItem value="Accountant">Accountant</MenuItem>
                                <MenuItem value="IT Officer">IT Officer</MenuItem>
                                <MenuItem value="Administrator">Administrator</MenuItem>
                                <MenuItem value="Administrator and Teller">Administrator and Teller</MenuItem>
                                <MenuItem value="Cook">Cook</MenuItem>
                                <MenuItem value="Cleaner">Cleaner</MenuItem>
                                <MenuItem value="School Head">School Head</MenuItem>
                                <MenuItem value="Director">Director</MenuItem>
                           
                            </Select>
</FormControl>
<br/>
{ positiondescription==="Class Teacher" || positiondescription==="Class and Subject Teacher"?
                       
                        <FormControl style={{ margin: 10 }}>
                        
                            <InputLabel id="demo-simple-select-helper-label">Class Teacher To</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={positiondescription_}
                                label="Class Teacher To"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_positiondescription_}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {console.log(Class_)}
                                {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                ) :"" }
                            </Select>

                        </FormControl>
                        :""
}{ positiondescription==="Subject Teacher"|| positiondescription==="Class and Subject Teacher"?
                    
                       <FormControl style={{margin:10}}>
                         
                       <InputLabel id="demo-multiple-checkbox-label">Assigned Classes</InputLabel>
                       <Select
                         labelId="demo-multiple-checkbox-label"
                         id="demo-multiple-checkbox"
                         multiple
                         size="small"
                         className="signup"
                         value={personName}
                         onChange={handleChange_}
                         input={<OutlinedInput label="Assigned Classes" />}
                         renderValue={(selected) => selected.join(', ')}
                         MenuProps={MenuProps}
                       >
                       
                         {names.map((name) => (
                           <MenuItem key={name} value={name}>
                             <Checkbox checked={personName.indexOf(name) > -1} />
                             <ListItemText primary={name} />
                           </MenuItem>
                         ))}
                       </Select>
                     </FormControl>
                        :""
}
{personName!=="" && personName.length!==0 && personName.map((list,index)=>


<Grid container align="center" style={{marginTop:"15px"}}>

<Grid xs={6}>
<input type="text" disabled id={'class'+list} value={list} style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"90%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }} /> 
</Grid>

<Grid xs={6}>
{/* <select  id={'subject'+list} style={{border:"solid", color:"gray", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"90%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }}  multiple>
    <option value="Class Teacher">Choose Subject</option>
    <option value="Class Teacher">Class Teacher</option> */}

    <FormControl >
                       <InputLabel id="demo-multiple-checkbox-label">Choose Classes</InputLabel>
                       <Select
                         labelId="demo-multiple-checkbox-label"
                         id="demo-multiple-checkbox"
                         multiple
                         size="small"
                         className="signup"
                         value={listing[index]}
                         onChange={(e)=>handlesubjectstoteach(e,listingset[index])}
                         input={<OutlinedInput label="Choose Classes" />}
                         renderValue={(selected) => selected.join(', ')}
                         MenuProps={MenuProps}
                       >
                            {Class_.filter(bug=>bug.classname===list).length!==0 && Class_.filter(bug=>bug.classname===list)[0].subjects[0].map((name,indexer)=>(

                           <MenuItem key={name.subject} value={name.subject}>
                             <Checkbox checked={listing[index].indexOf(name.subject) > -1} />
                             <ListItemText primary={name.subject} />
                           </MenuItem>

                         ))}
                       </Select>
                     </FormControl>
{/* 
    {Class_.filter(bug=>bug.classname===list).length!==0 && Class_.filter(bug=>bug.classname===list)[0].subjects[0].map((lister,indexer)=>
    <option key={indexer} value={lister.subject}>{lister.subject}</option>
    
    
    )} */}
    
    
  
{/* </select> */}
</Grid>



</Grid>



)}

<br/>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"

                            />
                        </FormControl>
 
                        <br />

  
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="snnit"
   placeholder="SNNIT"
   type="text"
   className="signup"
   variant="outlined"
   label="SNNIT"
   size="small"



/>
</FormControl>  
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="bank"
   placeholder="Bank Name"
   type="text"
   className="signup"
   variant="outlined"
   label="Bank Name"
   size="small"



/>
</FormControl>

<br/>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="accountnumber"
   placeholder="Bank Account Number"
   type="text"
   className="signup"
   variant="outlined"
   label="Bank Account Number"
   size="small"



/>
</FormControl>

                        <FormControl style={{ marginLeft: 10 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
                            <RadioGroup
                                id="maritalstatus"
                                row
                                value={maritalstatusmain}
                                onChange={(e)=>changemaritalstatus(e)}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <div style={{ textAlign: "center" }}>

                           
                            
                            


                        </div>
                    </DialogContent>

                    <DialogActions>

                    {newworkerloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => addstaff()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }
                       
                    </DialogActions>
                </Dialog>
            </div>


            <div>

                <Dialog  maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                     <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Edit Staff
                    <IconButton onClick={handleCloseedit} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimageedit"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimageedit">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>

                        <br />


      <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Tittle</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={tittleedit ===""?chosen.title:tittleedit}
                                label="Position"
                                className="signup"
                                variant="outlined"
                                size="small"
                                

                                onChange={handleChange_tittleedit}
                            >
                                <MenuItem value="none">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Mr.">Mr.</MenuItem>
                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Rev.">Rev.</MenuItem>
                                


                            </Select>


                        </FormControl>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastnameedit"
                                placeholder="Surname"
                                type="text"
                                defaultValue={chosen.lastname}
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"

                            />
                        </FormControl>
                        <br/>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.othernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"

                            />
                        </FormControl>
                       

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dobedit"
                                label="Date of Birth"
                                type="date"
                                defaultValue={chosen.dob}
                               
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>
 <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residenceedit"
                                placeholder="Residence Address"
                                type="address"
                                defaultValue={chosen.residence}
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                       
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometownedit"
                                placeholder="Hometown"
                                type="text"
                                defaultValue={chosen.hometown}
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>
 <br />
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligionedit === ""? chosen.religion : selectedreligionedit}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religionedit}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Christian">Christian</MenuItem>
                                <MenuItem value="Muslim">Muslim</MenuItem>

                            </Select>


                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgenderedit ===""?chosen.gender:selectedgenderedit}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_genderedit}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

 <br />
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={positionedit ===""?chosen.position:positionedit}
                                label="Position"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_positionedit}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Teaching Staff">Teaching Staff</MenuItem>
                                <MenuItem value="Non-Teaching Staff">Non-Teaching Staff</MenuItem>
                              
                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={positiondescriptionedit ===""? chosen.positiondescription:positiondescriptionedit}
                                label="Position Description"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_positiondescriptionedit}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Subject Teacher">Subject Teacher</MenuItem>
                                <MenuItem value="Class Teacher">Class Teacher</MenuItem>
                                <MenuItem value="Class and Subject Teacher">Class and Subject Teacher</MenuItem>
                                <MenuItem value="Accountant">Accountant</MenuItem>
                                <MenuItem value="IT Officer">IT Officer</MenuItem>
                                <MenuItem value="Administrator">Administrator</MenuItem>
                                <MenuItem value="Administrator and Teller">Administrator and Teller</MenuItem>
                                <MenuItem value="Cook">Cook</MenuItem>
                                <MenuItem value="Cleaner">Cleaner</MenuItem>
                                <MenuItem value="School Head">School Head</MenuItem>
                           
                            </Select>
</FormControl>
<br/>
{ positiondescriptionedit==="Class Teacher" || positiondescriptionedit==="Class and Subject Teacher"?
<>
                       <br/>
                        <FormControl style={{ margin:10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class Teacher To</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={positiondescriptionedit_}
                                label="Class Teacher To"
                                className="signup"
                                variant="outlined"
                                size="small"
                                onChange={handleChange_positiondescriptionedit_}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {console.log(Class_)}
                                {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                ) :"" }
                            </Select>
                        </FormControl>
                        </>
                        :""}
{positiondescriptionedit==="Subject Teacher" || positiondescriptionedit==="Class and Subject Teacher"?
                       <>
                       
                      
                       <FormControl style={{margin:10}}>
                       <InputLabel id="demo-multiple-checkbox-label">Choose Classes</InputLabel>
                       <Select
                         labelId="demo-multiple-checkbox-label"
                         id="demo-multiple-checkbox"
                         multiple
                         size="small"
                         className="signup"
                         value={personName}
                         onChange={handleChange_}
                         input={<OutlinedInput label="Choose Classes" />}
                         renderValue={(selected) => selected.join(', ')}
                         MenuProps={MenuProps}
                       >
                         {names.map((name) => (
                           <MenuItem key={name} value={name}>
                             <Checkbox checked={personName.indexOf(name) > -1} />
                             <ListItemText primary={name} />
                           </MenuItem>
                         ))}
                       </Select>
                     </FormControl>
                     </>
                        :""
}

{  personName!==undefined && personName!=="" && personName.length!==0 && personName.map((list,index)=>

<Grid container align="center" style={{marginTop:"10px"}}>

<Grid xs={6}>
<input type="text" disabled id={'classedit'+list} value={list} style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"90%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }} /> 
</Grid>

<Grid xs={6}>
   

<FormControl >
                       <InputLabel id="demo-multiple-checkbox-label">Choose Classes</InputLabel>
                       <Select
                         labelId="demo-multiple-checkbox-label"
                         id="demo-multiple-checkbox"
                         multiple
                         size="small"
                         className="signup"
                         value={listing[index]}
                         onChange={(e)=>handlesubjectstoteach(e,listingset[index])}
                         input={<OutlinedInput label="Choose Classes" />}
                         renderValue={(selected) => selected.join(', ')}
                         MenuProps={MenuProps}
                       >
                            {Class_.filter(bug=>bug.classname===list).length!==0 && Class_.filter(bug=>bug.classname===list)[0].subjects[0].map((name,indexer)=>(

                           <MenuItem key={name.subject} value={name.subject}>
                             <Checkbox checked={listing[index].indexOf(name.subject) > -1} />
                             <ListItemText primary={name.subject} />
                           </MenuItem>

                         ))}
                       </Select>
                     </FormControl>


{/* 
<select  id={'subjectedit'+list} onChange={(e)=>changer_(e,list,chosen)} style={{border:"solid", color:"gray", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"90%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }}>
    <option selected={chosen.subjectstoteach!==undefined && chosen.subjectstoteach.filter(bug=>bug.subject===list.subject)} value="Class Teacher">Class Teacher</option>
    
    {Class_.filter(bug=>bug.classname===list)[0].subjects[0].map((lister,indexer)=>
    <>
  
    <option key={indexer} selected={chosen.subjectstoteach!==undefined && chosen.subjectstoteach.filter(bug=>bug.subject===lister.subject).length!==0 && chosen.subjectstoteach.filter(bug=>bug.subject===lister.subject)[0].subject} value={lister.subject}>{lister.subject}</option>
    </>
    )}
    
    
</select> */}




</Grid>



</Grid>


)}
<br/>
                       

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contactedit"
                                placeholder="Contact"
                                type="tel"
                                defaultValue={chosen.contact}
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="emailedit"
                                placeholder="Email"
                                type="email"
                                defaultValue={chosen.email}
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"

                            />
                        </FormControl>

                        <br />

  
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="snnitedit"
   placeholder="SNNIT"
   type="text"
   className="signup"
   variant="outlined"
   label="SNNIT"
   size="small"



/>
</FormControl>  
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="bankedit"
   placeholder="Bank Name"
   type="text"
   className="signup"
   variant="outlined"
   label="Bank Name"
   size="small"



/>
</FormControl>

<br/>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
   id="accountnumberedit"
   placeholder="Bank Account Number"
   type="text"
   className="signup"
   variant="outlined"
   label="Bank Account Number"
   size="small"



/>
</FormControl>

                        <FormControl style={{ marginLeft: 10 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
                            <RadioGroup
                                id="maritalstatus"
                                row
                                value={maritalstatusedit === ""? chosen.maritalstatus:maritalstatusedit}
                                onChange={changemaritalstatusedit}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <div style={{ textAlign: "center" }}>

                         
                        </div>
                    </DialogContent>


                    <DialogActions align="center">
                    {editworkerloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => editstaff()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                  Update
                                </Button>
                            }
                       
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />

                <Grid container>
                    <Grid lg={1} xs={2} md={6}>
                        <Tooltip title="Add Staff">
                            <Button
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                variant="outlined"
                                color="primary"
                                style={{ marginTop: 10.5 }}
                                onClick={handleClickOpenadd}

                            >
                                <PersonAddAlt1Icon fontSize="medium" />
                            </Button>
                        </Tooltip>
                    </Grid>
                 
                    <Grid lg={2} xs={2} md={6} style={{ textAlign: "left" }}>
                        <FormControl style={{ marginRight: 2, minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <Select
                                id="demo-simple-select-autowidth"
                                value={sorter}
                                onChange={sorting}
                                autoWidth
                                style={{ height: "38px", width: "100%", padding: 0, color: "grey", borderColor: "#2196f3", outline: "none" }}
                            >
                                  <Container>
                               <p align="center" style={{margin:5}} ><b>Staff Type</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={activeworker} onChange={(e)=>changestudenttype(e,"Active")} />} label="Active" />
                                    <FormControlLabel control={<Switch checked={inactiveworker} onChange={(e)=>changestudenttype(e,"Inactive")}/>} label="Inactive" />
                                    </FormGroup>
                                 
                                    <p align="center" style={{margin:5}} ><b>Sort Staff</b></p>
                                    <Divider/>
                               </Container>


                                <MenuItem value="none">Sort Staff</MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Teaching Staff">Teaching Staff</MenuItem>
                                <MenuItem value="Non-Teaching Staff">Non-Teaching Staff</MenuItem>


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid lg={1} xs={2} md={6}>
                    <FormControl style={{ marginLeft: "-70px", minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <Select
                                id="demo-simple-select-autowidth"
                                value={sorter}
                                onChange={sorting}
                                autoWidth
                                style={{ height: "38px", width: "100%", padding: 0, color: "grey", borderColor: "#2196f3", outline: "none" }}
                            >
                                  <Container>
                               <p align="center" style={{margin:5}} ><b>Restrict Staff On App</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={bulkmessage} onChange={(e)=>changebulksms(e,"Active")} />} label="Bulk Message" />
                                    <FormControlLabel control={<Switch checked={addstudent} onChange={(e)=>changeaddstudent(e,"Active")}/>} label="Add Student" />
                                    </FormGroup>
                                 
                                    {/* <p align="center" style={{margin:5}} ><b>Restrict Staff</b></p> */}
                                    <Divider/>
                               </Container>


                                <MenuItem value="none">Restrict Staff</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid lg={3} xs={10} md={6}>
                        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color: "grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "100%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.id}</TableCell>

                                            <TableCell component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="left"
                                            >

                                                <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+row.image} size="small" alt={row.lastname +" "+ row.othernames} onClick={() => handleClickOpenimage(row)} style={{ cursor: "pointer" }}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={row.lastname +" "+ row.othernames} />
                                                </ListItem>

                                            </TableCell>
                                            <TableCell align="left">{row.position}</TableCell>
                                            <TableCell align="left">{row.status==="Active" ? <span style={{padding:8, borderRadius:"20px", backgroundColor:"#13A518", color:"white"}}>{row.status}</span>:<span style={{padding:8, borderRadius:"20px", backgroundColor:"red", color:"white"}}>{row.status}</span>}</TableCell>
                                            <TableCell align="left">{row.contact}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.residence}</TableCell>

                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} password={(sender) => handleClickOpenpassword(sender)} message={(sender) => handleClickOpensupply(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)} status={(sender) => handleClickOpenstatus(sender)} />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>


    );
}
