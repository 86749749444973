import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ReactToPrint from 'react-to-print';
import {Offline, Online} from "react-detect-offline";
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import Networkerror from "./images/network.svg"
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import {visuallyHidden} from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';
import Tablereport from './tablereport';
import Tablefees from './feesinformation';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import Attendance from './date';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import TextField from '@mui/material/TextField';
import Menustyle from './menuschoolfees';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';
import { getofflinedata } from './redux/offline';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';
import { JsonToExcel } from 'react-json-to-excel';

import Stack from '@mui/material/Stack';
import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { user } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateschooldata, getdata } from './redux/schools';
import { getdata_, updateclassdata } from './redux/class';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, BrowserRouter } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function createData(_id,id,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothername, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothername, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academic ,finance, attendance, Awards, behaviour, others, charges, specialID, nextclass, housing) 
{
    return {
        _id,
        id,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothername,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothername,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academic,
        finance,
        attendance,
        Awards,
        behaviour,
        others,
        charges,
        specialID,
        nextclass,
        housing
        
    };
}




function createData2(_id,ID,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothername, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothernames, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academics ,finance, attendance, awards, behaviour, others, charges, specialID, nextclass, housing) 
{
    return {
        _id,
        ID,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothername,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothernames,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academics,
        finance,
        attendance,
        awards,
        behaviour,
        others,
        charges,
        specialID,
        nextclass,
        housing
        
    };
}







const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  
  
    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },
  
    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    }, {
        id: 'status',
        numeric: !true,
        disablePadding: false,
        label: 'Status',
    },
   
    {
        id: 'class',
        numeric: !true,
        disablePadding: false,
        label: 'Class',
    },
    {
        id: 'fees',
        numeric: !true,
        disablePadding: false,
        label: 'Total Fees Due',
    }, {
        id: 'fees',
        numeric: !true,
        disablePadding: false,
        label: 'Amount paid',
    }, {
        id: 'fees',
        numeric: !true,
        disablePadding: false,
        label: 'Outstanding',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            SCHOOL FEES
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const [value, setValue] = React.useState(window.navigator.onLine)

    


    const [exportdata, setexportdata] = React.useState([]);
    var exporter=[]
    const [opensnack, setOpensnack] = React.useState(false);
    const [preload, setpreload] = React.useState(true);
    const [activestudents, setactivestudents] = React.useState(true);
    const [withdrawnstudents, setwithdrawnstudents] = React.useState(false);
    const [completestudents, setcompletestudents] = React.useState(false);
    const [debtors, setdebtors] = React.useState(false);
    const [complete, setcomplete] = React.useState(false);
    const [feereceipt, setfeereceipt] = React.useState([]);
    const [feereceipt2, setfeereceipt2] = React.useState([]);
    const [gettingdatatype, setgettingdatatype] = React.useState("Active");
    const componentRef = useRef()
    const componentRefunsent = useRef()
    const componentRefreceipt = useRef()
    
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };



    const dispatch = useDispatch()
    const schools = useSelector((state) => state.students)
    const Offlinedata = useSelector((state) => state.offline)
    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    const Class_ = useSelector((state) => state.Class_)


      const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }


    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                _id:admin._id,
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }



      const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID



            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }


 React.useEffect(()=>{
    schooldata()
    gettingdata()
    gettingdata_()
 },[preload, gettingdatatype])

    var data = [schools.map((list, index) => createData(list._id,list.ID, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothername, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academics, list.finance, list.attendance, list.Awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))]
    var data11 = data
    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    var rows = data[0]
  
    let online = navigator.onLine
   
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [opendebtors, setopendebtors] = React.useState(!true);
    const [openunsent, setopenunsent] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [unsent, setunsent] = React.useState([]);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const [payer, setpayer] = React.useState("Mother");
    const [trialmode, settrialmode] = React.useState(!true);

    const [onlinecheck, setonlinecheck] = React.useState(true);
    const [syncoffline, setsyncoffline] = React.useState([]);
    const [smsloading, setsmsloading] = React.useState(!true);
    const [billloading, setbillloading] = React.useState(!true);
    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openviewbill, setOpenviewbill] = React.useState(!true);
    const [openattendance, setOpenattendance] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openoffline, setOpenoffline] = React.useState(!true);
    const [openmessage, setOpenmessage] = React.useState(!true);
    const [openfees, setOpenfees] = React.useState(!true);
    const [openreceipt, setOpenreceipt] = React.useState(!true);
    const [openreceipt2, setOpenreceipt2] = React.useState(!true);
    const [openmessagearrears, setOpenmessagearrears] = React.useState(!true);
    const [openmessagediscount, setOpenmessagediscount] = React.useState(!true);
    const [opencharge, setOpencharge] = React.useState(!true);
    const [opendailyfee, setOpendailyfee] = React.useState(!true);
    const [openweeklyfee, setOpenweeklyfee] = React.useState(!true);
    const [openmonthlyfee, setOpenmonthlyfee] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [guardian, setGuardian] = React.useState('Mother');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("none")
    const [selectedgender, setselectedgender] = React.useState("none")
    const [selectedreligion, setselectedreligion] = React.useState("none")
    const [person, setperson] = React.useState(Person)
    const [what, setwhat] = React.useState("back")
    const [selectedterm, setselectedterm] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [selectedcharge, setselectedcharge] = React.useState("none")
    const [selectedcharge_, setselectedcharge_] = React.useState("none")


    const searching = (e) => {
        setsearch(e.target.value)
    }
    var bill = "" 

   if (search !== "") {
        console.log(search)
        var listed = rows.filter(bug => bug.class_.toLowerCase().includes(search.toLowerCase()) || bug.status.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.id.toLowerCase().includes(search.toLowerCase())|| (bug.specialID !==undefined && bug.specialID.toLowerCase().includes(search.toLowerCase())) )
       rows=listed
    }

 

    if(debtors === true){
        var list  = rows.filter(bug => bug.finance.length!==0? bug.finance[bug.finance.length-1].balance !==0 : bug.finance.length !==0 || bug.finance.length!==0? bug.finance[bug.finance.length-1].Arrears !==0 : bug.finance.length ===0 )
        rows=list
        // setexportdata([])
        exporter=[]
    }

    if(complete === true){
        var list  = rows.filter(bug => bug.finance.length !==0? bug.finance[bug.finance.length-1].balance === 0 : bug.finance.length !==0 || bug.finance.length !==0? bug.finance[bug.finance.length-1].Arrears ===0 : bug.finance.length !==0 )
        rows=list
        // setexportdata([])
        exporter=[]
    }


    if(sorter !== "none" && sorter !== "All"){
               
           var list  = rows.filter(bug => bug.class_===sorter )
           rows=list
           exporter=[]
     
    }


    const mainterm = schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none"




    const saveoffline = ()=>{

        handleCloseoffline()
        var main = [...Offlinedata,{type:syncoffline.type,date:syncoffline.date,id:syncoffline.id,link:syncoffline.link,data:syncoffline.data}]
        dispatch(getdata({

            data: syncoffline.completedata

        }))
        localStorage.setItem("offline", JSON.stringify(main))

        dispatch(getofflinedata({

            data: main

        }))
        
setfeereceipt(syncoffline.data.data[syncoffline.data.data.length-1])

        handleClosemessage()
        handleClicksnack()
        setOpenreceipt(true)
        handleClosemessage()
        handleClicksnack()
        setmessage("GHC " +syncoffline.data.amountpaid+" paid offline")
        setstated("success")
        
    }

    const payfees = () =>{
      
        setloading(true)
        var paidby = ""
        var payercontact = ""
        var otherpayercontact = ""
        var balancer=0
        var receiver = admin.lastname!==undefined? (admin.lastname).toUpperCase() +" "+admin.othernames && (admin.othernames).toUpperCase():"Main Administrator"
    
if(payer==="Others"){
    paidby = document.getElementById("paidby").value
    payercontact = chosen.guardiancontact
    otherpayercontact = document.getElementById("payercontact").value

}else if (payer==="Mother"){
payercontact=chosen.mothercontact===""?chosen.fathercontact:chosen.mothercontact
paidby = chosen.motherlastname===""?"Mother":chosen.motherlastname
}else if (payer==="Father"){
payercontact=chosen.fathercontact===""?chosen.mothercontact:chosen.fathercontact
paidby = chosen.fatherlastname===""?"Father":chosen.fatherlastname
}

        var amount = parseFloat(document.getElementById("amountpaid").value)
        var Arrears = 0
        var fees = chosen.finance
        var totalfees = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)
        var charge = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges)
        var main = []
        var othercharges = 0

        if (charge.length!==0){
            for(var i in charge[0]){
                othercharges+=parseFloat(charge[0][i].amount)

            }
        }

        if(fees.length===0){
            balancer=totalfees - amount
            var data = [{term:selectedterm, othercharges:othercharges, balance: balancer , Arrears:0, totalfees:totalfees, breakdown:{}, payment:[{date:date, receiver:receiver, time:time,des:selectedcharge, paidby:paidby, amountpaid:amount, balance: balancer }]}]
            main = data
            setfeereceipt(data[0])

        }else{
            var selected = fees.filter(bug=>bug.term === selectedterm)
            
            if(selected.length===0){
                balancer = (totalfees+(fees[fees.length-1].balance))-amount
                var d = {term:selectedterm, othercharges:othercharges, balance: balancer, Arrears:0, totalfees:totalfees, breakdown:{}, payment:[{date:date, time:time, receiver:receiver,des:selectedcharge, paidby:paidby, amountpaid:amount, balance:balancer}]}
                setfeereceipt(d)
                main=[...fees, d]
            }else{
                var unselected = fees.filter(bug=>bug.term !== selectedterm)
                var selected = fees.filter(bug=>bug.term === selectedterm)
                balancer = selected[0].balance - amount
                var d = {term:selectedterm,  othercharges:selected[0].othercharges,  balance: balancer, Arrears:0, totalfees:totalfees, breakdown:{}, payment:[...selected[0].payment,{date:date, receiver:receiver, time:time,des:selectedcharge, paidby:paidby, amountpaid:amount, balance:selected[0].balance - amount}]}
                setfeereceipt(d)
                main=[...unselected, d]
            }

        }


          const doer = async () => {
           
            await axios.post("https://servermain.sisrevolution.com/api/students/payfees/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    descriptioner:selectedcharge,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                    
                      var data= [...data11[0]]
                      var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                      var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
                    
                      mainstudent.finance=main
                      data[studentindex]=mainstudent

                      var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))


                      dispatch(getdata({

                        data: data22

                    }))

                        setloading(!true)
                        setOpenreceipt(true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("GHC " +amount+" paid ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Payment error")
                        setstated("error")

                    }

                })

     

        }
       
       
       if(navigator.onLine===true){ 
        try{
        doer()
        }catch(err){

           

            var data= [...data11[0]]
            var studentindex = data.findIndex(bug=>bug._id===chosen._id)
            var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
          
            mainstudent.finance=main
            data[studentindex]=mainstudent

            var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

            setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/payfees/", completedata:data22,data:{id: chosen._id,
                ID: chosen.id,
                lastname: chosen.lastname,
                othername: chosen.othernames,
                amountpaid: amount,
                descriptioner:selectedcharge,
                balance: balancer,
                guardiancontact: payercontact,
                othercontact: otherpayercontact,
                guardianlastname: paidby,
                data: main,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                schoolID:admin.schoolID}})
          

                setOpenoffline(true)

        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

        }
       }else{

        
        var data= [...data11[0]]
        var studentindex = data.findIndex(bug=>bug._id===chosen._id)
        var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
      
        mainstudent.finance=main
        data[studentindex]=mainstudent

        var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

        setsyncoffline({type:"payfees", id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/payfees/", completedata:data22,data:{id: chosen._id,
            ID: chosen.id,
            lastname: chosen.lastname,
            othername: chosen.othernames,
            amountpaid: amount,
            descriptioner:selectedcharge,
            balance: balancer,
            guardiancontact: payercontact,
            othercontact: otherpayercontact,
            guardianlastname: paidby,
            data: main,
            smsname:schooldetails_[0].smsname,
            smsID:schooldetails_[0].smsID,
            schoolID:admin.schoolID}})
      

        setOpenoffline(true)
        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

       }
  
       
    }


    const payfeesedit = () =>{
        setloading(true)
        var paidby = admin.lastname !== undefined && admin.othernames !== undefined? (admin.lastname).toUpperCase() +" "+ (admin.othernames).toUpperCase():"Main Administrator"
        var payercontact = ""
        var otherpayercontact = ""
        var balancer=0
        var receiver = admin.lastname!==undefined? (admin.lastname).toUpperCase() +" "+admin.othernames && (admin.othernames).toUpperCase():"Main Administrator"
    

        var amount = parseFloat(document.getElementById("amountpaidedit").value)
        var Arrears = 0
        var des = document.getElementById("editdescription").value
        setselectedcharge(des)
        var fees = chosen.finance
        var totalfees = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)
        var charge = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges)
        var main = []
        var othercharges = 0

        if (charge.length!==0){
            for(var i in charge[0]){
                othercharges+=parseFloat(charge[0][i].amount)

            }
        }

        if(fees.length===0){
            balancer=othercharges+totalfees+Arrears - amount
            var data = [{term:selectedterm, othercharges:othercharges, balance: balancer , Arrears:0, totalfees:totalfees, breakdown:{}, payment:[{date:date, time:time,des:des, receiver:receiver, paidby:paidby, amountpaid:amount, balance: totalfees - amount }]}]
            main = data
        }else{
            var selected = fees.filter(bug=>bug.term === selectedterm)
            
            if(selected.length===0){
                balancer = (fees[fees.length-1].balance)-amount
                var d = {term:selectedterm, othercharges:othercharges, balance: balancer, Arrears:fees[fees.length-1].Arrears, totalfees:totalfees, breakdown:{}, payment:[{date:date,  receiver:receiver, time:time, des:des, paidby:paidby, amountpaid:amount, balance:totalfees - amount}]}
                main=[...fees, d]
            }else{
                var unselected = fees.filter(bug=>bug.term !== selectedterm)
                var selected = fees.filter(bug=>bug.term === selectedterm)
                balancer = selected[0].balance - amount
                var d = {term:selectedterm,  othercharges:selected[0].othercharges,  balance: balancer, Arrears:fees[fees.length-1].Arrears, totalfees:totalfees, breakdown:{}, payment:[...selected[0].payment,{date:date, time:time,des:des, paidby:paidby,  receiver:receiver, amountpaid:amount, balance:selected[0].balance - amount}]}
                main=[...unselected, d]
            }

        }

          const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/payfees/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    descriptioner:des,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                    //   gettingdata()
                      var data= [...data11[0]]
                      var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                      var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
                    
                      mainstudent.finance=main
                      data[studentindex]=mainstudent

                      var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))


                      dispatch(getdata({

                        data: data22

                    }))
                        setloading(!true)
                        handleClosefees()
                        handleClicksnack()
                        setmessage("GHC " +amount+" paid ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosefees()
                        handleClicksnack()
                        setmessage("Payment error")
                        setstated("error")

                    }

                })

        }

         
       if(navigator.onLine===true){ 
        try{
        doer()
        }catch(err){

           

            var data= [...data11[0]]
            var studentindex = data.findIndex(bug=>bug._id===chosen._id)
            var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
          
            mainstudent.finance=main
            data[studentindex]=mainstudent

            var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

            setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/payfees/", completedata:data22,data:{id: chosen._id,
                ID: chosen.id,
                lastname: chosen.lastname,
                othername: chosen.othernames,
                amountpaid: amount,
                descriptioner:selectedcharge,
                balance: balancer,
                guardiancontact: payercontact,
                othercontact: otherpayercontact,
                guardianlastname: paidby,
                data: main,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                schoolID:admin.schoolID}})
          

                setOpenoffline(true)

        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

        }
       }else{

        
        var data= [...data11[0]]
        var studentindex = data.findIndex(bug=>bug._id===chosen._id)
        var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
      
        mainstudent.finance=main
        data[studentindex]=mainstudent

        var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

        setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/payfees/", completedata:data22,data:{id: chosen._id,
            ID: chosen.id,
            lastname: chosen.lastname,
            othername: chosen.othernames,
            amountpaid: amount,
            descriptioner:selectedcharge,
            balance: balancer,
            guardiancontact: payercontact,
            othercontact: otherpayercontact,
            guardianlastname: paidby,
            data: main,
            smsname:schooldetails_[0].smsname,
            smsID:schooldetails_[0].smsID,
            schoolID:admin.schoolID}})
      

        setOpenoffline(true)
        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

       }

    }


  
    const discount= () =>{
        setloading(true)
        var paidby = chosen.guardianlastname
        var payercontact = chosen.guardiancontact
        var otherpayercontact = chosen.guardiancontact
        var balancer=0

        var amount = parseFloat(document.getElementById("amountdiscount").value)
        var Arrears = 0
        var fees = chosen.finance
        var totalfees = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)
        var charge = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges)
        var main = []
        var othercharges = 0

        if (charge.length!==0){
            for(var i in charge[0]){
                othercharges+=parseFloat(charge[0][i].amount)

            }
        }

        if(fees.length===0){
            balancer=totalfees - amount
            var data = [{term:selectedterm, othercharges:othercharges, balance: balancer , Arrears:0, totalfees:totalfees, breakdown:{}, payment:[{date:date, time:time,des:"Discount", paidby:paidby, amountpaid:amount, balance: totalfees - amount }]}]
            main = data
        }else{
            var selected = fees.filter(bug=>bug.term === selectedterm)
            
            if(selected.length===0){
                balancer = (totalfees+(fees[fees.length-1].balance))-amount
                var d = {term:selectedterm, othercharges:othercharges, balance: balancer, Arrears:fees[fees.length-1].Arrears, totalfees:totalfees, breakdown:{}, payment:[{date:date, time:time, des:"Discount", paidby:paidby, amountpaid:amount, balance:balancer}]}
                main=[...fees, d]
            }else{
                var unselected = fees.filter(bug=>bug.term !== selectedterm)
                var selected = fees.filter(bug=>bug.term === selectedterm)
                balancer = selected[0].balance - amount
                var d = {term:selectedterm,  othercharges:selected[0].othercharges,  balance: balancer, Arrears:fees[fees.length-1].Arrears, totalfees:totalfees, breakdown:{}, payment:[...selected[0].payment,{date:date, time:time,des:"Discount", paidby:paidby, amountpaid:amount, balance:balancer}]}
                main=[...unselected, d]
            }

        }

          const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/discount/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                    //   gettingdata()
                 
                    
                  
                    var data= [...data11[0]]
                    
                    var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                    var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
                  
                    mainstudent.finance=main
                    data[studentindex]=mainstudent

                   console.log(data)
                   var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))
                   console.log(data22)
                    dispatch(getdata({

                      data: data22

                  }))
                        setloading(!true)
                        handleClosemessagediscount()
                        handleClicksnack()
                        setmessage("GHC " +amount+" has been discounted ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessagediscount()
                        handleClicksnack()
                        setmessage("Discount error")
                        setstated("error")

                    }

                })

        }

        // try{
        // doer()
        // }catch(err){
        //                 setloading(!true)
        //                 handleClicksnack()
        //                 setmessage("Network Error")
        //                 setstated("error")
        // }



          
       if(navigator.onLine===true){ 
        try{
        doer()
        }catch(err){

           

            var data= [...data11[0]]
            var studentindex = data.findIndex(bug=>bug._id===chosen._id)
            var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
          
            mainstudent.finance=main
            data[studentindex]=mainstudent

            var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

            setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/discount/", completedata:data22,
            data:{
                id: chosen._id,
                ID: chosen.id,
                lastname: chosen.lastname,
                othername: chosen.othernames,
                amountpaid: amount,
                balance: balancer,
                guardiancontact: payercontact,
                othercontact: otherpayercontact,
                guardianlastname: paidby,
                data: main,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                schoolID:admin.schoolID
            }})
          

                setOpenoffline(true)

        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

        }
       }else{

        
        var data= [...data11[0]]
        var studentindex = data.findIndex(bug=>bug._id===chosen._id)
        var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
      
        mainstudent.finance=main
        data[studentindex]=mainstudent

        var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

        setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/payfees/", completedata:data22,
        data:{
            id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID}})
      

        setOpenoffline(true)
        setloading(!true)
        handleClicksnack()
        setmessage("No Internet connection found")
        setstated("error")

       }



    }


  
    const adddebt = () =>{
        setloading(true)
        var paidby = chosen.guardianlastname
        var payercontact = chosen.guardiancontact
        var otherpayercontact = chosen.guardiancontact
        var balancer=0


        var amount = parseFloat(document.getElementById("amountindebt").value)
        var Arrears = amount
        var fees = chosen.finance
        var totalfees = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)
        var charge = parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges)
        var main = []
        var othercharges = 0

      

        if(fees.length===0){
            balancer=totalfees + amount
            var data = [{term:selectedterm, othercharges:othercharges, balance: balancer , Arrears:0, totalfees:totalfees, breakdown:{}, payment:[]}]
            main = data
        }else{
            var selected = fees.filter(bug=>bug.term === selectedterm)
            
            if(selected.length===0){
                balancer = (totalfees+(fees[fees.length-1].balance))+amount
                var d = {term:selectedterm, othercharges:othercharges, balance: balancer, Arrears:0, totalfees:totalfees, breakdown:{}, payment:[]}
                main=[...fees, d]
            }else{
                var unselected = fees.filter(bug=>bug.term !== selectedterm)
                var selected = fees.filter(bug=>bug.term === selectedterm)
                balancer = selected[0].balance + amount
                var d = {term:selectedterm,  othercharges:selected[0].othercharges,  balance: balancer, Arrears:0, totalfees:totalfees, breakdown:{}, payment:[...selected[0].payment]}
                main=[...unselected, d]
            }

        }

          const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/adddebt/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                    //   gettingdata()
                      
                      var data= [...data11[0]]
                      var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                      var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
                    
                      mainstudent.finance=main
                      data[studentindex]=mainstudent

                      var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))


                      dispatch(getdata({

                        data: data22

                    }))
                        setloading(!true)
                        handleClosemessagearrears()
                        handleClicksnack()
                        setmessage("GHC " +amount+" added to debt ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessagearrears()
                        handleClicksnack()
                        setmessage("Error Adding debt")
                        setstated("error")

                    }

                })

        }

        // try{
        // doer()
        // }catch(err){
        //                 setloading(!true)
        //                 handleClicksnack()
        //                 setmessage("Network Error")
        //                 setstated("error")
        // }



        if(navigator.onLine===true){ 
            try{
            doer()
            }catch(err){
    
               
    
                var data= [...data11[0]]
                var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
              
                mainstudent.finance=main
                data[studentindex]=mainstudent
    
                var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))
    
                setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/adddebt/", completedata:data22,
                data:{
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    balance: balancer,
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID
                
                }})
              
    
                    setOpenoffline(true)
    
            setloading(!true)
            handleClicksnack()
            setmessage("No Internet connection found")
            setstated("error")
    
            }
           }else{
    
            
            var data= [...data11[0]]
            var studentindex = data.findIndex(bug=>bug._id===chosen._id)
            var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
          
            mainstudent.finance=main
            data[studentindex]=mainstudent
    
            var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))
    
            setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/adddebt/", completedata:data22,
            data:{
                id: chosen._id,
                ID: chosen.id,
                lastname: chosen.lastname,
                othername: chosen.othernames,
                amountpaid: amount,
                balance: balancer,
                guardiancontact: payercontact,
                othercontact: otherpayercontact,
                guardianlastname: paidby,
                data: main,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                schoolID:admin.schoolID}})
          
    
            setOpenoffline(true)
            setloading(!true)
            handleClicksnack()
            setmessage("No Internet connection found")
            setstated("error")
    
           }


    }


  



  //actions

    const paycharges = () =>{
        setloading(true)
        var paidby = ""
        var payercontact = ""
        var otherpayercontact = ""
        var description = selectedcharge_==="Others"? document.getElementById("describecharge").value:selectedcharge_
        var receiver = admin.lastname!==undefined? (admin.lastname).toUpperCase() +" "+admin.othernames && (admin.othernames).toUpperCase():"Main Administrator"

if(payer==="Others"){
    paidby = document.getElementById("paidby_charge").value
    payercontact = chosen.guardiancontact
    otherpayercontact = document.getElementById("payercontact_charge").value

}else if (payer==="Mother"){
payercontact=chosen.mothercontact
paidby = chosen.motherlastname
}else if (payer==="Father"){
payercontact=chosen.fathercontact
paidby = chosen.fatherlastname
}
        var amount = parseFloat(document.getElementById("amountpaid_charges").value)
        var fees = chosen.charges
        var class_chosen= Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges
        var totalfees = class_chosen[0].filter(bug=>bug.description===selectedcharge).length!==0 ? class_chosen[0].filter(bug=>bug.description===selectedcharge)[0].amount:0
        var main = []

        if(fees === undefined || fees.length===0){
            var data = [{term:selectedterm, payment:[{date:date, time:time, receiver:receiver, paidby:paidby, amountpaid:amount, balance: totalfees===0?0: totalfees - amount, description:description }]}]
            main = data
        }else{

            var selected = fees.filter(bug=>bug.term === selectedterm)
            
            if(selected.length===0){
                // var balance_ = fees[fees.length-1].balance
                var d = {term:selectedterm,  payment:[{date:date, time:time, receiver:receiver, paidby:paidby, amountpaid:amount, balance:  totalfees===0?0: totalfees - amount, description:description }]}
                main=[...fees, d]
            }else{
                var unselected = fees.filter(bug=>bug.term !== selectedterm)
                var selected = fees.filter(bug=>bug.term === selectedterm)
                var deducted=0
                for (var i in selected[0].payment){
                    if(selected[0].payment[i].description===description){
                        deducted+=parseFloat(selected[0].payment[i].amountpaid)
                    }
                } 
                var d = {term:selectedterm, payment:[...selected[0].payment,{date:date, time:time,receiver:receiver, paidby:paidby, amountpaid:amount, balance: totalfees===0?0: (totalfees-deducted) - amount, description:description }]}
                main=[...unselected, d]
            }

        }

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/paycharges/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    lastname: chosen.lastname,
                    othername: chosen.othernames,
                    amountpaid: amount,
                    description:description,
                    balance: ( totalfees===0?0:(totalfees-deducted) - amount),
                    guardiancontact: payercontact,
                    othercontact: otherpayercontact,
                    guardianlastname: paidby,
                    data: main,
                    smsname:schooldetails_[0].smsname,
                    smsID:schooldetails_[0].smsID,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        // gettingdata()

                        var data= [...data11[0]]
                        var studentindex = data.findIndex(bug=>bug._id===chosen._id)
                        var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
                      
                        mainstudent.othercharge=main
                        data[studentindex]=mainstudent
  
                        var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))
  
  
                        dispatch(getdata({
  
                          data: data22
  
                      }))
                        setloading(!true)
                        handleClosecharge()
                        handleClicksnack()
                        setmessage("GHC " +amount+" paid as "+description)
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosecharge()
                        handleClicksnack()
                        setmessage("Payent error")
                        setstated("error")

                    }

                })

        }

//         try{
//         doer()
//     }catch(err){
//         setloading(!true)
//         handleClicksnack()
//         setmessage("Network Error")
//         setstated("error")
// }




if(navigator.onLine===true){ 
    try{
    doer()
    }catch(err){

       

        var data= [...data11[0]]
        var studentindex = data.findIndex(bug=>bug._id===chosen._id)
        var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
      
        mainstudent.finance=main
        data[studentindex]=mainstudent

        var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

        setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/paycharges/", completedata:data22,
        data:{
            id: chosen._id,
            ID: chosen.id,
            lastname: chosen.lastname,
            othername: chosen.othernames,
            amountpaid: amount,
            description:description,
            balance: ( totalfees===0?0:(totalfees-deducted) - amount),
            guardiancontact: payercontact,
            othercontact: otherpayercontact,
            guardianlastname: paidby,
            data: main,
            smsname:schooldetails_[0].smsname,
            smsID:schooldetails_[0].smsID,
            schoolID:admin.schoolID
        
        }})
      

            setOpenoffline(true)

    setloading(!true)
    handleClicksnack()
    setmessage("No Internet connection found")
    setstated("error")

    }
   }else{

    
    var data= [...data11[0]]
    var studentindex = data.findIndex(bug=>bug._id===chosen._id)
    var mainstudent = data.filter(bug=>bug._id===chosen._id)[0]
  
    mainstudent.finance=main
    data[studentindex]=mainstudent

    var data22 = data.map((list, index) => createData2(list._id,list.id, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academic, list.finance, list.attendance, list.awards, list.behaviour, list.others, list.charges, list.spacialID, list.nextclass, list.housing))

    setsyncoffline({type:"payfees",id:new Date(), date:date , link:"https://servermain.sisrevolution.com/api/students/paycharges/", completedata:data22,
    data:{
        id: chosen._id,
        ID: chosen.id,
        lastname: chosen.lastname,
        othername: chosen.othernames,
        amountpaid: amount,
        description:description,
        balance: ( totalfees===0?0:(totalfees-deducted) - amount),
        guardiancontact: payercontact,
        othercontact: otherpayercontact,
        guardianlastname: paidby,
        data: main,
        smsname:schooldetails_[0].smsname,
        smsID:schooldetails_[0].smsID,
        schoolID:admin.schoolID}})
  

    setOpenoffline(true)
    setloading(!true)
    handleClicksnack()
    setmessage("No Internet connection found")
    setstated("error")

   }




    }




    const paydailycharges = () =>{
        setloading(true)
        var amount = parseFloat(document.getElementById("dailyfee").value)


        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/dailycharges/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    amount: amount,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        gettingdata()
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Daily charge of GHC " +amount+" added ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Error adding daily charge")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }catch(err){
        setloading(!true)
        handleClicksnack()
      
        setmessage("Network Error")
        setstated("error")
}

    }




    const payweeklycharges = () =>{
        setloading(true)
        var amount = parseFloat(document.getElementById("weeklyfee").value)


        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/weeklycharges/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    amount: amount,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        gettingdata()
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Weekly charge of GHC " +amount+" added ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Error adding daily charge")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }catch(err){
        setloading(!true)
        handleClicksnack()
      
        setmessage("Network Error")
        setstated("error")
}

    }




    const paymonthlycharges = () =>{
        setloading(true)
        var amount = parseFloat(document.getElementById("monthlyfee").value)


        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/monthlycharges/",
                {
                    id: chosen._id,
                    ID: chosen.id,
                    amount: amount,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        gettingdata()
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Monthly charge of GHC " +amount+" added ")
                        setstated("success")
                       
                        
                    } else {
                        setloading(!true)
                        handleClosemessage()
                        handleClicksnack()
                        setmessage("Error adding daily charge")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }catch(err){
        setloading(!true)
        handleClicksnack()
      
        setmessage("Network Error")
        setstated("error")
}

    }





const debtorsnotification=()=>{

        setsmsloading(true)

        const fd = new FormData()
      //here
        const uploading = async() => {
            await axios.post("https://servermain.sisrevolution.com/api/students/debtorssms/", {
                schoolID:admin.schoolID,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                // list: rows,
                classlist: Class_

            }).then(res => {

                if (res.status === 200) {
                    setsmsloading(!true)
                    handleCloseadd()
                    handleClicksnack()
                    setmessage("Message Sent to debtors")
                    setstated("success")
                   
                }
            //    else if (res.status === 204) {
            //         setsmsloading(false)
            // handleClicksnack()
            // setmessage("Error Sending SMS")
            // setstated("error")
                    
            //     }
            else{
                    setsmsloading(false)
                    handleClicksnack()
                    setmessage("Error sending debtors notification")
                    setstated("success")
                            
                }
                if(res.data.length!==0){
                    setunsent(res.data)
                    setopenunsent(true)

                }

            })

        }
        try{
        uploading()
    }catch(err){
        setmessage("Network Error")
        setstated("error")
        handleClicksnack()
        setsmsloading(false)
    }
   


}



const bulkbill=()=>{

        setbillloading(true)

        const fd = new FormData()
      
        const uploading = async() => {
            await axios.post("https://servermain.sisrevolution.com/api/students/bulkbill/", {
                schoolID:admin.schoolID,
                smsname:schooldetails_[0].smsname,
                smsID:schooldetails_[0].smsID,
                classlist: Class_,
               
                term:schooldetails_[0].terms.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none"

            }).then(res => {

                if (res.status === 200) {
                    setbillloading(!true)
                    handleCloseadd()
                    handleClicksnack()
                    setmessage("Bills sent")
                    setstated("success")
                   
                }
            else{
                    setbillloading(false)
                    handleClicksnack()
                    setmessage("Bills sent")
                    setstated("success")
                            
                }

                if(res.data.length!==0){
                    setunsent(res.data)
                    setopenunsent(true)

                }

            })

        }
        try{
        uploading()
    }catch(err){
        setmessage("Network Error")
        setstated("error")
        handleClicksnack()
        setbillloading(false)
    }
   

}



    const addtolist = (data) => {
        setloading(true)
        console.log(data)
        var quantity = document.getElementById("Quantity").value
        var SRV = document.getElementById("SRVrec").value
        var By = document.getElementById("supplier").value
        var term = age
        var done = [...data.data, { date: date, sem: term, quantity: quantity, SRVrec: SRV, SRVsent: 0, SRVcon: 0, SRVpaid: 0, suppliedby: By }]

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/updateschooldata/",
                {
                    id: data.id,
                    data: done,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        }

        try{
            doer()
        }catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
}

    }

    const changeedit = (changer,e) => {
        changer(e.target.value)
        console.log(e.target.value)
    }

    const editschool = (data) => {
        setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/editschool/",
                {
                    id: chosen.id,
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("data has been edited")
                        setstated("sucess")
                        
                    } else {
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error editting data")
                        setstated("error")
                    }

                })

        }

        try{
            doer()
        }catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
}

    }

    const addschool = (data) => {
        setloading(true)
        var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")}

                })

        }

        try{
            doer()
        }catch(err){
            setloading(!true)
            handleClicksnack()
          
            setmessage("Network Error")
            setstated("error")
}

    }

     const deleter = (data) => {

         setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/deleteschool/",
                {
                   id:chosen.id,
                   schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }

        
        try{
            doer()
        }catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
}

    }

     const addclassfees = ()=>{
        setloading(true)
        const amount = document.getElementById("classfees").value

         const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/classfees/",
                {
                   classname:selectedclass,
                   amount:amount,
                   schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }

        try{
        doer()
         }catch(err){
                        setloading(!true)
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")
        }

    }

    const calcoutstanding = () =>{
        var total = 0
        for(var i in rows){

            
           total+=parseFloat( rows[i].finance.length !== 0 && rows[i].finance[rows[i].finance.length-1].term === mainterm ? (parseFloat(rows[i].finance[rows[i].finance.length-1].balance).toFixed(2)) : rows[i].finance.length !== 0 ?  rows[i].finance[rows[i].finance.length-1].Arrears !==null ? ((parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_).length!==0 && Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees) + parseFloat(rows[i].finance[rows[i].finance.length-1].balance)+ parseFloat(rows[i].finance[rows[i].finance.length-1].Arrears)).toFixed(2)):(parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees).toFixed(2)) :(parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_).length!==0 && (Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees)).toFixed(2))  )

        }
        return total
    } 

    
    const calcdue = () =>{
        var total = 0
        for(var i in rows){

            
            total += parseFloat(Class_.length!==0 && rows[i].finance.length !== 0 ?  rows[i].finance[rows[i].finance.length-1].Arrears !==null && rows[i].finance[rows[i].finance.length-1].term === mainterm ? ((parseFloat(totalamountpaid(rows[i].finance[rows[i].finance.length-1].payment)) + parseFloat(rows[i].finance[rows[i].finance.length-1].balance)).toFixed(2)): ((parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees)+parseFloat(rows[i].finance[rows[i].finance.length-1].Arrears)+parseFloat(rows[i].finance[rows[i].finance.length-1].balance)).toFixed(2)) : Class_.length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_).length!==0 && rows[i] !==null && rows[i].length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees)).toFixed(2))))

        }
        return total
    } 
    

    const chooser=()=>{
        var del=0
if(schooldetails_[0].terms!==undefined && schooldetails_[0].terms.length!==0 && schooldetails_[0].terms[schooldetails_[0].terms.length-1].split('Term')[0] !=="3rd"){
        if(chosen.length !==0 && Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges.length!==0){
            for(var i in Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges[0]){
                del+=parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges[0][i].amount)
            }

        }
    }else{
        if(chosen.nextclass!==undefined && chosen.nextclass !=="" && chosen.length !==0 && Class_.filter(bug=>bug.classname===chosen.nextclass)[0].othercharges.length!==0){
            for(var i in Class_.filter(bug=>bug.classname===chosen.nextclass)[0].othercharges[0]){
                del+=parseFloat(Class_.filter(bug=>bug.classname===chosen.nextclass)[0].othercharges[0][i].amount)
            }

        }
    }
        return del

    }

    const handleClickOpenedit = (data) => {
        console.log(data)
        setchosen(data)
        setOpenedit(true);
    };
    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimage(true);
    };
    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClickOpenoffline = (data) => {
       
        setchosen(data)
        setOpenoffline(true);
    };
    const handleCloseoffline = () => {
        setOpenoffline(false);
    };

    const handleClickOpenadd = () => {
        setOpenadd(true);
    };
    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };
    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        setchosen(data)
        setOpenview(true);
    }; 
    const handleClickOpenviewbill = (data) => {
        setchosen(data)
        setOpenviewbill(true);
    }; 
    
    const handleChangeterm = (e) => {
        setselectedterm(e.target.value)
        
    };
   
    const handleChangecharge = (e) => {
        setselectedcharge(e.target.value)
        
    };

 const handleChangecharge_ = (e) => {
        setselectedcharge_(e.target.value)
        
    };


    const handleCloseview = () => {
        setOpenview(false);
    };
    const handleCloseviewbill = () => {
        setOpenviewbill(false);
    };

      const handleClickOpenattendance = (data) => {
        console.log(data)
        setchosen(data)
        setOpenattendance(true);
    };
    const handleCloseattendance = () => {
        setOpenattendance(false);
    };
    
    const handleClickOpenmessage = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenmessage(true);}
    };
    

    const printreceipt=(data)=>{
    setfeereceipt(data)
    setOpenfees(false)
    setOpenreceipt(true)


    }

    const printreceipt2=(data)=>{
    setfeereceipt2(data)
    setOpenfees(false)
    setOpenreceipt2(true)


    }


    const handleClickOpenfees = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenfees(true);}
    };


  const handleClickOpenmessagearrears = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenmessagearrears(true);}
    };



  const handleClickOpenmessagediscount = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenmessagediscount(true);}
    };




    const handleClosemessage = () => {
        setOpenmessage(false);
    };


    const handleClosefees = () => {
        setOpenfees(false);
    };


    const handleClosereceipt = () => {
        setOpenreceipt(false);
    };


    const handleClosereceipt2 = () => {
        setOpenreceipt2(false);
    };



    const closedebtors = () => {
        setopendebtors(false);
    };

    

    const closeunsent = () => {
        setopenunsent(false);
    };

    
    const printdebtors = () => {
        setopendebtors(true);
    };




   const handleClosemessagearrears = () => {
        setOpenmessagearrears(false);
    };
   const handleClosemessagediscount = () => {
        setOpenmessagediscount(false);
    };


    const handleClickOpencharge = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpencharge(true);
        }
    };

    
    const handleClickOpendailyfee = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpendailyfee(true);
        }
    };





    
    const handleClickOpenweeklyfee = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenweeklyfee(true);
        }
    };

    
    const handleClickOpenmonthlyfee = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenmonthlyfee(true);
        }
    };






    
    const handleClosecharge = () => {
        setOpencharge(false);
    }; 

    const handleClosedailyfee = () => {
        setOpendailyfee(false);
    };
    const handleCloseweeklyfee = () => {
        setOpenweeklyfee(false);
    };
    const handleClosemonthlyfee = () => {
        setOpenmonthlyfee(false);
    };

    const handleClickOpensupply = (data) => {
        console.log(data)
        setchosen(data)
        setOpensupply(true);
    };

    const handleClosesupply = () => {
        setOpensupply(false);
    };

    const totalsupplied = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].quantity
        }

        return total
    }

    const srvrec = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].SRVrec
        }


        return total
    }

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
  
    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }
    
    const sorting = (e) => {
        setexportdata([])
        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


const setback=(data)=>{
    setwhat(data)
}
        const sendmessage = () =>{

            return(



<div style={{textAlign:"center", margin:8}}>
<p style={{textAlign:"center", margin:12}}>
                        Send a message to Guardian via 
                    </p>
                    <Button  aria-label="fingerprint"  style={{margin:12}} color="primary">
        <WhatsAppIcon style={{fontSize:"30px"}} /> <br/> <small> Whatsapp</small>
      </Button>
           <Button onClick={()=>setback("email")} aria-label="fingerprint" style={{margin:12}} color="primary">
        <EmailIcon style={{fontSize:"30px"}} /> <br/> <small> Email</small>
      </Button>
   <Button onClick={()=>setback("sms")} aria-label="fingerprint" style={{margin:12}} color="primary">
        <MessageIcon style={{fontSize:"30px"}} /> <br/> <small> Text Message</small>
      </Button>
        
                
</div>

            )
        }

        const viaSMS = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }
    
        const viaEmail = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }


   const viaEmailandtext = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email and SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }

        const guardianchange =(e)=>{

            setGuardian(e.target.value)

        }

const messenger =(type)=>{
    if(type==="email"){
        return(
            viaEmail()
        )
    }
    if(type==="sms"){
        return(
            viaSMS()
        )
    }  if(type==="back"){
        return(
            sendmessage()
        )
    }
}

        const otherguardian = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="surname"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"

    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="othernames"
    placeholder="Other name(s)"
    type="text"
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="occupation"
    placeholder="Occupation"
    type="text"
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="contact"
    placeholder="Contact"
    type="tel"
    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="email"
    placeholder="Email"
    type="email"
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="address"
    placeholder="Address"
    type="address"
    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }


        if(activestudents===false && withdrawnstudents===false && completestudents===false){

            setactivestudents(true)
            setgettingdatatype("Active")

        }

        const changestudenttype=(e, name)=>{
            setexportdata([])
            console.log(e.target.checked)
            if(name==="Active"){
                setactivestudents(e.target.checked)
                setwithdrawnstudents(false)
                setcompletestudents(false)
                setgettingdatatype("Active")
            }
             else if(name==="Completed"){
                setactivestudents(false)
                setwithdrawnstudents(false)
                setcompletestudents(e.target.checked) 
                setgettingdatatype("Completed")

            }   
            else if(name==="Withdrawn"){
                setactivestudents(false)
                setwithdrawnstudents(e.target.checked)
                setcompletestudents(false) 
                setgettingdatatype("Withdrawn")


            }

            

        }
           
   const changefeetype=(e, name)=>{
            console.log(e.target.checked)
            if(name==="debtors"){
                setdebtors(e.target.checked)
                setcomplete(false)
            }
             else if(name==="complete"){
                setdebtors(false)
                setcomplete(e.target.checked) 

            }   
          
        }
           
        const settrialmode_=()=>{
            settrialmode(false)
        }
    
const totalamountpaid=(input)=>{
    var result = 0
    for(var i in input){
        result+=parseFloat(input[i].amountpaid)

    }
    return result
}

    return (

        <Box sx={{ width: '100%' }}>


       {/* <Offline><div>{onlinecheck===true && setonlinecheck(false)}</div></Offline> */}

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>


            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>




                 

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="lg" onClose={closedebtors} aria-labelledby="customized-dialog-title" open={opendebtors}>
 <DialogTitle id="customized-dialog-title" onClose={closedebtors}  style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Print Debtors
                    <IconButton onClick={closedebtors}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Debtors List</Button>}
            content ={()=>componentRef.current}
            />

<div ref={componentRef} >
<div style={{textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h3 style={{margin:4}}> Debtors List </h3>
</div>


              {rows.length!==0 &&
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>No. </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Student Name </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Class</TableCell>
                    
                      <TableCell align="left" style={{color:"red" , fontWeight:"bolder", margin:3, padding:3}}>Balance</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.class_}
              </TableCell>
              <TableCell align="left" style={{color:"red", margin:3, padding:3}}>{row.finance.length !== 0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].balance).toFixed(2)) : row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null ? "GHC "+ Intl.NumberFormat('en-US').format((parseFloat(row.finance[row.finance.length-1].totalfees) + parseFloat(row.finance[row.finance.length-1].Arrears)).toFixed(2)):"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].totalfees).toFixed(2)) :"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && Class_.filter(bug=>bug.classname===row.class_)[0].classfees).toFixed(2))}</TableCell>

          </TableRow>))} 
        </TableBody>
      </Table>
    </TableContainer>
}


</div>          

                </DialogContent>
                <DialogActions>




                 

                  
                </DialogActions>
            </Dialog>





            <Dialog fullWidth maxWidth="lg" onClose={closeunsent} aria-labelledby="customized-dialog-title" open={openunsent}>
 <DialogTitle id="customized-dialog-title" onClose={closeunsent}  style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Print Unsent SMS List
                    <IconButton onClick={closeunsent}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Unsent List</Button>}
            content ={()=>componentRefunsent.current}
            />

<div ref={componentRefunsent} >
<div style={{textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h3 style={{margin:4}}> Unsent SMS List </h3>
    <p style={{color:"red"}}>The list below did not received the SMS sent to all students, please make sure the guardian's contacts are intact and resend the SMS individually</p>
</div>


              {unsent.length!==0 &&
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>No. </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Student Name </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Name</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Contact</TableCell>
                    

          </TableRow>
        </TableHead>
        <TableBody>
          {unsent.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.class_}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardianlastname+" "+row.guardianothername}
              </TableCell> 
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardiancontact}
              </TableCell>

          </TableRow>))} 
        </TableBody>
      </Table>
    </TableContainer>
}


</div>          

                </DialogContent>
                <DialogActions>




                 

                  
                </DialogActions>
            </Dialog>





            <Dialog maxWidth="md" onClose={handleCloseoffline} aria-labelledby="customized-dialog-title" open={openoffline}>

                <DialogContent dividers>
<div style={{width:"100%", textAlign:"center"}}>
    <img src={Networkerror} style={{width:"30%"}}/>
</div>
                    <p style={{textAlign:"center"}}>
                        <b>You are currently offline, do you want to save the data and sync to the database online latter?
                        </b>
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={()=>saveoffline(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    

                    <Button autoFocus onClick={handleCloseoffline} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>




            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>

                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={()=>deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    

                    <Button autoFocus onClick={handleClosedelete} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>




            
            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessage} aria-labelledby="customized-dialog-title" open={openmessage}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosemessage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Pay Fees
                    <IconButton onClick={handleClosemessage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    
                    
    <FormControl style={{ margin: 10}}>
    <InputLabel id="demo-simple-select-autowidth-label">Charge Description</InputLabel>
    <Select
        labelId="demo-simple-select-autowidth-label"
        id=""
        value={selectedcharge}
        className="signup"
        align="left"
        onChange={(e)=>handleChangecharge(e)}
        autoWidth
        label="Charge Description"
        size="small"
    >
        <MenuItem value="none">
            <em>Choose Description</em>
        </MenuItem>  
        <MenuItem value="School Fees">
            <em>School Fees</em>
        </MenuItem>
        {chosen.length !==0 && Class_.filter(bug=>bug.classname === chosen.class_) !==undefined  ?
        Class_.filter(bug=>bug.classname === chosen.class_)[0].othercharges[0].map((list,index)=>
        <MenuItem value={list.description}>{list.description}</MenuItem>
        ) :""   
    }
    </Select>

</FormControl>



                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaid"
    placeholder="0.00"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Amount Paid"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

                    {/* <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="Arrears"
    placeholder="Arrears from last term"
    type="number"
    defaultValue={0}
    className="signup"
    variant="outlined"
    label="Arrears from last term"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
    size="small"
/>
                    </FormControl> */}

                    <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Paid By</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={payer}
                                onChange={(e)=>setpayer(e.target.value)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        {
                            payer==="Others"?
                            <>
                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidby"
    placeholder="Paid By"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid By"
    size="small"
/>
                    </FormControl>  
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="payercontact"
    placeholder="Contact"
    type="tel"
    className="signup"
    variant="outlined"
    label="Payer's Contact"
    size="small"
/>
                    </FormControl>

                    </>
                    :
                    ""
}


             



                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => payfees()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>



           
            <Dialog fullWidth maxWidth="sm" onClose={handleClosefees} aria-labelledby="customized-dialog-title" open={openfees}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosefees} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Add Or Deduct Fees
                    <IconButton onClick={handleClosefees} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>
<p style={{textAlign:"left"}}><small>This features is used to add or deduct an amount from school fees without sending a notification to parents. <br/>Positive value will be added to amount paid, Eg. if input value=10 amount paid will increase by 10 and oustanding balance will reduce by 10 <br/>Negative values will be deducted from amount paid, Eg. if input value= -10 amount paid will reduce by 10 and oustanding balance will increase by 10</small></p>
            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    
                    
    <FormControl style={{ margin: 10}}>
    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="editdescription"
    placeholder="Description"
    type="text"
    className="signup"
    variant="outlined"
    // defaultValue={0}
    label="Description"
    size="small"
    // InputProps={{
    //     startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
    //   }}



/>
                    </FormControl>

</FormControl>



                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaidedit"
    placeholder="0.00"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Amount Paid"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

                    {/* <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="Arrears"
    placeholder="Arrears from last term"
    type="number"
    defaultValue={0}
    className="signup"
    variant="outlined"
    label="Arrears from last term"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
    size="small"
/>
                    </FormControl> */}

                    {/* <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Paid By</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={payer}
                                onChange={(e)=>setpayer(e.target.value)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        {
                            payer==="Others"?
                            <>
                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidby"
    placeholder="Paid By"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid By"
    size="small"
/>
                    </FormControl>  
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="payercontact"
    placeholder="Contact"
    type="tel"
    className="signup"
    variant="outlined"
    label="Payer's Contact"
    size="small"
/>
                    </FormControl>

                    </>
                    :
                    ""
} */}


             



                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => payfeesedit()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>




       

           
            <Dialog fullWidth maxWidth="sm" onClose={handleClosereceipt} aria-labelledby="customized-dialog-title" open={openreceipt}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosereceipt} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                 Print Receipt
                    <IconButton onClick={handleClosereceipt} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>


                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Receipt</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<div style={{textAlign:"center", padding:"10px"}}>
<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>

<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h5 style={{margin:4}}>PAYMENT RECEIPT</h5>

</div>

</div>

   
</div>

<Divider></Divider>
<Grid container spacing={0}>
            <Grid item xs={7} align="left" style={{paddingLeft:0}}>
            <span><b>Student Name: </b>{chosen.length!==0 && chosen.lastname+" "+chosen.othernames} </span> <br/>
            <span><b>Student ID: </b>{chosen.length!==0 && chosen.ID} </span> <br/>
            <span><b>Class: </b>{chosen.length!==0 && chosen.class_} </span> <br/>
            </Grid>  

            <Grid item xs={5} align="left">
            <span><b>Date: </b>{feereceipt.length!==0 ? (months[parseInt((feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[1])-1] +" "+(feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[2]+", "+(feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[0] ):""}</span>
            <br/>
            <span><b>Term: </b>{selectedterm}</span> <br/>
            <span><b>No.: </b>{feereceipt.length!==0 && chosen.length!==0 && (new Date(feereceipt.payment[feereceipt.payment.length-1].date).getTime()).slice(0,5)+chosen.ID.slice(3,4)}</span> <br/>
            </Grid>

            </Grid>
          
            <Divider></Divider>
         
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Payment Description</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Paid By</TableCell>                    
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Amount</TableCell>                    
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Balance</TableCell>                    

          </TableRow>
        </TableHead>
        <TableBody>
        

 <TableRow
                  key={2}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {feereceipt.length!==0 && feereceipt.payment[feereceipt.payment.length-1].des}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              {feereceipt.length!==0 && feereceipt.payment[feereceipt.payment.length-1].paidby}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              GHC {feereceipt.length!==0 && Intl.NumberFormat('en-US').format(feereceipt.payment[feereceipt.payment.length-1].amountpaid)}
              </TableCell> 
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              GHC {feereceipt.length!==0 && Intl.NumberFormat('en-US').format(feereceipt.payment[feereceipt.payment.length-1].balance)}
              </TableCell>
             
          </TableRow> 
        </TableBody>
      </Table>
    </TableContainer>


<div style={{textAlign:"left"}}>
<p>................................................</p>
<p>Signature / Stamp</p>
</div>
</div>          






                    </DialogContent>
                <DialogActions>
<Divider/>

 {/* {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => payfeesedit()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            } */}
                  
                    

                    
                </DialogActions>
            </Dialog>



   

           
            <Dialog fullWidth maxWidth="sm" onClose={handleClosereceipt2} aria-labelledby="customized-dialog-title" open={openreceipt2}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosereceipt2} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                 Print Receipt
                    <IconButton onClick={handleClosereceipt2} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>


                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Receipt</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<br/>
<div style={{display:"flex", textAlign:"left"}}>

<div style={{width:"30%", textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>

</div>


<div style={{width:"69%"}}>
<h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].contact).toUpperCase()} </h5>
    <h3 style={{margin:4}}>PAYMENT RECEIPT</h3>
</div>

</div>


<Divider></Divider>
<Grid container spacing={0}>
<Grid item xs={7} align="left" style={{paddingLeft:0}}>
            <span><b>Student Name: </b>{chosen.length!==0 && chosen.lastname+" "+chosen.othernames} </span> <br/>
            <span><b>Student ID: </b>{chosen.length!==0 && chosen.ID} </span> <br/>
            <span><b>Class: </b>{chosen.length!==0 && chosen.class_} </span> <br/>
            </Grid>  

            <Grid item xs={5} align="left">
            <span><b>Date: </b>{feereceipt.length!==0 ? (months[parseInt((feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[1])-1] +" "+(feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[2]+", "+(feereceipt.payment[feereceipt.payment.length-1].date.split("-"))[0] ):""}</span>
            <br/>
            <span><b>Term: </b>{selectedterm}</span> <br/>
            <span><b>No.: </b>{feereceipt.length!==0 && chosen.length!==0 && (new Date(feereceipt.payment[feereceipt.payment.length-1].date).getTime()).slice(0,5)+chosen.ID.slice(3,4)}</span> <br/>
            </Grid>

            </Grid>
          
            <Divider></Divider>
         
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Payment Description</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Paid By</TableCell>                    
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Amount</TableCell>                    
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Balance</TableCell>                    

          </TableRow>
        </TableHead>
        <TableBody>
        

 <TableRow
                  key={2}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {feereceipt2.length!==0 && feereceipt2.des}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              {feereceipt2.length!==0 && feereceipt2.paidby}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              GHC {feereceipt2.length!==0 && Intl.NumberFormat('en-US').format(feereceipt2.amountpaid)}
              </TableCell> 
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
              GHC {feereceipt2.length!==0 && Intl.NumberFormat('en-US').format(feereceipt2.balance)}
              </TableCell>
             
          </TableRow> 
        </TableBody>
      </Table>
    </TableContainer>


<div style={{textAlign:"left"}}>
<p>................................................</p>
<p>Signature / Stamp (Office use only)</p>
</div>
</div>          






                    </DialogContent>
                <DialogActions>
<Divider/>

 {/* {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => payfeesedit()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            } */}
                  
                    

                    
                </DialogActions>
            </Dialog>




                 
            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessagediscount} aria-labelledby="customized-dialog-title" open={openmessagediscount}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosemessagediscount} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Discount
                    <IconButton onClick={handleClosemessagediscount} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountdiscount"
    placeholder="0.00"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Amount to discount"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

                    {/* <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="Arrears"
    placeholder="Arrears from last term"
    type="number"
    defaultValue={0}
    className="signup"
    variant="outlined"
    label="Arrears from last term"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
    size="small"
/>
                    </FormControl> */}



             



                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => discount()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>

           
            
            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessagearrears} aria-labelledby="customized-dialog-title" open={openmessagearrears}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosemessagearrears} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Arrears
                    <IconButton onClick={handleClosemessagearrears} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    

                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountindebt"
    placeholder="0.00"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Amount Paid"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}



/>
                    </FormControl>

                    {/* <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="Arrears"
    placeholder="Arrears from last term"
    type="number"
    defaultValue={0}
    className="signup"
    variant="outlined"
    label="Arrears from last term"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
    size="small"
/>
                    </FormControl> */}



             



                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => adddebt()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>

            
            <Dialog fullWidth maxWidth="sm" onClose={handleClosecharge} aria-labelledby="customized-dialog-title" open={opencharge}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosecharge} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Pay Other Charges
                    <IconButton onClick={handleClosecharge} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>
<small style={{color:"red"}}>This section is for collection of all other charges that does not amount to the total fees for a term, payments made here are not deducted from the student's fees but are recorded</small>
            <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    
                                       
    <FormControl style={{ margin: 10}}>
    <InputLabel id="demo-simple-select-autowidth-label">Charge Description</InputLabel>
    <Select
        labelId="demo-simple-select-autowidth-label"
        id=""
        value={selectedcharge_}
        className="signup"
        align="left"
        onChange={(e)=>handleChangecharge_(e)}
        autoWidth
        label="Charge Description"
        size="small"
    >
        <MenuItem value="none">
            <em>Choose Description</em>
        </MenuItem>  
        <MenuItem value="Daily Charge">Daily Charge</MenuItem>
        <MenuItem value="Weekly Charge">Weekly Charge</MenuItem>
        <MenuItem value="Monthly Charge">Monthly Charge</MenuItem>
        <MenuItem value="Termly Charge">Termly Charge</MenuItem>
        <MenuItem value="Books Charge">Books Charge</MenuItem>
        <MenuItem value="Uniform Charge">Uniform Charge</MenuItem>
        <MenuItem value="Admission Fee">Admission Fee</MenuItem>
        <MenuItem value="Printing Fee">Printing Fee</MenuItem>
        <MenuItem value="Canteen Fee">Canteen Fee</MenuItem>
        <MenuItem value="Others">Others</MenuItem>

       
    </Select>

</FormControl>

{selectedcharge_ === "Others" &&
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="describecharge"
    placeholder="Charge Description"
    type="text"
    className="signup"
    variant="outlined"
    label="Charge Description"
    size="small"
    // InputProps={{
    //     startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
    //   }}
/>
                    </FormControl>
                    }

         <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="amountpaid_charges"
    placeholder="Amount Paid"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Amount Paid"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>


                    <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Paid By</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={payer}
                                onChange={(e)=>setpayer(e.target.value)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        {
                            payer==="Others"?
                            <>
                   <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="paidby_charge"
    placeholder="Paid By"
    type="text"
    className="signup"
    variant="outlined"
    label="Paid By"
    size="small"
/>
                    </FormControl>  
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="payercontact_charge"
    placeholder="Contact"
    type="tel"
    className="signup"
    variant="outlined"
    label="Payer's Contact"
    size="small"
/>
                    </FormControl>

                    </>
                    :
                    ""
}

                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => paycharges()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>
    
            <Dialog fullWidth maxWidth="sm" onClose={handleClosedailyfee} aria-labelledby="customized-dialog-title" open={opendailyfee}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosedailyfee} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Set Daily Charges
                    <IconButton onClick={handleClosedailyfee} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

          


                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="dailyfee"
    placeholder="Daily Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Daily Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>


               
                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => paydailycharges()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>


    
            <Dialog fullWidth maxWidth="sm" onClose={handleCloseweeklyfee} aria-labelledby="customized-dialog-title" open={openweeklyfee}>
             <DialogTitle id="customized-dialog-title" onClose={handleCloseweeklyfee} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Set Weekly Charges
                    <IconButton onClick={handleCloseweeklyfee} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

          


                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="weeklyfee"
    placeholder="Weekly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Weekly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>


               
                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => payweeklycharges()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>





            <Dialog fullWidth maxWidth="sm" onClose={handleClosemonthlyfee} aria-labelledby="customized-dialog-title" open={openmonthlyfee}>
             <DialogTitle id="customized-dialog-title" onClose={handleClosemonthlyfee} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Set Monthly Charges
                    <IconButton onClick={handleClosemonthlyfee} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

          


                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="monthlyfee"
    placeholder="Monthly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Monthly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>


               
                    </DialogContent>
                <DialogActions>
<Divider/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => paymonthlycharges()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                  
                    

                    
                </DialogActions>
            </Dialog>












            <Dialog  maxWidth="md" onClose={handleCloseattendance} aria-labelledby="customized-dialog-title" open={openattendance}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Other Charges Information
                    <IconButton onClick={handleCloseattendance} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>

                <div align="center" style={{marginBottom:7}}>
  <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
             
</div>

 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="center"><b>Description</b></TableCell>
            <TableCell align="center"><b>Amount Paid</b></TableCell>
            <TableCell align="center"><b>Paid By</b></TableCell>
            <TableCell align="center"><b>Received By</b></TableCell>
            <TableCell align="center"><b>Balance</b></TableCell>
            {/* <TableCell align="left"></TableCell> */}
            
          </TableRow>
        </TableHead>
        <TableBody>

          {chosen.length !==0 ?chosen.charges !== undefined && chosen.charges.map((rower) => 
          (rower.term===selectedterm && rower.payment.map((row,list)=>
            <TableRow
              key={row.amount}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                        
              <TableCell align="center">{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] }</TableCell>
              <TableCell align="center">{row.description}</TableCell>
              <TableCell align="center">GHC {row.amountpaid}</TableCell>
              <TableCell align="center">{row.paidby}</TableCell>
              <TableCell align="center">{row.receiver}</TableCell>
              <TableCell align="center">GHC {row.balance}</TableCell>
              <TableCell align="center"><Button onClick={()=>printreceipt2(row)}>Print</Button></TableCell>
            </TableRow>
          )
          )

          ):""}
        </TableBody>
      </Table>
    </TableContainer>

                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseattendance} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog fullWidth maxWidth="md" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Fees Information
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                <div align="center" style={{marginBottom:7}}>
                <FormControl style={{ margin: 10}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            className="signup"
                            align="left"
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
             
</div>

 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="center"><b>Amount Paid</b></TableCell>
            <TableCell align="center"><b>Description</b></TableCell>
            <TableCell align="center"><b>Paid By</b></TableCell>
            <TableCell align="center"><b>Received By</b></TableCell>
            <TableCell align="center"><b>Balance</b></TableCell>
            <TableCell align="left"></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>

        {console.log(chosen)}
          {chosen.length !==0 ? chosen.finance.map((rower) => 
          
(selectedterm !== "none" && selectedterm !== "All" ? rower.term ===selectedterm? rower.payment.map((row, index)=>(
 
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
          
             
              <TableCell align="left">{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] }</TableCell>
              <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row.amountpaid)}</TableCell>
              <TableCell align="center">{row.des}</TableCell>
              <TableCell align="center">{row.paidby}</TableCell>
              <TableCell align="center">{row.receiver}</TableCell>
              <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row.balance)}</TableCell>
              <TableCell align="center"><Button onClick={()=>printreceipt2(row)}>Print</Button></TableCell>
            </TableRow>
            
          
            
            )
):"":

rower.payment.map((row, index)=>(


    <TableRow
    key={row.date}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >

   
    <TableCell align="left">{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] }</TableCell>
    <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row.amountpaid)}</TableCell>
    <TableCell align="center">{row.des}</TableCell>
    <TableCell align="center">{row.paidby}</TableCell>
    <TableCell align="center">{row.receiver}</TableCell>
    <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row.balance)}</TableCell>
    <TableCell align="center"><Button onClick={()=>printreceipt2(row)}>Print</Button></TableCell>
  </TableRow>)
)

)
          ):""}
        </TableBody>
      </Table>
    </TableContainer>












<br/>


                </DialogContent>
              
            </Dialog>


            <Dialog fullWidth maxWidth="md" onClose={handleCloseviewbill} aria-labelledby="customized-dialog-title" open={openviewbill}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseviewbill} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.length!==0 && chosen.lastname +" "+chosen.othernames + "'s Bill"}
                    <IconButton onClick={handleCloseviewbill} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div  ref={componentRef}>
                <div align="center" style={{marginBottom:7}}>
               
                <img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="100px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&(schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 && (schooldetails_[0].address).toUpperCase()} </h4>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 && (schooldetails_[0].contact)} </h4>
    <h4 style={{margin:4}}> STUDENT'S BILL FOR NEXT TERM </h4>
             
</div>
<hr/>
<div>
    
    <p style={{margin:"0px"}}><b>Student Name: </b>{chosen.length!==0 && chosen.lastname +" "+chosen.othernames}</p>
    <p style={{margin:"0px"}}><b>Class: </b>{chosen.length!==0 && chosen.class_}</p>
    <p style={{margin:"0px"}}><b>Date: </b>{(months[parseInt((date.split("-"))[1])-1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] )}</p>
    <p style={{margin:"0px"}}><b>Next Term Begins: </b>{schooldetails_.length!==0 && schooldetails_[0].reopendate!==""?months[parseInt((schooldetails_[0].reopendate.split("-"))[1])-1] +" "+(schooldetails_[0].reopendate.split("-"))[2]+", "+(schooldetails_[0].reopendate.split("-"))[0]:"Set Re-opening Date"}</p>
</div>


<br/>

 <TableContainer >
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{padding:2, margin:2}} align="left"><b>Description Of Bill Item</b></TableCell>
            <TableCell style={{padding:2, margin:2}} align="center"><b>Amount</b></TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
        {/* <TableRow
          key={0}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            
             <TableCell align="center">School Fees</TableCell>
          <TableCell align="center">{chosen.length!==0 && "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees))}</TableCell>
          </TableRow> */}

          {schooldetails_.length!==0 && schooldetails_[0].terms!==undefined && schooldetails_[0].terms.length!==0 && schooldetails_[0].terms[schooldetails_[0].terms.length-1].split('Term')[0] !=="3rd" ?chosen.length !==0 && Class_.filter(bug=>bug.classname===chosen.class_)[0].othercharges[0].map((row) => 
          

          <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
           
        {/* {bill+="\n"+row.description+" = GHC" +Intl.NumberFormat('en-US').format(row.amount)} */}
           
          <TableCell style={{padding:2, margin:2}} align="left">{row.description}</TableCell>
          <TableCell style={{padding:2, margin:2}} align="center">GHC {Intl.NumberFormat('en-US').format(row.amount)}</TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
       
        
    )
    :
    chosen.nextclass!==undefined && chosen.nextclass !== "" && chosen.length !==0 && Class_.filter(bug=>bug.classname===chosen.nextclass)[0].othercharges[0].map((row) => 
          

          <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {console.log("termer2")}
        {/* {bill+="\n"+row.description+" = GHC" +Intl.NumberFormat('en-US').format(row.amount)} */}
           
          <TableCell style={{padding:2, margin:2}} align="left">{row.description}</TableCell>
          <TableCell style={{padding:2, margin:2}} align="center">GHC {Intl.NumberFormat('en-US').format(row.amount)}</TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
       
        
    )
}
      <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
    
            {/* {bill+="\nTOTAL = GHC" +chosen.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)+chooser()+(chosen.length!==0 && chosen.finance.length!==0 ? parseFloat(chosen.finance[chosen.finance.length-1].balance):0))} */}

          <TableCell style={{padding:2, margin:2}} align="left"><b>Total</b></TableCell>
          <TableCell style={{padding:2, margin:2}} align="center"><b>{chosen.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(chooser())}</b></TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
             <p style={{color:"red", textAlign:"center", width:"100%"}}>Arrears From Last Term</p>

      <TableRow
          key={0}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {/* {bill+="\nArrears = GHC" +chosen.length!==0 && chosen.finance.length!==0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(chosen.finance[chosen.finance.length-1].balance)):0} */}

            <TableCell style={{padding:2, margin:2, color:"red"}} align="left">Arrears </TableCell>
          <TableCell style={{padding:2, margin:2, color:"red"}} align="center">{chosen.length!==0 && chosen.finance.length!==0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(chosen.finance[chosen.finance.length-1].balance)):0}</TableCell>
          </TableRow>

     <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
    
            {/* {bill+="\nTOTAL = GHC" +chosen.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)+chooser()+(chosen.length!==0 && chosen.finance.length!==0 ? parseFloat(chosen.finance[chosen.finance.length-1].balance):0))} */}

          <TableCell style={{padding:2, margin:2}} align="left"><b>Grand Total</b></TableCell>
          <TableCell style={{padding:2, margin:2}} align="center"><b>{chosen.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(chooser()+(chosen.length!==0 && chosen.finance.length!==0 ? parseFloat(chosen.finance[chosen.finance.length-1].balance):0))}</b></TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
   
        </TableBody>
      </Table>
    </TableContainer>
    <Divider/>
    <ul>
 {Class_.filter(bug=>bug.classname===chosen.class_).length!==0 && Class_.filter(bug=>bug.classname===chosen.class_)[0].billnotes.length!==0 && Class_.filter(bug=>bug.classname===chosen.class_)[0].billnotes!==undefined &&
 Class_.filter(bug=>bug.classname===chosen.class_)[0].billnotes.map((list,index)=><li>{list.billnotes}</li>)


 }
  </ul>




    </div>
    <div style={{textAlign:"center"}}> <ReactToPrint
             pageStyle='@page { size: A4 } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Bill</Button>}
            content ={()=>componentRef.current}
            />
            
            <Button>Send Bill</Button></div>
   
<br/>


                </DialogContent>
              
            </Dialog>


            <Dialog fullScreen maxWidth="" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Add to Supply List

                    <Button onClick={handleClosesupply} style={{float:"right", color:"white"}}>
                    close
                    </Button>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    
              
                    <FormControl style={{ margin: 0}}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={handleChangeterm}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose semester</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    <Button>
                    Print Report
                    </Button>
                    <br />
                    <br />

                    <Tablefees data={chosen.finance}  term={selectedterm}/>


                </DialogContent>

                <DialogActions>
                    {loading === false ?
                        <Button autoFocus onClick={() => addtolist(chosen)} color="primary">
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }
                    
                    <Button autoFocus onClick={handleClosesupply} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/uploads/'+chosen.image} width="100%"/>

                </DialogContent>

            </Dialog>



                <Dialog  maxWidth="md" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                   <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Class Fees
                    <IconButton onClick={handleCloseadd} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>



                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="none"> <em>Choose Class</em> </MenuItem>
                                {Class_.langth !== 0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                ):""}
                                
                             
                                

                            </Select>


                        </FormControl>

                   
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="classfees"
    placeholder="0.00"
    type="number"
    className="signup"
    variant="outlined"
    label="Amount"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>

</FormControl>



                        <div style={{ textAlign: "center", marginTop:15, marginBottom:15 }}>

                            {loading === false ?
                                <Button autoFocus onClick={() => addclassfees()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                                :
                                 <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
                            }

                           
                        </div>
                    </DialogContent>

                </Dialog>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundColor: "darkblue", color: "white" }}>
                        Edit School
                    </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="contained-button-file">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>
                        <br />

                        <Divider><small> <b>STUDENT'S PERSONAL DETAILS </b></small></Divider>
                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="surname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="date"
                                label="Date Of Birth"
                                type="date"
                                defaultValue="2017-05-24"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >
                                <MenuItem value="none"><em>Choose Religion</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="none"><em>Choose Gender</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="none"> <em>Choose Class</em></MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>


                            </Select>


                        </FormControl>

                        <br />
                        <br />
                        <Divider><small> <b>STUDENT'S PARENTS DETAILS </b></small></Divider>

                        <p style={{ textAlign: "center" }}> <b>Mother</b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="surname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="occupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="address"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <p style={{ textAlign: "center" }}> <b>Father </b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="surname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"

                                size="small"


                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="occupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="address"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />
                        <br />

                        <Divider><small> <b>GUARDIAN </b></small></Divider>
                        <br />

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={guardian}
                                onChange={(e)=>guardianchange(e)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Parents" control={<Radio />} label="Parents" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        {guardian === "Others"? otherguardian():"" }

                        <br />
                        <div style={{ textAlign: "center" }}>

                            {loading === false ?
                                <Button autoFocus onClick={() => addschool()} color="primary">
                                    Submit
                                </Button>
                                :
                                <Button disabled={loading} >

                                    <CircularProgress size={20} /> Loading...

                                </Button>
                            }


                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleCloseedit} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />
                <Divider />

               { admin.positiondescription!=="Administrator and Teller" &&

<Grid container style={{margin:0, padding:0, textAlign:"left", marginLeft:20}}>

<Grid lg={3} xs={2} md={2} >
<p><b>Class Fees</b>: {sorter!=="none" && sorter!=="All"? "GHC "+ Intl.NumberFormat('en-US').format(Class_.filter(bug=>bug.classname===sorter)[0].classfees):"Sort by Class"}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Total Fees Due</b>: GHC {Intl.NumberFormat('en-US').format(calcdue())}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Total Fees Paid </b>: GHC {Intl.NumberFormat('en-US').format(calcdue()-calcoutstanding())}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Outstanding</b>: GHC {Intl.NumberFormat('en-US').format(calcoutstanding())}</p>
</Grid>
<Grid lg={3} xs={2} md={2} style={{paddingBottom:5}}>

</Grid>

</Grid>

                        }

<div style={{textAlign:"center", marginTop:"-10px", marginBottom:"5px"}}><JsonToExcel title="Download Excel"  data={exportdata} fileName="School fees data" btnClassName="custom-classname" />
</div>
<Divider />
 
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={2} md={6}>

                        {debtors===false?
                        <>
                        <Tooltip title="Add Class Fees">
                        <Button
                            color="inherit"
                            aria-label="open drawer"
                                edge="start"
                                
                                style={{marginTop:10.5, backgroundColor:" #e0eff9" , color:"#1976d2"}}
                            onClick={handleClickOpenadd}

                        >
                                Class Fees
                        </Button>

                        
                    </Tooltip> 
                    
                    {
                        billloading?
                        <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        style={{marginTop:10.5}}
                      >
                        Sending Bill...
                      </LoadingButton>:
                    <Tooltip title="Send Next Term Bills">
                       
                        <Button
                            color="inherit"
                            aria-label="open drawer"
                                edge="start"
                                
                                style={{marginTop:10.5, backgroundColor:" #e0eff9" , color:"#1976d2", marginLeft:"15px"}}
                            onClick={()=>bulkbill()}

                        >
                               Send Bill
                        </Button>

                        
                    </Tooltip>
}
                    </>
                    :

<div>
    {smsloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10.5}}
      >
        Sending bulk SMS...
      </LoadingButton>:
<>
                    <Tooltip title="Send notification to debtors">
                    <Button
                        color="inherit"
                        aria-label="open drawer"
                            edge="start"
                            
                            style={{marginTop:10.5,backgroundColor:" #e0eff9" , marginLeft:"-30px" , color:"#1976d2"}}
                        onClick={debtorsnotification}

                    >
                            Send Debtors' Notification
                    </Button>
                    </Tooltip> 
                    
                    <Tooltip title="Print Debtors list">
                    <Button
                        color="inherit"
                        aria-label="open drawer"
                            edge="start"
                            
                            style={{marginTop:10.5,marginLeft:"5px", backgroundColor:" #e0eff9" , color:"#1976d2"}}
                        onClick={()=>printdebtors()}

                    >
                            Print
                    </Button>
                    </Tooltip>
                    </>
}


</div>
                    }
                 
                    </Grid>

                    <Grid item lg={1} xs={2} md={6} style={{textAlign:"center"}}>
                        <FormControl style={{ marginRight: 2,marginLeft:"-30px", minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <Select
                                id="Select"
                                value={sorter}
                                onChange={sorting}
                                autoWidth
                                varient="primary"
                                style={{ outlined:"none", height: "38px", width: "100%", padding: 0,backgroundColor:" #e0eff9" , color:"#1976d2" }}
                            >
  <Container>
                               <p align="center" style={{margin:5}} ><b>Student Type</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={activestudents} onChange={(e)=>changestudenttype(e,"Active")} />} label="Active" />
                                    <FormControlLabel control={<Switch checked={completestudents} onChange={(e)=>changestudenttype(e,"Completed")}/>} label="Completed" />
                                    <FormControlLabel control={<Switch checked={withdrawnstudents}  onChange={(e)=>changestudenttype(e,"Withdrawn")}/>} label="Withdrawn" />
                                    </FormGroup>
                                 
                                  

                               </Container>
                              
                               <Container>
                               <p align="center" style={{margin:5}} ><b>Fees Payment Type</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={debtors} onChange={(e)=>changefeetype(e,"debtors")} />} label="Debtors" />
                                    <FormControlLabel control={<Switch checked={complete} onChange={(e)=>changefeetype(e,"complete")}/>} label="Complete Payment" />
                                    </FormGroup>
                                 
                                    <p align="center" style={{margin:5}} ><b>Sort by class</b></p>
                                    <Divider/>
                               </Container>

                                <MenuItem value="none"><em>Sort By</em></MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                {Class_.length!==0?Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                ):""}


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg={2} xs={10} md={6}>
                        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color:"grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "120%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid item lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>

                </Grid>
             
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                      
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.id}</TableCell>

                                            <TableCell  component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="left"
                                            >

                                                <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+row.image} size="small" alt={row.lastname+" "+row.othernames} onClick={() => handleClickOpenimage(row)} style={{cursor:"pointer"}}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={row.lastname+" "+row.othernames}  />
                                                </ListItem>
                                                <div style={{display:"none"}}> 
                                                {exporter.push({Student_ID:row.id, Name:row.lastname+" "+ row.othernames, Class:row.class_, Gender:row.gender, Total_Fees_Due: "GHC "+ (row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null ? Intl.NumberFormat('en-US').format((parseFloat(row.finance[row.finance.length-1].totalfees) + parseFloat(row.finance[row.finance.length-1].Arrears)).toFixed(2)): Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].totalfees).toFixed(2)) : Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && Class_.filter(bug=>bug.classname===row.class_)[0].classfees).toFixed(2))), Amount_Paid: (row.finance.length !== 0 ? "GHC "+ Intl.NumberFormat('en-US').format((parseFloat(row.finance[row.finance.length-1].totalfees)-parseFloat(row.finance[row.finance.length-1].balance) + parseFloat(row.finance[row.finance.length-1].Arrears) ).toFixed(2)) : <span style={{padding:"6px", borderRadius:"20px", backgroundColor:"orange"}}>No data</span>), Balance:row.finance.length !== 0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].balance).toFixed(2)) : row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null ? "GHC "+ Intl.NumberFormat('en-US').format((parseFloat(row.finance[row.finance.length-1].totalfees) + parseFloat(row.finance[row.finance.length-1].Arrears)).toFixed(2)):"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].totalfees).toFixed(2)) :"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && Class_.filter(bug=>bug.classname===row.class_)[0].classfees).toFixed(2)) })}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">{row.status==="Active" ? <span style={{padding:8, borderRadius:"20px", backgroundColor:"#13A518", color:"white"}}>{row.status}</span>: row.status==="Completed"?  <span style={{padding:8, borderRadius:"20px", backgroundColor:"blue", color:"white"}}>{row.status}</span> : <span style={{padding:8, borderRadius:"20px", backgroundColor:"red", color:"white"}}>{row.status}</span>}</TableCell>

                                            <TableCell align="left">{row.class_}</TableCell>
                                       
                                            <TableCell align="left">{Class_.length!==0 && row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null && row.finance[row.finance.length-1].term === mainterm ? Intl.NumberFormat('en-US').format((parseFloat(totalamountpaid(row.finance[row.finance.length-1].payment)) + parseFloat(row.finance[row.finance.length-1].balance)).toFixed(2)): Intl.NumberFormat('en-US').format((parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees)+parseFloat(row.finance[row.finance.length-1].Arrears)+parseFloat(row.finance[row.finance.length-1].balance)).toFixed(2)) : Class_.length!==0 && Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && row !==null && row.length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees)).toFixed(2)))}</TableCell>
                                            <TableCell align="left">{row.finance.length !== 0 && row.finance[row.finance.length-1].term === mainterm ? "GHC "+ Intl.NumberFormat('en-US').format(totalamountpaid(row.finance[row.finance.length-1].payment)) : <span style={{padding:"6px", borderRadius:"20px", backgroundColor:"orange"}}>No data</span>}</TableCell>
                                            <TableCell align="left">{row.finance.length !== 0 && row.finance[row.finance.length-1].term === mainterm ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].balance).toFixed(2)) : row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null ? "GHC "+ Intl.NumberFormat('en-US').format((parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees) + parseFloat(row.finance[row.finance.length-1].balance)+ parseFloat(row.finance[row.finance.length-1].Arrears)).toFixed(2)):"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees).toFixed(2)) :"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && (Class_.filter(bug=>bug.classname===row.class_)[0].classfees)).toFixed(2))}</TableCell>
                                         
                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} admin={admin}  viewbill={(sender) => handleClickOpenviewbill(sender)} viewcharges={(sender) => handleClickOpenattendance(sender)}  academics={(sender) => handleClickOpensupply(sender)} message={(sender) => handleClickOpenmessage(sender)} editfees={(sender) => handleClickOpenfees(sender)} Arrears={(sender) => handleClickOpenmessagearrears(sender)}  discount={(sender) => handleClickOpenmessagediscount(sender)}  othercharge={(sender) => handleClickOpencharge(sender)}  dailyfee={(sender) => handleClickOpendailyfee(sender)}  weeklyfee={(sender) => handleClickOpenweeklyfee(sender)} monthlyfee={(sender) => handleClickOpenmonthlyfee(sender)}       addsupply={(sender) => handleClickOpensupply(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)}  />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>

                            {exportdata.length===0 && rows.length!==0 && setexportdata(exporter)}

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>


    );
}
