import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import Avatar from '@mui/material/Avatar';
import Printpage from './printbuttonall';

import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Atttable from './viewattendance';
import ReactToPrint from 'react-to-print';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import MessageIcon from '@mui/icons-material/Message';

import EmailIcon from '@mui/icons-material/Email';

import TextField from '@mui/material/TextField';
import Menustyle from './menuclass';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';

import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import Person from './images/Ellipse77.png';

import Tableacademic from './tableacc';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { loin, logout, updateschooldata } from './redux/schools';
import { BrowserRouter as Router, Routes, Route, Link, useLocation} from "react-router-dom";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { user } from './redux/admin';
import { getdata_ } from './redux/class';
import { updatestudentdata, getdata } from './redux/students';
import { schooldetails } from './redux/schooldetails';
import { updateteacherdata, getdatateacher } from './redux/teachers';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function createData(_id, classname, classteacher, subjects, attendance, timetable, classfees, othercharges, aggregate, position, billnotes,classteacher_id, billingdata, nextclass) {
    return {
        _id, classname, classteacher, subjects, attendance, timetable, classfees, othercharges, aggregate, position, billnotes,classteacher_id, billingdata, nextclass

    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [



    {
        id: 'classname',
        numeric: false,
        disablePadding: !true,
        label: 'Class',
    },

    {
        id: 'classteacher',
        numeric: false,
        disablePadding: !true,
        label: 'Class Teacher',
    },

    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'No. Students',
    },

    {
        id: 'classfees',
        numeric: !true,
        disablePadding: false,
        label: 'Class Fees',
    },
 

 
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                CLASSES
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

    const componentRef = useRef()


    const [opensnack, setOpensnack] = React.useState(false);
    const [preloading, setpreloading] = React.useState(true);
    const [editposition, seteditposition] = React.useState(true);
    const [editaggregate, seteditaggregate] = React.useState(true);    
    const [position, setposition] = React.useState(true);
    const [aggregate, setaggregate] = React.useState(true);
    const [subjecttype, setsubjecttype] = React.useState("none");
    const [subjecttypeedit, setsubjecttypeedit] = React.useState("none");
    const componentRefunsent = useRef()

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };



    const dispatch = useDispatch()
    const classer= useSelector((state) => state.Class_)
    const students = useSelector((state) => state.students)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    const admin = useSelector((state) => state.admin)
    const teacher = useSelector((state) => state.teachers)
    var schools  = admin.class_===undefined ? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)


    const gettingdatateachers = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
            {
                status:"Active",
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdatateacher({

                        data: res.data

                    }))


                }

            })

    }

 
      const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
                status:"Active",
                schoolID:admin.schoolID



            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }


    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {
                status:"Active",
                schoolID:admin.schoolID



            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }

    
    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                _id:admin._id,
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }


    useEffect(()=>{

    gettingdata()
    schooldata()
    gettingdata_()
    gettingdatateachers()

 },[preloading])

    const data = [schools.map((list, index) => createData(list._id, list.classname, list.classteacher, list.subjects, list.attendance, list.timetable,list.classfees, list.othercharges, list.aggregate, list.position, list.billnotes, list.classteacher_id, list.billingdata, list.nextclass))]

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    var rows = data[0]

    const [trialmode, settrialmode] = React.useState(!true);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [unsent, setunsent] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [emailsend, setemailsend] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const [openresults, setOpenresults] = React.useState(!true);
    const [openadd, setOpenadd] = React.useState(!true);
    const [openmigrate, setOpenmigrate] = React.useState(!true);
    const [billing, setbilling] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openclass, setOpenclass] = React.useState(!true);
    const [openviewatt, setOpenviewatt] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [smssend, setsmssend] = React.useState(!true);
    const [openmessage, setOpenmessage] = React.useState(!true);
    const [openclassreport, setOpenclassreport] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [openreport, setOpenreport] = React.useState(!true);
    const [openpreparereport, setOpenpreparereport] = React.useState(!true);
    const [openbulkreport, setOpenbulkreport] = React.useState(!true);
    const [openbill, setOpenbill] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openclassbill, setOpenclassbill] = React.useState(!true);
    const [openclassbillview, setOpenclassbillview] = React.useState(!true);
    const [opennextclass, setOpennextclass] = React.useState(!true);
    const [opencharge, setOpencharge] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [whattype, setwhattype] = React.useState("back")

    const [what, setwhat] = React.useState('back');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState([]);
    const [loading, setloading] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("")
    const [selectedreligion, setselectedreligion] = React.useState("")
    const [selectedteacher, setselectedteacher] = React.useState("")
    const [selectedteacheredit, setselectedteacheredit] = React.useState(chosen!==""?chosen.classteacher_id:"")
    const [person, setperson] = React.useState(Person)
    const [selectedterm, setselectedterm] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [subjectlist, setsubjectlist] = React.useState([])
    const [subjectlist2, setsubjectlist2] = React.useState([])
    const [chargeslist2, setchargeslist2] = React.useState([])
    const [totalbill, settotalbill] = React.useState(0)
    const [chargeslist, setchargeslist] = React.useState([])
    const [billnotes, setbillnotes] = React.useState([])
    const [opennotify, setOpennotify] = React.useState(!true)
    const [billnotes2, setbillnotes2] = React.useState([])
    const [emailloading, setemailloading] = React.useState("false")
    const [changeterm, setchangeterm] = React.useState("none");
    const [changeclass, setchangeclass] = React.useState("none");
    const [openunsent, setopenunsent] = React.useState(!true);
    const [selectedpromote, setselectedpromote] = React.useState("none")


    var Class_ = admin.class_===undefined? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)



    const searching = (e) => {
        setsearch(e.target.value)
    }

    if (search !== "") {
        var listed = rows.filter(bug => bug.classname.toLowerCase().includes(search.toLowerCase()) || bug.classteacher.toLowerCase().includes(search.toLowerCase()) )
        rows = listed
    }


    //actions
    const registerclass = (data) => {
        setloading(true)
        
        var classname = document.getElementById("classname").value
        var classfees = document.getElementById("classfees").value
        var classteacher_ = selectedteacher
        var selectedteacher_ = teacher.filter(bug=>bug._id===classteacher_)
        var classnamer=""
        
        console.log(classteacher_)
        console.log(selectedteacher_)

        if(selectedteacher_.length!==0){
        if(selectedteacher_[0].class_.length===0){
            classnamer=[classname]
        }else {
            if(selectedteacher_[0].class_[0].includes(classname)){
                classnamer=selectedteacher_[0].class_
            }else{
                classnamer=[selectedteacher_[0].class_[0]+","+classname]
            }
        }
    }


        const doer = async () => {
         try{
            await axios.post("https://servermain.sisrevolution.com/api/class/registerclass/",
                {

                    classname: classname,
                    teacherclass: classnamer,
                    classteacher: selectedteacher_.length!==0?selectedteacher_[0].lastname+" "+selectedteacher_[0].othernames :"",
                    classteacher_id:  selectedteacher_.length!==0?selectedteacher_[0]._id:"",
                    subjects: [subjectlist],
                    othercharges: [chargeslist],
                    billnotes: [billnotes],
                    classfees: classfees,
                    position: position,
                    aggregate: aggregate,        
                    schoolID:admin.schoolID

                   

                }).then(res => {
                    if (res.status === 200) {
                    gettingdata()

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have successfully added to class list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to class list")
                        setstated("error")

                    }

                })
            }catch(err){
                console.log(err)
            }

        }

        try{

        doer()
    }
    catch(err){
        console.log(err)
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }


    const changetermfunction=(e)=>{
        setchangeterm(e.target.value)
        }
    const changeclassfunction=(e)=>{
        setchangeclass(e.target.value)
        }
          

  
    const submitcharges = (data) => {
  
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/registerclass/",
                {
                    _id: chosen._id,
                    charges: [chargeslist],
                    billnotes: billnotes,
                    schoolID:admin.schoolID,
                    position: document.getElementById("positionadder").checked,
                    aggregate: document.getElementById("aggregateadder").checked,

        
                }).then(res => {
                
                    if (res.status === 200) {
                        dispatch(getdata_({
                        data: res.data
                    }))

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        }
try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }




   const editclass = (data) => {
    console.log(billnotes2)
        console.log(billnotes)

        setloading(true)
        var classname = document.getElementById("classnameedit").value
        var classfees = document.getElementById("classfeesedit").value
        var selectedteacher_ = teacher.filter(bug=>bug._id===selectedteacheredit)
        
        var classteacher_ = selectedteacheredit
        var classnamer=""
        if(selectedteacher.length!==0){
        if(selectedteacher[0].class_.length===0){
            classnamer=[classname]
        }else {
            if(selectedteacher[0].class_[0].includes(classname)){
                classnamer=selectedteacher[0].class_
            }else{
                classnamer=[selectedteacher[0].class_[0]+","+classname]
            }
        }
    }
        
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/editclass/",
                {
                    _id: chosen._id,
                    classname: classname,
                    classfees: classfees,
                    teacherclass: classnamer,
                    classteacher: selectedteacher_.length!==0?selectedteacher_[0].lastname+" "+selectedteacher_[0].othernames :"",
                    classteacher_id:  selectedteacher_.length!==0?selectedteacher_[0]._id:"",
                    aggregate: editaggregate,
                    position: editposition,
                    subjects: [subjectlist2],
                    othercharges: [chargeslist2],
                    billnotes: billnotes2,
                    schoolID:admin.schoolID

                                      
                }).then(res => {
                

                    if (res.status === 200) {
 dispatch(getdata_({

                        data: res.data

                    }))
                      
                        
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage( classname+ " successfully edited")
                        setstated("success")



                    } else {
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error editing class")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }


    
   const migratestudents = (data) => {
  

        setloading(true)
         
        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/migratestudents/",
                {
                    _id: chosen._id,
                    classname: chosen.classname,
                    allclass: rows,
                    schoolID:admin.schoolID

                                      
                }).then(res => {
                

                    if (res.status === 200) {
                        dispatch(getdata({

                            data: res.data
    
                        }))
                      
                        
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Students Migrated to next class successfully")
                        setstated("success")



                    } else {
                        console.log(res)
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error migrating students")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }





const addsubjectlist=()=>{
    var subject = document.getElementById("subject1").value
  

    var topics = document.getElementById("topics").value
    var conbinetopic = topics.split(",")
    // var classscore = document.getElementById("classscoreedit").value
    // var examscore = document.getElementById("examscoreedit").value
  
    
   var news = {subject:subject, classscore:50, examscore:50, type:subjecttype, topics:conbinetopic}
    // var news = {subject:subject, classscore:50, examscore:50, type:subjecttype}
    setsubjectlist([...subjectlist,news])

} 


const removesubjectlist=(data)=>{

    var news = subjectlist.filter(bug=> bug.subject !== data)
    setsubjectlist(news)

}


const addchargeslist=()=>{
    var description = document.getElementById("description").value
    var amount = document.getElementById("amount").value
    settotalbill(parseFloat(amount)+totalbill)

    var news = {description:description, amount:amount, id:new Date()}
    setchargeslist([...chargeslist,news])

} 


const removechargeslist=(data)=>{

    var news = chargeslist.filter(bug=> bug.id !== data)
    var info = chargeslist.filter(bug=> bug.id === data)[0]
    settotalbill(parseFloat(totalbill)-parseFloat(info.amount))

    setchargeslist(news)

}



const addbillnotes=()=>{
    var billnotes_ = document.getElementById("billnotes").value


    var news = {billnotes:billnotes_, id:new Date()}
    setbillnotes([...billnotes,news])

} 

const addnewbill=()=>{

    setbilling(true)


    var billdata={term:changeterm, bill:chargeslist, billnotes:billnotes, id:dt}             

    const uploading = async() => {
        await axios.post("https://servermain.sisrevolution.com/api/class/newbill/", {
         

        schoolID : admin.schoolID,
        class_ : chosen.classname,
        _id : chosen._id,
        billnotes : billnotes,
        charges : chargeslist,
        billingdata : billdata,
        totalbill : totalbill


        }).then(res => {

            if (res.status === 200) {

                dispatch(getdata_({

                    data: res.data

                }))
               
        handleCloseclassbill()
        handleClicksnack()
        setmessage("Bill added to class")
        setstated("success")
        setbilling(!true)

            }
            if (res.status === 204) {
                handleCloseadd()
        handleClicksnack()
        setmessage("Error adding bill")
        setstated("error")
        setbilling(!true)
            }

        })

    }


    try{
        uploading()
    }catch(err){
        setbilling(!true)

        handleClicksnack()
        setmessage("Network Error")
        setstated("error")
    }


}



const addnextclass=()=>{

    setbilling(true)
             

    const uploading = async() => {
        await axios.post("https://servermain.sisrevolution.com/api/class/addnextclass/", {
         

        schoolID : admin.schoolID,
        class_ : chosen.classname,
        _id : chosen._id,
        nextclass : changeclass,
       


        }).then(res => {

            if (res.status === 200) {

                dispatch(getdata_({

                    data: res.data

                }))
               
        handleCloseclassbill()
        handleClicksnack()
        setmessage("next class set for "+chosen.classname)
        setstated("success")
        setbilling(!true)

            }
            if (res.status === 204) {
                handleCloseadd()
        handleClicksnack()
        setmessage("Error setting class")
        setstated("error")
        setbilling(!true)
            }

        })

    }


    try{
        uploading()
    }catch(err){
        setbilling(!true)

        handleClicksnack()
        setmessage("Network Error")
        setstated("error")
    }


}


const removebillnotes=(data)=>{

    var news = billnotes.filter(bug=> bug.id !== data)
    setbillnotes(news)

}

const billnotesedit=()=>{
    var billnotes2_ = document.getElementById("billnotesedit").value


    var news = {billnotes:billnotes2_, id:new Date()}
    setbillnotes2([...billnotes2,news])

} 


const removebillnotesedit=(data)=>{

    var news = billnotes2.filter(bug=> bug.id !== data)
    setbillnotes2(news)

}



const addsubjectlistedit=()=>{
    var subject = document.getElementById("subjectedit").value
    var topics = document.getElementById("topicsedit").value
    var conbinetopic = topics.split(",")
    // var classscore = document.getElementById("classscoreedit").value
    // var examscore = document.getElementById("examscoreedit").value
  
    
   var news = {subject:subject, classscore:50, examscore:50, type:subjecttypeedit, topics:conbinetopic}
    setsubjectlist2([...subjectlist2,news])
    
} 


const addchargeslistedit=()=>{
    var description = document.getElementById("descriptionedit").value
    var amount = document.getElementById("amountedit").value
  
    
   var news = {description:description, amount:amount, id:new Date()}
    setchargeslist2([...chargeslist2,news])
    
} 


const removesubjectlistedit=(data)=>{

    var news = subjectlist2.filter(bug=> bug.subject !== data)
    setsubjectlist2(news)
}



const removechargeslistedit=(data)=>{

    var news = chargeslist2.filter(bug=> bug.id !== data)
    setchargeslist2(news)
}




 const classbulksms = () => {
                   setemailloading(true)

                    const fd = new FormData()
                 
                    fd.append("schoolID",admin.schoolID)
                    fd.append("class_", chosen.classname)
                    fd.append("smsID", schooldetails_[0].smsID)
                    fd.append("sender", schooldetails_[0].schoolname)
                    fd.append("image", schooldetails_[0].image)
                    fd.append("smsname", schooldetails_[0].smsname)
                    fd.append("message", document.getElementById("smsdata").value)
                   
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/classbulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Bulk SMS sent")
                        setstated("success")
                                setemailloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Bulk SMS")
                        setstated("error")
                                setemailloading(!true)
                            }
                            if(res.data.length!==0){
                                setunsent(res.data)
                                setopenunsent(true)
        
                            }

                        })

                    }

try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setemailloading(!true)
                }



                }

    const sendemail = () => {
                   setemailloading(true)

               
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("schoolname",admin.schoolname)
                    fd.append("contact",admin.contact)
                    fd.append("address",admin.address)
                    fd.append("class_", chosen.classname)
                    fd.append("message", document.getElementById("emaildata").value)
                    fd.append("subject", document.getElementById("subject").value)
                   
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulkemail/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Email sent")
                        setstated("success")
                   setemailloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Email")
                        setstated("success")
                   setemailloading(!true)
                            }

                        })

                    }

try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setemailloading(!true)
                }


                }

  const addtolist = (data) => {
        setloading(true)
        console.log(data)
        var quantity = document.getElementById("Quantity").value
        var SRV = document.getElementById("SRVrec").value
        var By = document.getElementById("supplier").value
        var term = age
        var done = [...data.data, { date: date, sem: term, quantity: quantity, SRVrec: SRV, SRVsent: 0, SRVcon: 0, SRVpaid: 0, suppliedby: By }]

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/updateschooldata/",
                {
                    id: data.id,
                    data: done,
                    schoolID:admin.schoolID




                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")



                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }

    const changeedit = (changer, e) => {
        changer(e.target.value)
        console.log(e.target.value)
    }

    const editschool = (data) => {
        setloading(true)
          var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/editschool/",
                {
                    id: chosen.id,
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("data has been edited")
                        setstated("sucess")

                    } else {
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error editting data")
                        setstated("error")
                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }

    const addschool = (data) => {
        setloading(true)
         var classname = document.getElementById("classnameedit").value
        var classteacher_ = selectedteacheredit
      
        

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")
                    }

                })

        }

        try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }


    const deleter = (data) => {

        setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/deleteclass/",
                {
                    _id: chosen._id,
                    schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                      
                        dispatch(getdata_({
                        data: res.data
                    }))
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }
try{
        doer()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setloading(!true)
    }

    }

    const handleClickOpenedit = (data) => {
      
        console.log("data")
        console.log(data)
        setchosen(data)
       setsubjectlist2(data.subjects[0])
       seteditposition(data.position)
       setselectedteacheredit(data.classteacher_id)
       seteditaggregate(data.aggregate)
       setchargeslist2(data.othercharges !==undefined? data.othercharges[0] :[])
       setbillnotes2(data.billnotes !==undefined? data.billnotes :[])
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimage(true);
    };

    const handleCloseimage = () => {
        setOpenimage(false);
    };

    
    const handleClickOpenclassbill = (data) => {
        console.log(data)
        setchosen(data)
        setOpenclassbill(true);
    };

    const handleCloseclassbill = () => {
        setOpenclassbill(false);
    };

    const handleClickOpennextclass = (data) => {
        console.log(data)
        setchosen(data)
        setOpennextclass(true);
    };

    const handleClosenextclass = () => {
        setOpennextclass(false);
    };
   
    const handleClickOpenclassbillview = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        settotalbill(0)
        setchargeslist([])
        setbillnotes([])
        setOpenclassbillview(true);
        }
    };

    const handleCloseclassbillview = () => {
        setOpenclassbillview(false);
    };

    
    const handleClickOpenreport = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenreport(true);
        }
    };

    const handleClosereport = () => {
        setOpenreport(false);
    };   


    const handleClickOpenpreparereport = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenpreparereport(true);
        }
    };

    const handleClosepreparereport = () => {
        setOpenpreparereport(false);
    }; 
     
    const handleClickOpensendbulkreport = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenbulkreport(true);
        }
    };

    const handleClosebulkreport = () => {
        setOpenbulkreport(false);
    }; 
    
    const handleClickOpenbill = (data) => {
        console.log(data)
        setchosen(data)
        setOpenbill(true);
    };

    const handleClosebill = () => {
        setOpenbill(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };
    
    
    const handleClickOpencharge = (data) => {
        console.log(data)
        setchosen(data)
        setOpencharge(true);
    };

    const handleClosecharge = () => {
        setOpencharge(false);
    };

    const handleClickOpenadd = (data) => {
        
        setOpenadd(true);
    };

    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpenmigrate = (data) => {
        
        setOpenmigrate(true);
    };

    const handleClosemigrate = () => {
        setOpenmigrate(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };
    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        
        setOpenview(true);
    };
    const handleCloseview = () => {
        setOpenview(false);
    };

    const handleClickOpenclass = (data) => {
        setchosen(data)
        setOpenclass(true);
    };
    const handleCloseclass = () => {
        setOpenclass(false);
    };


    const closeunsent = () => {
        setopenunsent(false);
    };


    const handleClickOpenviewatt = (data) => {
        console.log(data)
        setchosen(data)
        
        setOpenviewatt(true);
    };
    const handleCloseviewatt = () => {
        setOpenviewatt(false);
    };


    const handleClickOpenmessage = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenmessage(true);
        }
    };
    const handleClosemessage = () => {
        setOpenmessage(false);
    };

    const handleClickOpenclassreport = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log("class data")
        console.log(data)
        setchosen(data)
        setOpenclassreport(true);
        }
    };
    const handleCloseclassreport = () => {
        setOpenclassreport(false);
    };



    const handleClickOpensupply = (data) => {
        console.log(data)
        setchosen(data)
        setOpensupply(true);
    };
    const handleClosesupply = () => {
        setOpensupply(false);
    };

    const totalsupplied = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].quantity
        }


        return total
    }

    const srvrec = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].SRVrec
        }


        return total
    }



    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }


    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
  
    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }
  const handleChange_teacher = (e) => {
    console.log(e.target.value)
        setselectedteacher(e.target.value)
    }
 const handleChange_teacheredit = (e) => {
    console.log(e.target.value)
        setselectedteacheredit(e.target.value)
    }


    const sorting = (e) => {

        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };   const handleChangeterm = (e) => {
        setselectedterm(e.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


        const setback=(data)=>{
            setwhat(data)
        }

        const sendmessage = () =>{
        
                    return(
        
        
        
        <div style={{textAlign:"center", margin:8}}>
        <p style={{textAlign:"center", margin:12}}>
                                Send a message to Guardians of all the students in this class 
                            </p>
                    
                   <Button onClick={()=>setback("email")} aria-label="fingerprint" style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <EmailIcon style={{fontSize:"20px"}} />}>
                <small> Email</small>
              </Button>
           <Button onClick={()=>setback("sms")} aria-label="fingerprint" style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <MessageIcon style={{fontSize:"20px"}} />}>
                 <small> Text Message</small>
              </Button>
                   
                        
        </div>
        
                    )
                }
        
        const viaSMS = ()=>{
                    
                    return(
                        <div style={{textAlign:"left", marginTop:10}}>
                        <Button onClick={()=>setback("back")} style={{margin:2}}>
                        <KeyboardBackspaceIcon />
                        </Button>
                        <div style={{textAlign:"center"}}>
                        <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
        <Divider/>
        <br/>
        <br/>
                        <textarea id="smsdata" rows="5" defaultValue=""placeholder='Message' style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
        <br/>
        {emailloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => classbulksms()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Send
                                </Button>
                            }
                        </div>
                        </div>
        
        
                 
                       
        
        
                    )
        
                }
            
        const viaEmail = ()=>{
                    
                    return(
                        <div style={{textAlign:"left", marginTop:10}}>
                        <Button onClick={()=>setback("back")} style={{margin:2}}>
                        <KeyboardBackspaceIcon />
                        </Button>
                        <div style={{textAlign:"center"}}>
                        <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
        <Divider/>
        <br/>
        <br/>
                        <textarea id="subject" rows="1" defaultValue="" placeholder='Subject' style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} /><br></br>    
                        <textarea id="emaildata" rows="5" defaultValue="" placeholder='Message' style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
       <br/>
        {emailloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendemail()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Send
                                </Button>
                            }
                        </div>
                        </div>
        
        
                    )
        
                }
        
        const viaEmailandtext = ()=>{
                    
                    return(
                        <div style={{textAlign:"left", marginTop:10}}>
                        <Button style={{margin:2}}>
                        <KeyboardBackspaceIcon />
                        </Button>
                        <div style={{textAlign:"center"}}>
                        <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email and SMS</p>
        <Divider/>
        <br/>
        <br/>
                        <textarea rows="5" defaultValue="" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
        
                        </div>
                        </div>
        
        
                    )
        
                }
        
        const messenger =(type)=>{
            if(type==="email"){
                return(
                    viaEmail()
                )
            }
            if(type==="sms"){
                return(
                    viaSMS()
                )
            }  if(type==="back"){
                return(
                    sendmessage()
                )
            }
        }

           
    const settrialmode_=()=>{
        settrialmode(false)
    }


    const chooser=(list)=>{
        var del=0
if(schooldetails_[0].terms!==undefined && schooldetails_[0].terms.length!==0 && schooldetails_[0].terms[schooldetails_[0].terms.length-1].split('Term')[0] !=="3rd"){
        if(chosen.length !==0 && chosen.othercharges.length!==0){
            for(var i in chosen.othercharges[0]){
                del+=parseFloat(chosen.othercharges[0][i].amount)
            }

        }
    }else{
        if(list.nextclass!=="" && list.nextclass!==undefined && rows.filter(bug=>bug.classname===list.nextclass)[0].length !==0 && rows.filter(bug=>bug.classname===list.nextclass)[0].othercharges.length!==0){
            for(var k in rows.filter(bug=>bug.classname===list.nextclass)[0].othercharges[0]){
                del+=parseFloat(rows.filter(bug=>bug.classname===list.nextclass)[0].othercharges[0][k].amount)
            }

        }
    }
        return del

    }





    const top100Films = [
        { title: 'English Language', year: 1994 },
        { title: 'Mathematics', year: 1994 },
        { title: 'Integrated Sceince', year: 1994 },
        { title: 'Sceince', year: 1994 },
        { title: 'Social Studies', year: 1994 },
        { title: 'Basic Technology and Design', year: 1994 },
        { title: 'Ghanaian Language & Culture', year: 1994 },
        { title: 'French', year: 1994 },
        { title: 'I.C.T.', year: 1994 },
        { title: 'Information Communication Technology', year: 1994 },
        { title: 'Religious and Moral Education', year: 1994 },
        { title: 'P.E', year: 1994 },
        { title: 'Physical Education', year: 1994 },
        { title: 'Art', year: 1994 },
        { title: 'Art Education', year: 1994 },
        { title: 'Character and Citizenship Education', year: 1994 },
        { title: 'History', year: 1994 },
        { title: 'Our World Our People', year: 1994 },
        { title: 'Creative Arts', year: 1994 },
        
        
      ];


      const handleChangepromote = (e) => {
        console.log(e.target.value)
        setselectedpromote(e.target.value);
    };


      const sendviatext = () => {
        
        // var data = list.academics.filter(bug => bug.term === selectedterm)
        setsmssend(true)
    
      
var chosenterm = schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none"
const senddatatext = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/students/sendbulkresultstext/",
                {
                    class_:chosen.classname,
                    term:chosenterm,
                    schoolname: schooldetails_[0].schoolname,
                    smsname: schooldetails_[0].smsname,
                    smsID: schooldetails_[0].smsID,
                    reopening: schooldetails_[0].reopendate,
                    contact: schooldetails_[0].contact,
                    address: schooldetails_[0].address,
                    email: schooldetails_[0].email,
                    schoolID:schooldetails_[0].schoolID,
                    position:chosen.position,
                    aggregate:chosen.aggregate

                }).then(res => {



                    if (res.status === 200) {

                            setsmssend(false)
                            handleClosebulkreport()
                            handleClicksnack()
                            setmessage("Results sent to parent")
                            setstated("success")


                    }else{
                        setsmssend(false)
                        handleClicksnack()
                        setmessage("Error sending results")
                        setstated("error")
                    }

                    if(res.data.length!==0){
                        setunsent(res.data)
                        setopenunsent(true)

                    }

                })
            }catch{
                
                setsmssend(false)
                setmessage("Network Error")
                setstated("error")
                setOpensnack(true)

            }
        }

        if (chosenterm !== "none" ) {
           
            senddatatext()
            
        }else{
                
            setsmssend(false)
            setmessage("Select Term")
            setstated("error")
            setOpensnack(true)

        }

    }


    const sendviaemail = () => {
        setemailsend(true)
        // var data = list.academics.filter(bug => bug.term === selectedterm)

var chosenterm = schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none"

        const senddatatext = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/students/sendbulkresultsemail/",
                {
                    class_:chosen.classname,
                    term:chosenterm,
                    schoolname: schooldetails_[0].schoolname,
                    reopening: schooldetails_[0].reopendate,
                    contact: schooldetails_[0].contact,
                    address: schooldetails_[0].address,
                    email: schooldetails_[0].email,
                    schoolID:admin.schoolID,
                    position:chosen.position,
                    aggregate:chosen.aggregate




                }).then(res => {

                    if (res.status === 200) {
                        setemailsend(!true)
                        handleClosebulkreport()
                        handleClicksnack()
                        setmessage("Results sent to parent")
                        setstated("success")

                    }else{
                        handleClicksnack()
                        setmessage("Error sending report")
                        setstated("error")
                        setemailsend(!true)

                    }
                  

                })
            }catch{
                setemailsend(!true)
                setmessage("Error sending report")
                setstated("error")
                setOpensnack(true)

            }

        }

        if (chosenterm !== "none" ) {
           
            senddatatext()
          
        }

    }


    const records =(recordtype)=>{
        if(recordtype==="edit"){
            return(
                editrecord()
            )
        }   
        if(recordtype==="reopen"){
            return(
                mainrecord()
            )
        }
         if(recordtype==="back"){
            return(
                mainrecord()
            )
        }
            }
    

    const editacademic =(data)=>{

                setwhattype(data)
                // setreopendate(chosen.reopendate)
        
            }

            const handleCloseresults = () => {
                setOpenresults(false);
            };
        
            const handlesendresults = () => {
                setOpenresults(true);
            };

            
    const handlenotify = (data) => {
    
        setOpennotify(true);
    };

    const handleClosenotify = () => {
        setOpennotify(false);
    };
    
      
            const grading = (score) => {
     
                if(schooldetails_[0].grades.length===0){
              
                  handleClicksnack()
                  setmessage("setup your grades at settings")
                  setstated("error")
              
                }else{
              
              
                  var value_= schooldetails_[0].grades.filter(bug=>bug.upperlimit>=score && bug.lowerlimit<=score)
                 
                  if(value_.length===0){
                  return "F"
                  }else{
                      return value_[0].gradepoint
                  }
                }
              
                  }
              
              
              
                  const remarks = (grade) => {
                      if(schooldetails_[0].grades.length===0){
              
                          handleClicksnack()
                          setmessage("setup your grades at settings")
                          setstated("error")
                      
                        }else{
                      
                      var value_= schooldetails_[0].grades.filter(bug=>bug.upperlimit>=grade && bug.lowerlimit<=grade)
                      if(value_.length===0){
                          return "Fail"
                          }else{
                              return value_[0].remarks
                          }
                   
                        }
                  }
                  



    const mainrecord =()=>{
        var data=[]
        if(selectedterm !== "none" && chosen.length!==0){
        // data=chosen.academic.filter(bug=> bug.term === selectedterm)

        }
return(
<div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
            
                    <FormControl style={{ marginLeft:45, marginRight:5, backgroundColor:" #e0eff9" , color:"#1976d2" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id=""
                        value={selectedterm}
                        style={{ minWidth: 200, padding: 0, margin: 0 }}
                        onChange={(e)=>handleChangeterm(e)}
                        autoWidth
                        label="Term"
                        size="small"
                    >
                        <MenuItem value="none">
                            <em>Choose Term</em>
                        </MenuItem>
                        {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                        schooldetails_[0].terms.map((list,index)=>
                        <MenuItem value={list}>{list}</MenuItem>

                        ) :""   
                    }
                      
                    </Select>
                </FormControl>
                <Button onClick={()=>editacademic("edit")} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                Add / Edit Results
                </Button>

                <Button onClick={handleClosesupply} style={{float:"right"}}> close </Button>
  
                <Button  onClick={handlesendresults} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send to Guardian</Button>

                <Button  onClick={()=>editacademic("reopen")}  style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                    set re-opening date
                </Button>

            </DialogTitle>

            <DialogContent dividers style={{ textAlign: "left" }}>

                {/* <Printpage data={chosen} term={selectedterm} Class_={Class_} students={rows} schooldata={schooldetails_}/> */}

            </DialogContent>

</div> 
)

    }


const positions = (term, data, id,classname) => {
var list = []
var list2 = [data]
var position = "None"
var arrange = []
var value = 0
for(var i in rows){
    if(rows[i]._id !== id){
    if(rows[i].academic.length !==0){

    for(var k in rows[i].academic){
        if(rows[i].academic[k].term===term && rows[i].class_===classname){
           list.push(rows[i].academic[k].totalscore) 
        }
    }
    }
}

}

for(var i in list){
    if (list2.includes(list[i])){
    console.log("includes")
    }else(
        list2.push(list[i])
    )
    
}

list2= list2.sort((a,b)=>b-a)

for(var i in list2){
    var sum = parseInt(i)+1
     if((sum).toString()[(sum).toString().length-1] === "1"){
    arrange.push({score:list2[i], position:(sum).toString()+"st"})

} else if((sum).toString()[(sum).toString().length-1] === "2"){
     arrange.push({score:list2[i], position:(sum).toString()+"nd"})
}
else if((sum).toString()[(sum).toString().length-1] === "3"){
    arrange.push({score:list2[i], position:(sum).toString()+"rd"})

}else{
    arrange.push({score:list2[i], position:(sum).toString()+"th"})
}
}


const doposition = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/arrangepositions/",
            {
                class_: chosen.class_,
                term: term,
                data: arrange,
                schoolID:admin.schoolID
      
            }).then(res => {

                if (res.status === 200) {

                   
                    
                    // setloading(!true)
                    // handleClosesupply()
                    // handleClicksnack()
                    // setmessage("You have successfully added to supply list")
                    // setstated("success")
                   

                    
                } else {
                    // setloading(!true)
                    // handleClosesupply()
                    // handleClicksnack()
                    // setmessage("Error adding to supply list")
                    // setstated("error")

                }

            })

    }

    doposition()




value = list2.findIndex(bug=>bug===data) + 1
console.log(value.toString()[value.toString().length-1])
if(value.toString()[value.toString().length-1] === "1"){
    position=value.toString()+"st"

} else if(value.toString()[value.toString().length-1] === "2"){
    position=value.toString()+"nd"

}
else if(value.toString()[value.toString().length-1] === "3"){
    position=value.toString()+"rd"

}else{
    position=value.toString()+"th"
}



return position

    }




    const editrecord =()=>{

        var subjected = Class_.filter(bug=>bug.classname === chosen.class_)
        var subjected = chosen.subjects
        // console.log(subjected)
        // var number = subjected[0].subjects[0]
        var number = []
        var subject = ''
        var student_list=[]
        var results = []
        var conclusion = []
        var totalscore=0
        var aggregatecalc=[]
        var aggregate=0
        var elective=0
        var core=0
        //var promotion = document.getElementById('nextclass').value
        var promotion = selectedpromote
        
        if(promotion === "none"){
             promotion=chosen.class_
        }

        const submitscore=()=>{
            var interest = ""
            var conduct =  ""
            var teacherremarks =  ""
            var headremarks =  ""

            setloading(true)
if(schooldetails_[0].grades.length!==0){
            for(var i in number){
                var classscore = document.getElementById('class'+subjected[0].subjects[0][i].subject+i).value
                var examscore =  document.getElementById('exams'+subjected[0].subjects[0][i].subject+i).value
                
                if(classscore===""){
                    classscore = 0
                }  
                if(examscore===""){
                    examscore = 0
                }
                
               var total =(parseInt(classscore)+parseInt(examscore))
               totalscore += total
               aggregatecalc.push([grading(total),subjected[0].subjects[0][i].type])
               results.push({subject: subjected[0].subjects[0][i].subject, class_scorre: classscore, exam_score:examscore, total:total , grade:grading(total), remarks:remarks(total) })
                
            }
           
            for(var i in aggregatecalc){
                
            if(aggregatecalc[i][1]==="Core" && core < 5){     
                aggregate += parseInt(aggregatecalc[i])
                elective+=elective+1
            }
            else if(aggregatecalc[i][1]==="Elective" && elective < 2){
                aggregate += parseInt(aggregatecalc[i])
                core+=core+1
            }
        }

conclusion = {term:selectedterm, position:positions(selectedterm, totalscore, chosen._id, chosen.class_ ), results:results, totalscore:totalscore, promotion:promotion, interest:interest, conduct:conduct, teacherremarks:teacherremarks, headremarks:headremarks,  aggregate:aggregate}

const doerresult = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/addresults/",
            {
                id: chosen._id,
                data: conclusion,
                term: selectedterm,
                class_: promotion,
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                   gettingdata()
                    
                    setloading(!true)
                    handleClosesupply()
                    handleClicksnack()
                    setmessage("You have successfully added academic records")
                    setstated("success")
                   

                    
                } else {
                    setloading(!true)
                    handleClosesupply()
                    handleClicksnack()
                    setmessage("Error adding academic records")
                    setstated("error")

                }

            })

    }

    try{
    doerresult()
    }catch(err){
        setloading(!true)
        handleClicksnack()

                    setmessage("Network Error")
                    setstated("error")
    }

}else{
    handlenotify()
}
        }



        const submitscoremain=()=>{

            var datalist = []
            var subjects = chosen.subjects[0]
           
            var type = subjects.filter(bug=>bug.subject===selectedpromote)
            
            var students_ = students.filter(bug=>bug.class_===chosen.classname)

            for (var i in students_){
            var classscore = document.getElementById("class" + students_[i].ID).value
            var examsscore = document.getElementById("exams" + students_[i].ID).value
            var subject = selectedpromote
            var studentID = students_[i].ID

            datalist = [...datalist, {classscore:classscore, examsscore:examsscore, subject:subject, studentID:studentID}]
            }



            const doerresult = async () => {
                // await axios.post("https://servermain.sisrevolution.com/api/students/bulkreport/",
                await axios.post("https://servermain.sisrevolution.com/api/students/bulkreport",
                    {
                        data: datalist,
                        term: selectedterm,
                        class_: chosen.classname,
                        schoolID:admin.schoolID,
                        subjects:subjects,
                        type:type.length!==0?type[0].type:"none",
                        grades: schooldetails_[0].grades,
        
                    }).then(res => {
        
                        if (res.status === 200) {
        
                           gettingdata()
                            
                            setloading(!true)
                            handleClosesupply()
                            handleClicksnack()
                            setmessage("You have successfully added academic records")
                            setstated("success")
                           
        
                            
                        } else {
                            setloading(!true)
                            handleClosesupply()
                            handleClicksnack()
                            setmessage("Error adding academic records")
                            setstated("error")
        
                        }
        
                    })
        
            }
        
            try{
            doerresult()
            }catch(err){
                setloading(!true)
                handleClicksnack()
        
                            setmessage("Network Error")
                            setstated("error")
            }

        }
    



return(
<div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
<Button style={{margin:2}} onClick={()=>editacademic("back")}>
            <KeyboardBackspaceIcon />
            </Button>
               Add / Edit Academic records

                 <FormControl style={{ marginLeft:10, marginRight:0 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id=""
                        value={selectedterm}
                        style={{ minWidth: 200, padding: 0, margin: 0 }}
                        onChange={(e)=>handleChangeterm(e)}
                        autoWidth
                        label="Term"
                        size="small"
                    >
                        <MenuItem value="none">
                            <em>Choose Term</em>
                        </MenuItem>
                        {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                        schooldetails_[0].terms.map((list,index)=>
                        <MenuItem value={list}>{list}</MenuItem>

                        ) :""   
                    }
                    </Select>
                </FormControl>
              
                <Button onClick={handleClosesupply} style={{float:"right"}}>
                close
                </Button>
            </DialogTitle>

            <DialogContent dividers style={{ textAlign: "left" }}>
            
                
           <FormControl style={{ marginLeft:0, marginRight:0, marginTop:10, marginBottom:10 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Choose Subject</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id=""
                        value={selectedpromote}
                        style={{ minWidth: 200, padding: 0, margin: 0 }}
                        onChange={(e)=>handleChangepromote(e)}
                        autoWidth
                        label="Choose Subject"
                        size="small"
                    >
                        <MenuItem value="none">
                            <em>Choose Subject</em>
                        </MenuItem>   

                        {Class_ && Class_[0].subjects[0].map((row, index)=>
                        <MenuItem value={row.subject}>{row.subject}</MenuItem>

                        )}
                     

                    </Select>
                </FormControl>
               
                <TableContainer component={Paper}>
  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
    <TableHead>
      <TableRow>
                  <TableCell><b>Students</b></TableCell>
                  <TableCell align="left"><b>CLASS SCORE (50%)</b></TableCell>
                  <TableCell align="left"> <b>EXAM SCORE (50%)</b></TableCell>
                  

      </TableRow>
    </TableHead>


    <TableBody>
     { students.length!==0? students.filter(bug=>bug.class_===chosen.classname).map((row, index) => (
        <TableRow
             
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
         
              <TableCell align="left">{row.lastname && row.lastname.toUpperCase() +" "+ (row.othernames && row.othernames.toUpperCase())}</TableCell>
              <TableCell align="left">  <input  id={'class'+row.ID} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
              <TableCell align="left">  <input id={'exams'+row.ID}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>

        </TableRow>
      ))
      :
     ""
   
      }
    </TableBody>

  </Table>

</TableContainer>
<br/>
{loading ===true?
<div align="center"> 
                           <LoadingButton
    loading
    loadingPosition="start"
    startIcon={<SaveIcon />}
    variant="outlined"
  >
    Loading...
  </LoadingButton>
  </div>:
                      
                      <div align="center">   <Button autoFocus onClick={() => submitscoremain()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                Submit
                            </Button></div>
                        }


            </DialogContent>



</div> 
)
    }  













    return (

        <Box sx={{ width: '100%' }}>



            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            
            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>




                 

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="lg" onClose={handleCloseclassreport} aria-labelledby="customized-dialog-title" open={openclassreport}>
                
                {records(whattype)}
    
                   
                </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessage} aria-labelledby="customized-dialog-title" open={openmessage}>

                 <DialogTitle id="customized-dialog-title" onClose={handleClosemessage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Messages
                    <IconButton onClick={handleClosemessage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    


{messenger(what)}


               
                <DialogActions>

                  
                   
                </DialogActions>
            </Dialog>




            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
  <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Delete
                    <IconButton onClick={handleClosedelete} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={() => deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                            <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
                    }


                </DialogActions>
            </Dialog>





            <Dialog fullWidth maxWidth="lg" onClose={closeunsent} aria-labelledby="customized-dialog-title" open={openunsent}>
 <DialogTitle id="customized-dialog-title" onClose={closeunsent}  style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Print Unsent SMS List
                    <IconButton onClick={closeunsent}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Unsent List</Button>}
            content ={()=>componentRefunsent.current}
            />

<div ref={componentRefunsent} >
<div style={{textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h3 style={{margin:4}}> Unsent SMS List </h3>
    <p style={{color:"red"}}>The list below did not received the SMS sent to all students, please make sure the guardian's contacts are intact and resend the SMS individually</p>
</div>


              {unsent.length!==0 &&
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>No. </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Student Name </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Name</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Contact</TableCell>
                    

          </TableRow>
        </TableHead>
        <TableBody>
          {unsent.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.class_}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardianlastname+" "+row.guardianothername}
              </TableCell> 
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardiancontact}
              </TableCell>

          </TableRow>))} 
        </TableBody>
      </Table>
    </TableContainer>
}


</div>          

                </DialogContent>
                <DialogActions>




                 

                  
                </DialogActions>
            </Dialog>





            <Dialog maxWidth="lg" onClose={handleClosecharge} aria-labelledby="customized-dialog-title" open={opencharge}>
  <DialogTitle id="customized-dialog-title" onClose={handleClosecharge} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Schoolfees description / Other Charges
                    <IconButton onClick={handleClosecharge} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                {/* <p align="center"> <b>Add Subjects and overall scores</b></p> */}
                    
                       
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center"></TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow
                              key="main"
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                             
                              <TableCell align="right"> <TextField id="description" type="text"   size="small"  label="Description" variant="outlined" />
                   </TableCell>
                              <TableCell align="right"> <TextField id="amount" type="number"  size="small" label="Amount" variant="outlined" InputProps={{


startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
}} />
                </TableCell>
                             
                              <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addchargeslist()} color="success"> Add Charge
                      </Button> </TableCell>
                            </TableRow>
                            
                          {chargeslist.length !==0? chargeslist.map((row) => (
                            <TableRow
                              key={row.description}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center">{row.description}</TableCell>
                              <TableCell align="center">GHC {row.amount}</TableCell>
                              <TableCell align="center"><DeleteIcon  onClick={()=>removechargeslist(row.id)} /> </TableCell>
                            </TableRow>
                          )):""}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                

                </DialogContent>
                <DialogActions>

                {loading===true?
    <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                     <Button autoFocus onClick={() => submitcharges()} color="primary">
                                    Submit
                                </Button>}


                </DialogActions>
            </Dialog>


            <Dialog fullWidth width="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
              <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Staff Information
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{textAlign:"center", width:"100%"}}>
                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />
                    </div>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary="Name here" secondary="Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Contact" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Email" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Residence Address" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Gender" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Date of Birth" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Religion" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Status" />
                        </ListItem>
                        <Divider />



                    </List>

                    <br />

              
                </DialogContent>
              
            </Dialog>


            <Dialog fullWidth width="sm" onClose={handleCloseviewatt} aria-labelledby="customized-dialog-title" open={openviewatt}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseviewatt} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Class Attendance
                    <IconButton onClick={handleCloseviewatt} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers align="center">
                   
                   
                    <Atttable  data={chosen} />


                </DialogContent>

            </Dialog>


            <Dialog fullWidth maxWidth="" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
               
               
                <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }} >
                    Class Academic Records
                    <FormControl style={{ margin: 0, marginLeft:40, marginRight:20,}}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0, borderColor:"white" , color:"white"}}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
          
          

                    <IconButton onClick={handleClosesupply} style={{float:"right", color:"white"}}>
                    <CloseIcon/>
                    </IconButton>


                      
                <ReactToPrint
           trigger={()=><Button style={{color:"white"}}>Print Results</Button>}
            content ={()=>componentRef.current}
            />
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                   
            
                    <Container ref={componentRef} >

                    <br/>
                        <h2 align="center" style={{margin:4}}>{schooldetails_.length!==0 && (schooldetails_[0].schoolname).toUpperCase()}</h2>
                        <p align="center" style={{margin:4}}><b>{chosen.length!==0 && chosen.classname && chosen.classname.toUpperCase()}</b></p>
                        <p align="center" style={{margin:4}}><b>{selectedterm && selectedterm.toUpperCase()}</b></p>
                        <br/>
                    <Tableacademic data={students.filter(bug=>bug.class_===chosen.classname)} term={selectedterm}  subjects={chosen.subjects}/>
                    </Container>
<br/>
<br/>

                </DialogContent>

               
            </Dialog>


            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                 <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+chosen.image} width="100%"/>

                </DialogContent>

            </Dialog>




            <Dialog fullWidth maxWidth="lg" onClose={handleClosereport} aria-labelledby="customized-dialog-title" open={openreport}>
                 <DialogTitle id="customized-dialog-title" onClose={handleClosereport} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Print Class Report in Bulk  
                  <FormControl style={{ margin: 0, marginLeft:40, marginRight:20,}}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0, borderColor:"white" , color:"white"}}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    <IconButton onClick={handleClosereport} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    <Printpage data={students.filter(bug=>bug.class_===chosen.classname)} term={selectedterm} Class_={[chosen]} students={students.filter(bug=>bug.class_===chosen.classname)} subjects={chosen && chosen.subjects} schooldata={schooldetails_}/>

                </DialogContent>

            </Dialog>


         


            <Dialog fullWidth maxWidth="lg" onClose={handleClosebill} aria-labelledby="customized-dialog-title" open={openbill}>
                 <DialogTitle id="customized-dialog-title" onClose={handleClosebill} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Print Class Bill in Bulk  
                  {/* <FormControl style={{ margin: 0, marginLeft:40, marginRight:20,}}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0, borderColor:"white" , color:"white"}}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl> */}
                    <IconButton onClick={handleClosebill} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                    {/* <Printpage data={students.filter(bug=>bug.class_===chosen.classname)} term={selectedterm} Class_={[chosen]} students={students.filter(bug=>bug.class_===chosen.classname)} subjects={chosen && chosen.subjects} schooldata={schooldetails_}/> */}

                    <div style={{textAlign:"center"}}> <ReactToPrint
             pageStyle='@page { size: A4 } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Bulk Bill</Button>}
            content ={()=>componentRef.current}
            />

</div>

                    <div  ref={componentRef}>
                       {students.filter(bug=>bug.class_===chosen.classname).map((list,index)=>
                        <div className='pagebreak'>
                        
                <div align="center" style={{marginBottom:7, margin:10}}>
               
                <img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="100px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&(schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 && (schooldetails_[0].address).toUpperCase()} </h4>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 && (schooldetails_[0].contact)} </h4>
    <h4 style={{margin:4}}> STUDENT'S BILL FOR NEXT TERM </h4>
             
</div>
<hr/>
<div>
    <p style={{margin:"0px"}}><b>Student Name: </b>{list.length!==0 && list.lastname +" "+list.othernames}</p>
    <p style={{margin:"0px"}}><b>Class: </b>{list.length!==0 && list.class_}</p>
    <p style={{margin:"0px"}}><b>Date: </b>{(months[parseInt((date.split("-"))[1])-1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] )}</p>
    <p style={{margin:"0px"}}><b>Next Term Begins: </b>{schooldetails_.length!==0&&schooldetails_[0].reopendate!==""?months[parseInt((schooldetails_[0].reopendate.split("-"))[1])-1] +" "+(schooldetails_[0].reopendate.split("-"))[2]+", "+(schooldetails_[0].reopendate.split("-"))[0]:"Set Re-opening Date"}</p>
</div>


<br/>

 <TableContainer >
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{padding:2, margin:2}} align="left"><b>Description Of Bill Item</b></TableCell>
            <TableCell style={{padding:2, margin:2}} align="center"><b>Amount</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {/* <TableRow
          key={0}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            
             <TableCell align="center">School Fees</TableCell>
          <TableCell align="center">{chosen.length!==0 && "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees))}</TableCell>
          </TableRow> */}



          {schooldetails_[0].terms!==undefined && schooldetails_[0].terms.length!==0 && schooldetails_[0].terms[schooldetails_[0].terms.length-1].split('Term')[0] !=="3rd" && list.nextclass!==undefined && list.nextclass!=="" ? chosen.length !==0 && chosen.othercharges[0].map((row) => 
          

          <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        {/* {bill+="\n"+row.description+" = GHC" +Intl.NumberFormat('en-US').format(row.amount)} */}
           
          <TableCell style={{padding:2, margin:2}} align="left">{row.description}</TableCell>
          <TableCell style={{padding:2, margin:2}} align="center">GHC {Intl.NumberFormat('en-US').format(row.amount)}</TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
       
        
    ):
    list.nextclass!==undefined && list.nextclass!=="" && rows.filter(bug=>bug.classname===list.nextclass)[0].othercharges[0].map((row) => 
          

    <TableRow
    key={1}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >
  {/* {bill+="\n"+row.description+" = GHC" +Intl.NumberFormat('en-US').format(row.amount)} */}
     
    <TableCell style={{padding:2, margin:2}} align="left">{row.description}</TableCell>
    <TableCell style={{padding:2, margin:2}} align="center">GHC {Intl.NumberFormat('en-US').format(row.amount)}</TableCell>

    {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
    {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
    </TableRow>
 
  
)
    }
      <TableRow
          key={0}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {/* {bill+="\nArrears = GHC" +chosen.length!==0 && chosen.finance.length!==0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(chosen.finance[chosen.finance.length-1].balance)):0} */}

            <TableCell style={{padding:2, margin:2}} align="left"><b>Total</b> </TableCell>
            <TableCell style={{padding:2, margin:2}} align="center"><b>{list.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(chooser(list))}</b></TableCell>
          </TableRow>  
          <p style={{color:"red", textAlign:"center"}}>Arrears From Last Term</p>
          <TableRow
          key={0}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {/* {bill+="\nArrears = GHC" +chosen.length!==0 && chosen.finance.length!==0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(chosen.finance[chosen.finance.length-1].balance)):0} */}

            <TableCell style={{padding:2, margin:2, color:"red"}} align="left">Arrears </TableCell>
          <TableCell style={{padding:2, margin:2, color:"red"}} align="center">{list.length!==0 && list.finance.length!==0 ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(list.finance[list.finance.length-1].balance)):"GHC "+0}</TableCell>
          </TableRow>

     <TableRow
          key={1}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
    
            {/* {bill+="\nTOTAL = GHC" +chosen.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===chosen.class_)[0].classfees)+chooser()+(chosen.length!==0 && chosen.finance.length!==0 ? parseFloat(chosen.finance[chosen.finance.length-1].balance):0))} */}

          <TableCell style={{padding:2, margin:2}} align="left"><b>Grand Total</b></TableCell>
          <TableCell style={{padding:2, margin:2}} align="center"><b>{list.length!==0 && "GHC "+Intl.NumberFormat('en-US').format(chooser(list)+(list.length!==0 && list.finance.length!==0 ? parseFloat(list.finance[list.finance.length-1].balance):0))}</b></TableCell>

          {/* <TableCell align="center">GHC {Intl.NumberFormat('en-US').format(row)}</TableCell> */}
          {/* <TableCell align="center"><Button>Print</Button></TableCell> */}
          </TableRow>
   
        </TableBody>
      </Table>
    </TableContainer>
    <Divider/>
    <ul>
 {chosen.length!==0 &&  chosen.billnotes!==undefined && chosen.billnotes.length!==0 &&
 chosen.billnotes.map((list,index)=><li>{list.billnotes}</li>)


 }
  </ul>



    </div>
                       )
}


    </div>
 

                </DialogContent>

            </Dialog>


          
                <Dialog maxWidth="lg" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                   <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Add Class
                    <IconButton onClick={handleCloseadd} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>
                        
                        <FormControl variant="standard" style={{ margin:5 }}>

                            <TextField
                                id="classname"
                                placeholder="Class Name"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Class Name"
                                autoComplete='true'

                            />
                        </FormControl>

                        <FormControl style={{ margin:5 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class Teacher</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedteacher}
                                label="Class Teacher"
                                className="signup"
                                variant="outlined"

                                onChange={(e)=>handleChange_teacher(e)}
                            >
                                <MenuItem value="none">
                                    <em>Choose Teacher</em>
                                </MenuItem>
                                      {teacher.length !==0 ? teacher.map((list, index)=>
                                    <MenuItem value={list._id}>{list.lastname+" "+list.othernames}</MenuItem>

                                ):""}
                            </Select>


                        </FormControl>


                         <FormControl variant="standard" style={{ margin:5 }}>

                            <TextField

                                id="classfees"
                                placeholder="0.00"
                                type="number"
                                className="signup"
                                variant="outlined"
                                label="Total Class Fees"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
                                  }}

                            />
                        </FormControl>
                        <br />
{/* {(admin.positiondescription==="Accountant" || admin.positiondescription===undefined) &&

<div>
<p align="center"> <b>School fees description and Other Charges</b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">Description</TableCell>
    <TableCell align="center">Amount</TableCell>
    <TableCell align="center"></TableCell>
    
  </TableRow>
</TableHead>
<TableBody>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
     
      <TableCell align="right"> <TextField id="description" type="text"   size="small"  label="Description" variant="outlined" />
</TableCell>
      <TableCell align="right"> <TextField id="amount" type="number"  size="small" variant="outlined" label="Amount"  placeholder='0.00'   InputProps={{
            startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
          }} />
</TableCell>
     
      <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addchargeslist()} color="success"> Add Charge
</Button> </TableCell>
    </TableRow>
    
  {chargeslist.length !==0? chargeslist.map((row) => (
    <TableRow
      key={row.description}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{row.description}</TableCell>
      <TableCell align="center">GHC {row.amount}</TableCell>
      <TableCell align="center"><DeleteIcon  onClick={()=>removechargeslist(row.id)} /> </TableCell>
    </TableRow>
  )):""}
</TableBody>
</Table>
</TableContainer>

<br/>


<p align="center"> <b>Add Bill Notes</b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">BIll Notes</TableCell>
    <TableCell align="center"></TableCell>
    
  </TableRow>
</TableHead>
<TableBody>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
     
      <TableCell align="right"> <TextField id="billnotes" type="text"  style={{width:"100%"}} size="small"  label="Bill Notes" variant="outlined" />
</TableCell>
    
     
      <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addbillnotes()} color="success"> Add Notes
</Button> </TableCell>
    </TableRow>
    
  {billnotes.length !==0? billnotes.map((row) => (
    <TableRow
      key={row.billnotes}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{row.billnotes}</TableCell>
      <TableCell align="center"><DeleteIcon  onClick={()=>removebillnotes(row.id)} /> </TableCell>
    </TableRow>
  )):""}
</TableBody>
</Table>
</TableContainer>



</div>

} */}


                        <br />
                        <p align="center"> <b>Class Terminal Report Settings</b></p>


                        <FormGroup>
                        <FormControlLabel id="positionadder" onChange={()=>seteditposition(!editposition)}  control={<Switch checked={editposition} />} label="Add Position to Report" />
      <FormControlLabel id="aggregateadder" onChange={()=>seteditaggregate(!editaggregate)}  control={<Switch checked={editaggregate} />} label="Add Aggregate to Report" />
    </FormGroup>

                     <p align="center"> <b>Add Class Subjects</b></p>
                    
                       
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Subject</TableCell>
            <TableCell align="center">Subject Type</TableCell>
            <TableCell align="center">Subject Topics</TableCell>
            {/* <TableCell align="center">Exam Score</TableCell> */}
            <TableCell align="center"></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
              key="main"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
           
              <TableCell align="right"> 
              
              <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        // id="free-solo-demo"
        id="subject1"
        size="small"
        freeSolo
        options={top100Films.map((option) => option.title)}
        renderInput={(params) => <TextField   {...params} label="Subject" variant="outlined" />}
      />
     
    </Stack>
              
              {/* <TextField id="subject"   size="small"  label="Subject" variant="outlined" autoComplete='true' /> */}
   </TableCell>
              <TableCell align="right"> 
              {/* <TextField id="classscore"  size="small" label="Percentage Class Score" variant="outlined" /> */}

              <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label" style={{marginTop:"-5px"}}>Subject Type</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={subjecttype}
                                label="Subject Type"
                                className="signup"
                                variant="outlined"
                                size='small'
                                style={{textAlign:"left"}}

                                onChange={(e)=>setsubjecttype(e.target.value)}
                            >
                                <MenuItem value="none">
                                    <em>Subject Type</em>
                                </MenuItem> 
                                 <MenuItem value="Elective">
                                    <em>Elective</em>
                                </MenuItem> 
                                <MenuItem value="Core">
                                    <em>Core</em>
                                </MenuItem> 
                               
                               
                            </Select>


                        </FormControl>

</TableCell>
<TableCell align="right"> 
              {/* <TextField id="classscoreedit"  size="small" label="Percentage Class Score" variant="outlined" /> */}

              <FormControl variant="standard" style={{ margin:5 }}>

<TextField
    id="topics"
    placeholder="Saparte topics with a comma (,) (eg: addition, subtraction,)"
    type="text"
    size='small'
    className="signup"
    variant="outlined"
    label="Subject Topics"
    autoComplete='true'

/>
</FormControl>

</TableCell>
              {/* <TableCell align="right"> <TextField id="examscore"  size="small" label="Percentage Exams Score" variant="outlined" />
 </TableCell> */}
              <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addsubjectlist()} color="success"> +subject
      </Button> </TableCell>
            </TableRow>
            
          {subjectlist.length !==0? subjectlist.map((row) => (
            <TableRow
              key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" align="left" scope="row">
                {row.subject}
              </TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center">{row.topics.join(" , ")}</TableCell>
              {/* <TableCell align="center">{row.examscore}</TableCell> */}
              <TableCell align="center"><DeleteIcon  onClick={()=>removesubjectlist(row.subject)} /> </TableCell>
            </TableRow>
          )):""}
        </TableBody>
      </Table>
    </TableContainer>
  

                    


                    </DialogContent>

                    <DialogActions>
                       
{loading===true?
    <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                     <Button autoFocus onClick={() => registerclass()} color="primary">
                                    Submit
                                </Button>}


                    </DialogActions>
                </Dialog>
           
           

           
                <Dialog fullWidth maxWidth="sm" onClose={handleClosebulkreport} aria-labelledby="customized-dialog-title" open={openbulkreport}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosebulkreport} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Send Results To Guardian
                    <IconButton onClick={handleClosebulkreport} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
            <br/>
            <br/>
            <Grid container textAlign="center">
                <Grid xs={6}>
                {smssend ===true?
   
   <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginLeft:10}}
>
Loading...
</LoadingButton>
:

<Button  onClick={()=>sendviatext(chosen)} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send results via sms</Button>

}
</Grid>

<Grid xs={6}>

{emailsend ===true?
   
   <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginLeft:10}}
>
Loading...
</LoadingButton>
:

<Button  onClick={()=>sendviaemail(chosen)} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send results via email</Button>

}

</Grid>


</Grid>
<br/>
<br/>


            </Dialog>

          
                <Dialog maxWidth="md" onClose={handleClosemigrate} aria-labelledby="customized-dialog-title" open={openmigrate}>
                   <DialogTitle id="customized-dialog-title" onClose={handleClosemigrate} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Migrate Students to the next class
                    <IconButton onClick={handleClosemigrate} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>
<p><small>Students in the current class will be migrated to the next class when the command is initiated</small></p>
<p style={{color:"red"}}><small><b>NB: </b>Classes whose next class are not set must be set first</small></p>

                    <TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center"> <b>Current Class</b></TableCell>
    <TableCell align="center"><b><span>&#8594;</span></b> </TableCell>    
    <TableCell align="center"><b>Migrate to</b></TableCell>    
  </TableRow>
</TableHead>
<TableBody>
    {rows.map((list,index)=>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
     
      <TableCell align="center"> {list.classname}</TableCell>
      <TableCell align="center"> <span>&#8594;</span>  </TableCell>
      <TableCell align="center"> {list.nextclass}</TableCell>
     
   
    </TableRow>

        )}

</TableBody>
</Table>
</TableContainer>



                    


                    </DialogContent>

                    <DialogActions>
                       
{loading===true?
    <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                     <Button autoFocus onClick={() => migratestudents()} color="primary">
                                    migrate students
                                </Button>}


                    </DialogActions>
                </Dialog>
           

                <Dialog maxWidth="md" onClose={handleCloseclass} aria-labelledby="customized-dialog-title" open={openclass}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseclass} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Class Information
                    <IconButton onClick={handleCloseclass} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                    <DialogContent dividers>

                    <ListItem>
                            <ListItemText primary={chosen.classname} secondary="Class Name" />
                        </ListItem>
                        <Divider /> 
                        
                        <ListItem>
                            <ListItemText primary={chosen.classteacher} secondary="Class Teacher" />
                        </ListItem>
                        <Divider />
                        
                        <ListItem>
                            <ListItemText primary={students.filter(bug=>bug.class_===chosen.classname).length} secondary="Number of Students" />
                        </ListItem> 
                        <Divider />

                      
                     <p align="center"> <b>Class Subjects</b></p>
                                       
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Subject</b></TableCell>
            <TableCell align="center"><b>Subject Type</b></TableCell>
            <TableCell align="center"><b>Subject Topics</b></TableCell>
            {/* <TableCell align="center"><b>Exam Score</b></TableCell> */}
            
          </TableRow>
        </TableHead>
        <TableBody>
      

          {chosen.length !==0 ? chosen.subjects[0].map((row) => (
            <TableRow
              key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.subject}
              </TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center">{row.topics && row.topics.join(" , ")}</TableCell>

              {/* <TableCell align="center">{row.examscore}</TableCell> */}
            </TableRow>
          )):""}
        </TableBody>
      </Table>
    </TableContainer>

                        <br />         
                        <p align="center"> <b>Class Charges</b></p>
                                       
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Description</b></TableCell>
            <TableCell align="center"><b>Charges</b></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
      
{console.log(chosen)}
          {chosen.length !==0 ? chosen.othercharges.length!==0 && chosen.othercharges[0].map((row) => (
            <TableRow
              key={row.description}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="center">{row.amount}</TableCell>
            </TableRow>
          )):""}
        </TableBody>
      </Table>
    </TableContainer>

                        <br />
                      
                    </DialogContent>

                   
                </Dialog>
         

                <Dialog maxWidth="md" onClose={handleCloseclassbill} aria-labelledby="customized-dialog-title" open={openclassbill}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseclassbill} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                 Prepare Bill For {chosen.classname}
                    <IconButton onClick={handleCloseclassbill} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                    <DialogContent dividers>
<h1 style={{textAlign:"center", color:"green", margin:0}}>{"GHC " +totalbill}</h1>
<p style={{textAlign:"center", color:"grey", marginTop:"-10px"}}><small >Total Class Bill</small></p>

<div style={{textAlign:"center"}}>
                    <InputLabel id="demo-simple-select-autowidth-label">Choose Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={changeterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={changetermfunction}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value={"1st Term "+(year-1)+" / "+year}>{"1st Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"2nd Term "+(year-1)+" / "+year}>{"2nd Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"3rd Term "+(year-1)+" / "+year}>{"3rd Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"1st Term "+year+" / "+(year+1)}>{"1st Term "+year+" / "+(year+1)}</MenuItem>
                            <MenuItem value={"2nd Term "+year+" / "+(year+1)}>{"2nd Term "+year+" / "+(year+1)}</MenuItem>
                            <MenuItem value={"3rd Term "+year+" / "+(year+1)}>{"3rd Term "+year+" / "+(year+1)}</MenuItem>

                        </Select>
                        </div>

                    <div>
<p align="center"> <b>School fees description </b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">Description</TableCell>
    <TableCell align="center">Amount</TableCell>
    <TableCell align="center"></TableCell>
    
  </TableRow>
</TableHead>
<TableBody>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
     
      <TableCell align="right"> <TextField id="description" type="text"   size="small"  label="Description" variant="outlined" />
</TableCell>
      <TableCell align="right"> <TextField id="amount" type="number"  size="small" variant="outlined" label="Amount"  placeholder='0.00'   InputProps={{
            startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
          }} />
</TableCell>
     
      <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addchargeslist()} color="success"> Add Charge
</Button> </TableCell>
    </TableRow>
    
  {chargeslist.length !==0? chargeslist.map((row) => (
    <TableRow
      key={row.description}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{row.description}</TableCell>
      <TableCell align="center">GHC {row.amount}</TableCell>
      <TableCell align="center"><DeleteIcon  onClick={()=>removechargeslist(row.id)} /> </TableCell>
    </TableRow>
  )):""}
</TableBody>
</Table>
</TableContainer>

<br/>


<p align="center"> <b>Add Bill Notes</b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">BIll Notes</TableCell>
    <TableCell align="center"></TableCell>
    
  </TableRow>
</TableHead>
<TableBody>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
     
      <TableCell align="right"> <TextField id="billnotes" type="text"  style={{width:"100%"}} size="small"  label="Bill Notes" variant="outlined" />
</TableCell>
    
     
      <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addbillnotes()} color="success"> Add Notes
</Button> </TableCell>
    </TableRow>
    
  {billnotes.length !==0? billnotes.map((row) => (
    <TableRow
      key={row.billnotes}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{row.billnotes}</TableCell>
      <TableCell align="center"><DeleteIcon  onClick={()=>removebillnotes(row.id)} /> </TableCell>
    </TableRow>
  )):""}
</TableBody>
</Table>
</TableContainer>



</div>


                      
                    </DialogContent>

                    <DialogActions>
                        { billing?
                        <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                                <Button autoFocus onClick={() => addnewbill()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit Bill
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
         

                <Dialog maxWidth="md" onClose={handleClosenextclass} aria-labelledby="customized-dialog-title" open={opennextclass}>
                  <DialogTitle id="customized-dialog-title" onClose={handleClosenextclass} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                 Set next Class for {chosen.classname}, (Next class is {chosen.nextclass})
                    <IconButton onClick={handleClosenextclass} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                    <DialogContent dividers>
<p style={{textAlign:"left", color:"grey", marginTop:"-10px"}}> This section is to set the next class students in this class will go the next academic year </p>

<div style={{textAlign:"center"}}>
                    <InputLabel id="demo-simple-select-autowidth-label">Choose Class</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={changeclass}
                            style={{ minWidth: 200, padding: 0, margin: 0 , color:"black"}}
                            onChange={changeclassfunction}
                            autoWidth
                            // label="Next Class"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose class</em>
                            </MenuItem>
                            {rows.map((list,index)=> 
                             <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                                        )}
                          
                        </Select>
                        </div>

                      
                    </DialogContent>

                    <DialogActions>
                        { billing?
                        <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                                <Button autoFocus onClick={() => addnextclass()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit class
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
         


                <Dialog maxWidth="md" onClose={handleCloseclassbillview} aria-labelledby="customized-dialog-title" open={openclassbillview}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseclassbillview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                 Bills For {chosen.classname}
                    <IconButton onClick={handleCloseclassbillview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                    <DialogContent dividers>

<h1 style={{textAlign:"center", color:"green", margin:0}}>{"GHC " +chosen.classfees}</h1>
<p style={{textAlign:"center", color:"grey", marginTop:"-10px"}}><small >Total Class Bill</small></p>

<div style={{textAlign:"center"}}>
                    <InputLabel id="demo-simple-select-autowidth-label">Choose Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={changeterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={changetermfunction}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {console.log(chosen)}
                            {chosen && chosen.billingdata!==undefined && chosen.billingdata.length!==0 && chosen.billingdata.map((list,index)=> <MenuItem value={list.term}>{list.term}</MenuItem>)}
                        </Select>
                        </div>

                    <div>
<p align="center"> <b>School fees description</b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">Description</TableCell>
    <TableCell align="center">Amount</TableCell>
    
  </TableRow>
</TableHead>
<TableBody>

{chosen.billingdata!==undefined && chosen.billingdata.length!==0 && chosen.billingdata.map((list,index)=> 
list.term===changeterm && list.bill.map((lister, inxer)=>
<TableRow
      key="main"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{lister.description}</TableCell>
      <TableCell align="center">GHC {lister.amount}</TableCell>

     
   
    </TableRow>
)

)}


</TableBody>
</Table>
</TableContainer>

<br/>


<p align="center"> <b>Add Bill Notes</b></p>

<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
<TableHead>
  <TableRow>
    <TableCell align="center">Bill Notes</TableCell>
    
  </TableRow>
</TableHead>
<TableBody>

    
  {chosen.billingdata!==undefined && chosen.billingdata.length!==0 && chosen.billingdata.map((list,index)=> 
    list.term===changeterm && list.bill.map((lister, inxer)=>
    <TableRow
      key={list.billnotes.billnotes}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="center">{lister.billnotes}</TableCell>
    </TableRow>
    )
  )}
</TableBody>
</Table>
</TableContainer>



</div>


                      
                    </DialogContent>
<br/>
<br/>
<br/>
                    {/* <DialogActions>
                        { billing?
                        <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                                <Button autoFocus onClick={() => addnewbill()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit Bill
                                </Button>
                        }
                    </DialogActions> */}
                </Dialog>
         


            <div>

                <Dialog  maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                     <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Edit Class
                    <IconButton onClick={handleCloseedit} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>
                        
                        <FormControl variant="standard" style={{ margin: 5 }}>

                            <TextField
                                id="classnameedit"
                                placeholder="Class Name"
                                type="text"
                                defaultValue={chosen.classname}
                                className="signup"
                                variant="outlined"
                                label="Class Name"

                            />
                        </FormControl>

                        <FormControl style={{ margin: 5 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class Teacher</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedteacheredit}
                                label="Class Teacher"
                                className="signup"
                                variant="outlined"

                                onChange={(e)=>handleChange_teacheredit(e)}
                            >
                                <MenuItem value="none">
                                    <em>Choose Teacher</em>
                                </MenuItem>
                                {teacher.length !==0 ? teacher.map((list, index)=>
                                    <MenuItem value={list._id}>{list.lastname+" "+list.othernames}</MenuItem>

                                ):""}
                               

                            </Select>


                        </FormControl>


                         <FormControl variant="standard" style={{ margin: 5 }}>

                            <TextField
                                id="classfeesedit"
                                placeholder="Class Total Fees"
                                type="text"
                                defaultValue={chosen.classfees}
                                className="signup"
                                variant="outlined"
                                label="Class Total Fees"
                                InputProps={{


                                    startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
                                  }}


                            />
                        </FormControl>

                        <br />


                        <br />
{/* {(admin.positiondescription==="Accountant" ||  admin.positiondescription===undefined) &&
<div>
                        <p align="center"> <b>School fees description and Other Charges</b></p>
          
          <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center"></TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow
                key="main"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
               
                <TableCell align="right"> <TextField id="descriptionedit" type="text"   size="small"  label="Description" variant="outlined" />
     </TableCell>
                <TableCell align="right"> <TextField id="amountedit" type="number"  size="small" label="Amount" variant="outlined" InputProps={{


startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
}} />
  </TableCell>
               
                <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addchargeslistedit()} color="success"> Add Charge
        </Button> </TableCell>
              </TableRow>
              
            {chargeslist2 !==undefined && chargeslist2.length !==0? chargeslist2.map((row) => (
              <TableRow
                key={row.description}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center">{row.description}</TableCell>
                <TableCell align="center">GHC {row.amount}</TableCell>
                <TableCell align="center"><DeleteIcon  onClick={()=>removechargeslistedit(row.id)} /> </TableCell>
              </TableRow>
            )):""}
          </TableBody>
        </Table>
      </TableContainer>
<br/>

<p align="center"> <b>Edit Bill Notes</b></p>
          
          <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Bill Notes</TableCell>
              <TableCell align="center"></TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow
                key="main"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
               
                <TableCell align="right"> <TextField id="billnotesedit" type="text" style={{width:"100%"}}  size="small"  label="Bill Notes" variant="outlined" />
     </TableCell>
    
               
                <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>billnotesedit()} color="success"> Add notes
        </Button> </TableCell>
              </TableRow>
              {console.log(billnotes2)}
            {billnotes2 !==undefined && billnotes2.length !==0? billnotes2.map((row) => (
              <TableRow
                key={row.billnotes}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.billnotes}</TableCell>
                <TableCell align="center"><DeleteIcon  onClick={()=>removebillnotesedit(row.id)} /> </TableCell>
              </TableRow>
            )):""}
          </TableBody>
        </Table>
      </TableContainer>


    </div>
} */}
                        <br />

                     <p align="center"> <b>Class Terminal Report Settings</b></p>
                     
                     <FormGroup>
      <FormControlLabel id="positionadder" onChange={()=>seteditposition(!editposition)}  control={<Switch checked={editposition} />} label="Add Position to Report" />
      <FormControlLabel id="aggregateadder" onChange={()=>seteditaggregate(!editaggregate)}  control={<Switch checked={editaggregate} />} label="Add Aggregate to Report" />
    </FormGroup>    

                     <p align="center"> <b>Add Class Subjects</b></p>
                    
                       
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Subject</TableCell>
            <TableCell align="center">Subject Type</TableCell>
            <TableCell align="center">Subject Topics</TableCell>
            {/* <TableCell align="center">Exam Score</TableCell> */}
            <TableCell align="center"></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
              key="main"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
              <TableCell align="right"> 
              
              <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        // id="free-solo-demo"
        id="subjectedit"
        size="small"
        freeSolo
        options={top100Films.map((option) => option.title)}
        renderInput={(params) => <TextField   {...params} label="Subject" variant="outlined" />}
      />
     
    </Stack>
              
              {/* <TextField id="subjectedit"   size="small"  label="Subject" variant="outlined" /> */}
   
   
   </TableCell>
              <TableCell align="right"> 
              {/* <TextField id="classscoreedit"  size="small" label="Percentage Class Score" variant="outlined" /> */}





              <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label" style={{marginTop:"-5px"}}>Subject Type</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={subjecttypeedit}
                                label="Subject Type"
                                className="signup"
                                variant="outlined"
                                size='small'
                                style={{textAlign:"left"}}

                                onChange={(e)=>setsubjecttypeedit(e.target.value)}
                            >
                                <MenuItem value="none">
                                    <em>Subject Type</em>
                                </MenuItem> 
                                 <MenuItem value="Elective">
                                    <em>Elective</em>
                                </MenuItem> 
                                <MenuItem value="Core">
                                    <em>Core</em>
                                </MenuItem> 
                               
                               
                            </Select>


                        </FormControl>

</TableCell> 

<TableCell align="right"> 
              {/* <TextField id="classscoreedit"  size="small" label="Percentage Class Score" variant="outlined" /> */}

              <FormControl variant="standard" style={{ margin:5 }}>

<TextField
    id="topicsedit"
    placeholder="Saparte topics with a comma (,) (eg: addition, subtraction,)"
    type="text"
    size='small'
    className="signup"
    variant="outlined"
    label="Subject Topics"
    autoComplete='true'

/>
</FormControl>

</TableCell>
              {/* <TableCell align="right"> <TextField id="examscoreedit"  size="small" label="Percentage Exams Score" variant="outlined" />
 </TableCell> */}
              <TableCell align="right">  <Button aria-label="fingerprint" onClick={()=>addsubjectlistedit()} color="success"> +subject
      </Button> </TableCell>
            </TableRow>

          {subjectlist2 !== undefined? subjectlist2.map((row) => (
            <TableRow
              key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            {console.log(row)}
              <TableCell align="center" component="th" scope="row">

                {row.subject}
                
              </TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center">{row.topics && row.topics.join(" , ")}</TableCell>

              {/* <TableCell align="center">{row.examscore}</TableCell> */}
              <TableCell align="center"><DeleteIcon  onClick={(e)=>removesubjectlistedit(row.subject)} /> </TableCell>
            </TableRow>
          )) : ""}
        </TableBody>
      </Table>
    </TableContainer>
  

  <br/>
  <br/>
                    </DialogContent>


                    <DialogActions>
                        { loading?
                        <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                                <Button autoFocus onClick={() => editclass()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />

                <Grid container>
                    <Grid lg={1} xs={2} md={6}>
                        <Tooltip title="Add Class">
                            <Button
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                variant="outlined"
                                color = "primary"
                                style={{ marginTop: 10.5 }}
                                onClick={handleClickOpenadd}

                            >
                                <AddBusinessIcon fontSize="medium" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid lg={3} xs={2} md={6} style={{ textAlign: "left" }}>
                       
                    <Tooltip title="Migrate Students">
                            <Button
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                variant="outlined"
                                color = "primary"
                                style={{ marginTop: 10.5 }}
                                onClick={handleClickOpenmigrate}
                            >
                                Migrate Students
                            </Button>
                        </Tooltip>

                    </Grid>

                    <Grid lg={3} xs={10} md={6}>
                        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color: "grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "110%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.classname}</TableCell>

                                            <TableCell component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="left"
                                            >

                                                <ListItem align="left">
                                                    {/* <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+row.image} size="small" alt={row.classteacher}  style={{ cursor: "pointer" }}>
                                                        </Avatar>
                                                    </ListItemAvatar> */}
                                                    <ListItemText primary={row.classteacher} />
                                                </ListItem>

                                            </TableCell>
                                            
                                            <TableCell align="left">{students.filter(bug=>bug.class_===row.classname).length}</TableCell>
                                            <TableCell align="left">GHC {row.classfees===""?0:row.classfees}</TableCell>

                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} classinfo={(sender) => handleClickOpenclass(sender)} viewatt={(sender) => handleClickOpenviewatt(sender)}   preparebill={(sender) => handleClickOpenclassbill(sender)}   preparereport={(sender) => handleClickOpenclassreport(sender)}   nextclass={(sender) => handleClickOpennextclass(sender)} academics={(sender) => handleClickOpensupply(sender)} viewbill={(sender) => handleClickOpenclassbillview(sender)} message={(sender) => handleClickOpenmessage(sender)}  bulkreport={(sender) => handleClickOpenreport(sender)}  sendbulkreport={(sender) => handleClickOpensendbulkreport(sender)} bulkbill={(sender) => handleClickOpenbill(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)} charges={(sender) => handleClickOpencharge(sender)} />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>


    );
}
