// src/App.js

import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import StudentTable from './StudentTable';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { login } from '../redux/admin';
import { loin, logout, updatestudentdata, getdata } from '../redux/students';
import { getdata_ } from '../redux/class';
import { schooldetails } from '../redux/schooldetails';

import axios from 'axios';


const initialStudents = [
  { id: 1, name: 'John Doe', amount: '', date: '2024-11-01', class: 'Math', paymentStatus: 'Pending' },
  { id: 2, name: 'Jane Smith', amount: '', date: '2024-11-02', class: 'Science', paymentStatus: 'Pending' },
  { id: 3, name: 'Sam Johnson', amount: '', date: '2024-11-03', class: 'History', paymentStatus: 'Pending' },
  { id: 4, name: 'Alex Lee', amount: '', date: '2024-11-01', class: 'Math', paymentStatus: 'Pending' },
  { id: 5, name: 'Emily Davis', amount: '', date: '2024-11-03', class: 'Science', paymentStatus: 'Pending' },
];

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};


function createData(_id,id,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothernames, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothernames, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academic ,finance, attendance, Awards, behaviour, others, dailycharge, index, specialID, nextclass, housing) 
{
    return {
        _id,
        id,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothernames,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothernames,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academic,
        finance,
        attendance,
        Awards,
        behaviour,
        others,
        dailycharge,
        index,
        specialID,
        nextclass,
        housing, 
        
    };
}
function App() {
  const [selected, setSelected] = useState([]);
  const [inputAmount, setInputAmount] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [openAlert, setOpenAlert] = useState(false);
  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  var rows = data[0]
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const [message, setmessage] = React.useState("");
  const [stated, setstated] = React.useState("");
  const [loading, setloading] = React.useState(false);

  const [opensnack, setOpensnack] = React.useState(false);

  const admin = useSelector((state) => state.admin)
  const stud = useSelector((state) => state.students)
    const Class_ = useSelector((state) => state.Class_)

const schools = stud
var data = [schools.map((list, index) => createData(list._id,list.ID, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothernames, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academics, list.finance, list.attendance, list.Awards, list.behaviour, list.others, list.dailyfee, list.studentIndex, list.specialID, list.nextclass, list.housing))]

var students_ = data[0]

  const [students, setStudents] = useState(students_);




  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((studentId) => studentId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(students.map((student) => student.id));
    } else {
      setSelected([]);
    }
  };

  const handleApplyAmount = () => {
    if (selected.length === 0) {
      setOpenAlert(true);
      return;
    }

    setStudents((prevStudents) =>
      prevStudents.map((student) => {
        if (selected.includes(student.id)) {
          return {
            ...student,
            amount: inputAmount,
            paymentStatus: inputAmount ? 'Completed' : 'Pending',
          };
        }
        return student;
      })
    );
  };

  const handleAmountChange = (id, value) => {
    const updatedStudents = students.map((student) =>
      student.id === id ? { ...student, amount: value } : student
    );
    setStudents(updatedStudents);

  };


  const handlePayment= () => {
   var data=[]

   for(var i in students){
    data.push({id:students[i].id, amount:students, date:date, term:selectedDate, month:dt.getMonth(), year:year })

   }



   const doer = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/students/deletestudent/",
        {
           schoolID:admin.schoolID,
           data:data

        }).then(res => {

            if (res.status === 200) {


                setloading(!true)
                handleClicksnack()
                setmessage("Canteen Fees Collected")
                setstated("success")

            } else {
                setloading(!true)
                handleClicksnack()
                setmessage("Error Collecting Canteen")
                setstated("error")
            }

        })

}


doer()
    

  };

  // Filter students by search term, class, and date
  const filteredStudents = students.filter(
    (student) =>
      (student.lastname.toLowerCase().includes(filter.toLowerCase()) ||
        student.id.toString().includes(filter) || student.canteen.filter(cant=>cant.date===selectedDate)) &&
      (selectedClass === '' || student.class_ === selectedClass) 
  );

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  };


  const handleClicksnack = () => {
    setOpensnack(true);
};


const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpensnack(false);
};

const paymentlist=()=>{
var total=0
  for(var i in students){
    for (var k in students[i].canteen){
      total+=parseFloat(students[i].canteen[k].amount)
    }
  }
return total
}

  return (
    <Box sx={{ width: '100%' }}>

       <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                      <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                          { message }
                      </Alert>
                  </Snackbar>
            <Paper sx={{ width: '100%', mb: 2 }}>

<div style={{padding:"20px"}}>
    <p style={{textAlign:"center", fontWeight:"bolder"}}>CANTEEN FEES</p>
    <Divider/>
</div>

    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px', padding:"10px" }}>
       
        <TextField
          label="Search Students"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          variant="outlined"
          size="small"
        />

        <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
          <InputLabel>Class</InputLabel>
          <Select
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            label="Class"
          >
            <MenuItem value="">All</MenuItem>
         
          {Class_.length!==0?Class_.map((list, index)=>
                                         <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                         ):""}
         
         </Select>
        </FormControl>

       

        <TextField
        label="Select Date"
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        variant="outlined"
        size="small"
        InputLabelProps={{
          shrink: true, // Keeps the label above the field
        }}
        style={{ minWidth: '150px' }}
      />

        

        <TextField
          label="Set Amount for Selected Students"
          value={inputAmount}
          size='small'
          onChange={(e) => setInputAmount(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyAmount}
        >
          Apply Amount
        </Button> 
        
        <Button
          variant="contained"
          color="primary"
          onClick={handlePayment}
        >
         Submit Payment
        </Button>
      </div>

      <StudentTable
        students={filteredStudents}
        selected={selected}
        handleSelect={handleSelect}
        handleAmountChange={handleAmountChange}
        handleSelectAll={handleSelectAll}
        handlePayment={handlePayment}
      />

      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
          Please select at least one student to apply the amount.
        </Alert>
      </Snackbar>


</Paper>
     
</Box>


  );
}

export default App;
